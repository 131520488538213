import {Observable} from 'rxjs';
import {ValidatorFn} from '@angular/forms';
import {EventEmitter, Type} from '@angular/core';

export abstract class BaseDynamicControlModel<T> {

    // NOTE - Be careful of using keys longer than 30 characters as angular debug attributes "ng-reflect-*"
    // are clipped to 30 characters which could pose an issue for testing when grabbing and element using the ng-reflect-name attribute

    value: T;
    updateValueObservable: Observable<T>;
    clearValueObservable?: Observable<boolean>;
    key: string;
    label: string;
    required: boolean;
    order: number;
    hint: string;
    maxLength: number;
    validation?: ValidatorFn[];
    hidden: Observable<boolean>;
    disabled: Observable<boolean>;
    readonly: Observable<boolean>;
    hintClickedEvent: EventEmitter<any>;

    constructor(controlModel?: Partial<BaseDynamicControlModel<T>>) {
        Object.assign(this, controlModel);
    }

    abstract getControlClass(): Type<any>;

}
