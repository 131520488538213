import {CifVehicleAddressModel} from '../cif/cif-vehicle-address.model';
import {PifVehicleAddressTypeModel} from './pif-vehicle-address-type.model';
import {AddressDetailsModel} from '../../../../../personal/model/SaveClientModels/address-details.model';

export class PifVehicleAddressModel {


  dayTimeAddress: PifVehicleAddressTypeModel = new PifVehicleAddressTypeModel();

  nightTimeAddress: PifVehicleAddressTypeModel = new PifVehicleAddressTypeModel();

  static cifToPif(addresses: CifVehicleAddressModel[]): PifVehicleAddressModel {
    const pifVehicleAddressModel = new PifVehicleAddressModel();
    addresses.forEach(address => {
      if (address.addressType === 'NIGHTTIME') {
        pifVehicleAddressModel.nightTimeAddress.defaultToPhysicalAddress = address.sameAsHome;
        pifVehicleAddressModel.nightTimeAddress.areaTypeCode = address.areaTypeCode;
        pifVehicleAddressModel.nightTimeAddress.storageTypeCode = address.storageTypeCode;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.municipalityCode = '' + address.addressDetails.municipalityCode;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.unitNo = address.addressDetails.unitNo;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.suburbCode = '' + address.addressDetails.suburbCode;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.streetTypeDesc = address.addressDetails.streetTypeDesc;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.streetNo = address.addressDetails.streetNo;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.streetDesc = address.addressDetails.streetDesc;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.streetCode = address.addressDetails.streetCode;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.provinceCode = '' + address.addressDetails.provinceCode;
        pifVehicleAddressModel.nightTimeAddress.addressDetails.postalCode = address.addressDetails.postalCode;
      } else if (address.addressType === 'DAYTIME') {
        pifVehicleAddressModel.dayTimeAddress.defaultToPhysicalAddress = address.sameAsHome;
        pifVehicleAddressModel.dayTimeAddress.storageTypeCode = address.storageTypeCode;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.municipalityCode = '' + address.addressDetails.municipalityCode;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.unitNo = address.addressDetails.unitNo;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.suburbCode =  '' + address.addressDetails.suburbCode;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.streetTypeDesc = address.addressDetails.streetTypeDesc;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.streetNo = address.addressDetails.streetNo;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.streetDesc = address.addressDetails.streetDesc;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.streetCode = address.addressDetails.streetCode;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.provinceCode = '' + address.addressDetails.provinceCode;
        pifVehicleAddressModel.dayTimeAddress.addressDetails.postalCode = address.addressDetails.postalCode;
      }

    });

    return pifVehicleAddressModel;
  }

  static pifToCif(addresses: PifVehicleAddressModel): CifVehicleAddressModel[] {
    const cifAddresses: CifVehicleAddressModel[] = [];

    const nightAdd = new CifVehicleAddressModel();
    nightAdd.addressType = 'NIGHTTIME';
    nightAdd.areaTypeCode = addresses.nightTimeAddress.areaTypeCode;
    nightAdd.storageTypeCode = addresses.nightTimeAddress.storageTypeCode;
    nightAdd.addressDetails = Object.assign(new AddressDetailsModel(), addresses.nightTimeAddress.addressDetails);
    nightAdd.sameAsHome = addresses.nightTimeAddress.defaultToPhysicalAddress;
    cifAddresses.push(nightAdd);

    const dayAdd = new CifVehicleAddressModel();
    dayAdd.addressType = 'DAYTIME';
    dayAdd.areaTypeCode = '';
    dayAdd.storageTypeCode = addresses.dayTimeAddress.storageTypeCode;
    dayAdd.addressDetails = Object.assign(new AddressDetailsModel(), addresses.dayTimeAddress.addressDetails);
    dayAdd.sameAsHome = addresses.dayTimeAddress.defaultToPhysicalAddress;
    cifAddresses.push(dayAdd);

    return cifAddresses;
  }
}
