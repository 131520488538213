import {Injectable} from '@angular/core';
import {MSTIConfiguration} from '../msti.config';

@Injectable()
export class UrlService {

  public static readonly KEY_LOGIN = 'LOGIN';
  public static readonly KEY_LOOKUPS = 'LOOKUPS';
  public static readonly KEY_COORDS = 'COORDS';
  public static readonly KEY_VEHICLE_MAKES = 'VEHICLE_MAKES';
  public static readonly KEY_VEHICLE_MODELS = 'VEHICLE_MODELS';
  public static readonly KEY_PARTY_KEY = 'PARTY_KEY';
  public static readonly KEY_PREMIUM_TERMS_PDF = 'PREMIUM_TERMS_PDF';
  public static readonly KEY_PREMIUM_TERMS_AGREEMENT = 'PREMIUM_TERMS_AGREEMENT';

  public readonly GENERAL_API = 'cif/lookupapi/v1/';
  public readonly FULFILLMENT_API_V2 = 'cif/shortterm/fulfillmentapi/v2/MstiOnlineQuoteSTS/';
  public readonly BROKERBFF_API = 'broker-bff/';

  public urls: Map<string, string> = new Map<string, string>();

  constructor() {
    this.urls.set(UrlService.KEY_LOGIN, 'cif/auth/v1/login');
    this.urls.set(UrlService.KEY_LOOKUPS, this.GENERAL_API + 'codes/');
    this.urls.set(UrlService.KEY_COORDS, 'cif/locationapi/v1/addressList?');
    this.urls.set(UrlService.KEY_VEHICLE_MAKES, this.GENERAL_API + 'vehicles/makes');
    this.urls.set(UrlService.KEY_VEHICLE_MODELS, this.GENERAL_API + 'vehicles/models');
    this.urls.set(UrlService.KEY_PARTY_KEY, 'partymaster/partyapi/v1/partykeys?partyIdType=ldap&partyId=');
    this.urls.set(UrlService.KEY_PREMIUM_TERMS_PDF,
      'http://content.momentum.co.za/content/ShortTermInsurance/msti-terms-and-conditions-broker.pdf');
    this.urls.set(UrlService.KEY_PREMIUM_TERMS_AGREEMENT, 'product-terms-and-conditions/api/v1/acceptance');
  }

  getUrl(key: string): string {
    return MSTIConfiguration.constants.urlPrefix + this.urls.get(key);
  }

  getUnprefixedUrl(key: string): string {
    return this.urls.get(key);
  }

  getLeadsUrl(RSAID: string) {
    return MSTIConfiguration.constants.stiUrlPrefix + this.BROKERBFF_API + 'brokerClientsApi/clients/quotationEligibilities?identityType=RSA_ID&identityValue=' + RSAID;
  }

  getAvailableSectionsUrl() {
    return MSTIConfiguration.constants.urlPrefix + this.GENERAL_API + 'vehicles/sections';
  }

  getWebClientUrl(): string {
    return MSTIConfiguration.constants.urlPrefix + 'cif/so/auth/v1/login/?channel=WEB_CLIENT';
  }

  getAdviceUrl(): string {
    return MSTIConfiguration.constants.stiUrlPrefix + this.BROKERBFF_API + 'brokerClientsApi/clients/adviceRequests/async';
  }

  getBffLocationUrl(): string {
    return MSTIConfiguration.constants.stiUrlPrefix + '/broker-bff/nlapi?';
  }

  getBffTandCUrl(): string {
    return MSTIConfiguration.constants.stiUrlPrefix + '/broker-bff/ptaca?guid=';
  }

  getConfigUrl(): string {
    return MSTIConfiguration.constants.stiUrlPrefix + '/configurationApi/v2/configuration';
  }

}
