import {AbstractControl} from '@angular/forms';

export function ValidateRsaId(control: AbstractControl) {

    let isRsaIdNotValid = false;
    const idNumber = control.value.toString();

    // Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
    // SA ID Number have to be 13 digits, so check the length
    if (idNumber.length !== 13 || idNumber === '0000000000000') {
      isRsaIdNotValid = true;
    }

    // get first 6 digits as a valid date
    const tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));
    const idNumberDate = tempDate.getDate();
    const idNumberMonth = tempDate.getMonth();

    if (!((tempDate.getFullYear() === idNumber.substring(0, 2))
        && (idNumberMonth === idNumber.substring(2, 4) - 1)
        && (idNumberDate === idNumber.substring(4, 6)))) {
    } else {
      isRsaIdNotValid = true;
    }

    // apply Luhn formula for check-digits
    let tempTotal = 0;
    let checkSum = 0;
    let multiplier = 1;
    for (let i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
          tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = (multiplier % 2 === 0) ? 1 : 2;
    }
    if ((checkSum % 10) === 0) {
    } else {
      isRsaIdNotValid = true;
    }

    if (isRsaIdNotValid) {
      return { validateRsaId: true };
    }

    return null;
  }



