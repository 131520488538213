import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Attribute, Directive, forwardRef} from '@angular/core';

@Directive({
    selector: '[validateEmailAddress][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EmailAddressValidator),
            multi: true
        }
    ]
})
export class EmailAddressValidator implements Validator {

    constructor(@Attribute('validateEmailAddress') public validateEmailAddress: string) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        const emailAddress = control.value;
        if (emailAddress) {
            const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(emailAddress)) {
                return {
                    validateObj: {
                        valid: false,
                        msg: 'Invalid email address'
                    }
                };
            }
        } else {
            return {
                validateObj: {
                    valid: false,
                    msg: 'This field is required'
                }
            };
        }
    }
}
