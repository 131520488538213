import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sentenceTitleCase'
})
export class SentenceTitleCasePipe implements PipeTransform {
    /**
     * Converts a string value to title case
     * @param {string} value - Input string to be formatted
     */
    transform(
        value: string
    ) {
        if (!value) {
            return '';
        } else {
            return value[0].toUpperCase() + value.substr(1).toLowerCase();
        }
    }
}

