import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifBuildingModel} from './cif/cif-building.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

export class BuildingDetailsModel {

  constructor() {
  }

  sectionCode: string;
  industryTypeCode: string;
  buildingAmount: string;
  buildingIsHolidayResidence: string;
  buildingBurglaries: string;
  buildingAreaType: LookupModel;
  buildingNeighbourhood: LookupModel;
  buildingOwnership: LookupModel;
  buildingPropertyOccupation: LookupModel;
  buildingPropertyOccupants: LookupModel;
  buildingAddressSameAsPhysical: string;
  buildingAddress: AddressTypeModel;

  populateFromFormData(formData) {
    this.industryTypeCode = null;
    this.buildingAmount = formData.buildingAmount;
    this.buildingIsHolidayResidence = formData.buildingIsHolidayResidence;
    this.buildingBurglaries = formData.buildingBurglaries;
    this.buildingAreaType = formData.buildingAreaType;
    this.buildingNeighbourhood = formData.buildingNeighbourhood;
    this.buildingPropertyOccupants = formData.buildingPropertyOccupants;
    this.buildingOwnership = formData.buildingOwnership;
    this.buildingPropertyOccupation = formData.buildingPropertyOccupation;
    this.buildingAddressSameAsPhysical = formData.buildingAddressSameAsPhysical;

    // TODO - This logic is duplicated in the contentRisks details model
    if (this.buildingAddressSameAsPhysical === '0') {
      if (
        formData.buildingAddress &&
        formData.buildingAddress.formattedAddressLine
      ) {
        this.buildingAddress = formData.buildingAddress;
      } else if (
        formData.addressSelection &&
        formData.addressSelection.value &&
        formData.addressSelection.value.formattedAddressLine !== 'Add new address'
      ) {
        this.buildingAddress = formData.addressSelection.value;
      } else {
        // TODO - We should never get here, but what if we do?
      }
    } else {
      this.buildingAddress = null;
    }
  }

  async populateBuildingFromCifModel(cifModel: CifBuildingModel, lookupServiceInstance: LookupService) {
    if (cifModel) {
      this.buildingAmount = cifModel.sumInsured;
      this.buildingAreaType = await lookupServiceInstance.getLookUpObjFromCode(cifModel.riskAddress.areaTypeCode,
        LookupService.GROUP_BUILDING_AREA_TYPE
      );
      this.buildingNeighbourhood = await lookupServiceInstance.getLookUpObjFromCode(cifModel.neighbourTypeCode,
        LookupService.GROUP_BUILDING_NEIGHBOUR
      );
      this.buildingOwnership = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.ownershipTypeCode, LookupService.GROUP_BUILDING_OWNERSHIP_TYPE
      );
      this.buildingPropertyOccupation = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.occupancyTypeCode, LookupService.GROUP_BUILDING_OCCUPANCY
      );
      this.buildingPropertyOccupants = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.occupantTypeCode, LookupService.GROUP_BUILDING_OCCUPIED_BY
      );
      this.buildingIsHolidayResidence = cifModel.isHolidayResidence ? '1' : '0';
      this.buildingBurglaries = cifModel.previousBurglaries ? '1' : '0';
      this.buildingAddressSameAsPhysical = cifModel.riskAddress.defaultToPhysicalAddress ? '1' : '0';
      this.buildingAddress = PifRiskAddressModel.piftoAddressTypeModel(cifModel.riskAddress);

    }
  }

  // TODO - This code is duplicated in the other risks
  /*   private readCifAddressModel(cifRiskAddressModel: PifAllRisksAddressModel): AddressTypeModel {
         const addressTypeModel = new AddressTypeModel(cifRiskAddressModel.addressType);
         addressTypeModel.unitNumber = cifRiskAddressModel.unitNumber || '';
         addressTypeModel.sectionSchemeName = cifRiskAddressModel.sectionSchemeName || '';
         addressTypeModel.estateCode = cifRiskAddressModel.estateCode || '';
         addressTypeModel.estateName = cifRiskAddressModel.estateName || '';
         addressTypeModel.streetCode = cifRiskAddressModel.streetCode || '';
         addressTypeModel.streetDesc = cifRiskAddressModel.streetDesc || '';
         addressTypeModel.streetTypeCode = cifRiskAddressModel.streetTypeCode || '';
         addressTypeModel.streetTypeDesc = cifRiskAddressModel.streetTypeDesc || '';
         addressTypeModel.streetNo = cifRiskAddressModel.streetNumber || '';
         addressTypeModel.suburbCode = cifRiskAddressModel.suburbCode || '';
         addressTypeModel.suburbDesc = cifRiskAddressModel.suburbDesc || '';
         addressTypeModel.municipalityCode = cifRiskAddressModel.municipalityCode || '';
         addressTypeModel.municipalityName = cifRiskAddressModel.municipalityDesc || '';
         addressTypeModel.provinceCode = cifRiskAddressModel.provinceCode || '';
         addressTypeModel.provinceDesc = cifRiskAddressModel.provinceDesc || '';
         addressTypeModel.postalCode = cifRiskAddressModel.postalCode || '';
         addressTypeModel.poBoxNo = cifRiskAddressModel.poBoxNumber || '';
         addressTypeModel.formatAddressLine();
         return addressTypeModel;
     }*/

}
