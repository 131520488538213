import {Component, Injector} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material';
import {Moment} from 'moment';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {ImageService} from '../../../../../services/image.service';

@Component({
    selector: 'app-datepicker-dynamic-form-control',
    templateUrl: './datepicker-dynamic-form-control.component.html',
    styleUrls: ['./datepicker-dynamic-control.component.scss']

})
export class DatePickerDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    calendarIcon: string;
    maxDate = new Date();

    private imageService: ImageService;

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.imageService = injector.get(ImageService);
    }

    doInit() {
        this.calendarIcon = this.imageService.getImage('CALENDAR-ICON');
    }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    dateChanged(event: MatDatepickerInputEvent<Moment>) {
        this.form.controls[this.dynamicControlModel.key].setValue(event.value.format('YYYY-MM-DD').toString());
    }

}
