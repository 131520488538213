import {Component, OnDestroy, OnInit} from '@angular/core';
import {InactivityService} from './inactivity.service';
import {LogoutService} from '../services/logout.service';
import {from, fromEvent, merge, Subject, Subscription} from 'rxjs/index';
import {ImageService} from '../services/image.service';
import {Level} from '../utilities/logger-level';
import {Logger} from '../utilities/logger';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {MSTIConfiguration} from '../msti.config';
import {UiUtilsService} from '../utilities/ui-utils.service';

// declare var $: any;

@Component({
    selector: 'app-inactivity',
    templateUrl: './inactivity.component.html',
    styleUrls: ['./inactivity.component.scss']
})
export class InactivityComponent implements OnInit, OnDestroy {

    closeIcon;

    private sessionNotifyModalId: string;
    private timerDuration: number;
    private countdownStartTime: number;
    private lastActivityTime: number;
    private modalObject: any;
    // private mainTimer: NodeJS.Timer;
    private timerInterval: number;
    // private heartBeatTimer: NodeJS.Timer;
    private heartBeatTimerInterval: number;
    private stopActivityWatcher: Subject<any>;
    private activityWatcherSubscription: Subscription;

    public timeLeftInSeconds: number;

    constructor(
        private inactivityService: InactivityService,
        private logoutService: LogoutService,
        private imageService: ImageService,
        private dialogRef: MatDialog,
        private uiUtilsService: UiUtilsService
    ) {
        this.sessionNotifyModalId = MSTIConfiguration.constants.inactivityModalId;
        this.modalObject = $('#' + this.sessionNotifyModalId);
        this.timerInterval = MSTIConfiguration.constants.timerInterval;
        this.heartBeatTimerInterval = MSTIConfiguration.constants.heartBeatTimerInterval;
        this.timerDuration = MSTIConfiguration.constants.timerDuration;
        this.countdownStartTime = MSTIConfiguration.constants.countdownStartTime;
        this.closeIcon = this.imageService.getImage('CLOSE-ICON');
        this.stopActivityWatcher = new Subject<any>();
    }

    ngOnInit() {
        if (MSTIConfiguration.constants.enableInactivityTimeout === true) {
            this.init();
        }
    }

    ngOnDestroy() {
        this.destroy(true);
    }

    private init() {
        this.lastActivityTime = Date.now();
        this.startTimers();
        this.initUserActivityWatchers();
    }

    private destroy(stopMainTimer: boolean) {
        this.clearActivityWatcherSubscription();

        if (stopMainTimer === true) {
            // this.clearIntervalForTimer(this.mainTimer);
        }

        // this.clearIntervalForTimer(this.heartBeatTimer);

        this.stopActivityWatcher.next();
    }

    private startTimers() {
        Logger.log(Level.LOG, 'Starting all timers');
        this.startMainTimer();
        this.startHeartBeatTimer();
    }

    public startMainTimer() {
        // this.clearIntervalForTimer(this.mainTimer);
        // this.mainTimer = setInterval(() => {
        //     this.tick();
        // }, this.timerInterval);
    }

    private startHeartBeatTimer() {
        // this.clearIntervalForTimer(this.heartBeatTimer);
        // this.heartBeatTimer = setInterval(() => {
        //     this.heartBeat();
        // }, this.heartBeatTimerInterval);
    }

    private initUserActivityWatchers() {
        const userActivityEvents = merge(
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'),
            fromEvent(window, 'scroll'),
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keydown')
        );

        const activityWatcher = from(userActivityEvents);

        this.clearActivityWatcherSubscription();

        this.activityWatcherSubscription = activityWatcher.pipe(
            takeUntil(this.stopActivityWatcher.asObservable())
        ).subscribe(() => {
            this.lastActivityTime = Date.now();
        });
    }

    private clearActivityWatcherSubscription() {
        if (this.activityWatcherSubscription) {
            this.activityWatcherSubscription.unsubscribe();
        }
    }

    private tick() {
        this.timeLeftInSeconds = this.timerDuration - this.secondsSinceLastActivity();
        if (this.timeLeftInSeconds <= this.countdownStartTime) {
            this.manageInactivity();
        }
    }

    private heartBeat() {
        const userIsActive = this.secondsSinceLastActivity() < (this.heartBeatTimerInterval / 1000);
        Logger.log(Level.LOG, 'Heartbeat triggered, userIsActive is: ' + userIsActive);
        if (userIsActive === true) {
            this.continueWithSession();
        }
    }

    private secondsSinceLastActivity(): number {
        return ((Date.now() - this.lastActivityTime) / this.timerInterval) | 0;
    }

    private manageInactivity() {
        if (this.timeLeftInSeconds > 0) {
            this.destroy(false);
            this.showModal();
        } else {
            this.logoutUser();
        }
    }

    private stopAllTimers() {
        Logger.log(Level.LOG, 'Stopping all timers');
        // this.clearIntervalForTimer(this.mainTimer);
        // this.clearIntervalForTimer(this.heartBeatTimer);
    }

    private clearIntervalForTimer(timer) {
      clearInterval(timer);
      if (timer !== undefined && timer !== null) {
        }
    }

    private showModal() {
        if (this.modalObject) {
            $('#' + this.sessionNotifyModalId).modal('show');
            this.uiUtilsService.addClass('.modal', 'display-flex');
            this.dialogRef.closeAll();
        }
    }

    private hideModal() {
        if (this.modalObject) {
            $('#' + this.sessionNotifyModalId).modal('hide');
            this.uiUtilsService.removeClass('.modal', 'display-flex');
        }
    }

    public logoutUser() {
        this.hideModal();
        this.stopAllTimers();
        this.logoutService.logout();
    }

    public continueWithSession() {
        this.hideModal();
        this.inactivityService.keepSessionAlive();
        this.init();
    }
}
