import {Input} from '@angular/core';
import {AutoUnsubscribe} from './auto-unsubscribe';

export class MSTIParentComponent extends AutoUnsubscribe {

    @Input() isParentFormSubmitted;

    constructor() {
        super();
    }

    isFieldValid(field: any) {
        if (field.errors) {
            if ((this.isParentFormSubmitted || field.touched) && field.errors.validateObj) {
                return true;
            } else {
                return false;
            }
        }
    }

    clearField(input) {
        input.target.value = '';
        input.target.dispatchEvent(new Event('input'));
    }

    filter(filterModel, val) {
        filterModel.filter(val);
    }

}
