import {FormGroup} from '@angular/forms';

export class CardTabMenuItemParamsModel {
    constructor(
        public form: FormGroup,
        public fromTabIndex: number,
        public toTabIndex: number,
        public allowProceedToNextTab: boolean
    ) {}
}
