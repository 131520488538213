import {LookupModel} from './lookup.model';

export class NumberLookupModel extends LookupModel {

    value: number;

    constructor(value: number) {
        super();
        this.value = value;
    }

    public getCode(): string {
        return this.value.toString();
    }

    public getDescription(): string {
        return this.value.toString();
    }

}
