export class PremiumDisplayModel {
  totalPremium: TotalPremiumDisplayModel;
  brokerCommission: BrokerCommissionDisplayModel;
  sections: SectionListModel[] = [];

  /*  sectionName: string;
    riskDescription: string;
    totalRiskPremium: string;
    totalSasria: string;
    sectionName: string;
    riskDescription: string;
    riskDescription: string;
    childelements: PCalcChildElements[] = [];*/

}

export class PCalcChildElements {
  label: string;
  sumInsured: string;
  excess: string;
  premium: string;

}

export class BrokerCommissionDisplayModel {
  firstName: string;
  lastName: string;
  totalCommission: string;
  adminFee: string;
}

export class TotalPremiumDisplayModel {
  premiumValue: string;
  premiumVatComponent: string;
  premiumExlVat: string;
}

export class SectionListModel {
  sectionName: string;
  riskList: PremiumRiskList[] = [];
}

export class PremiumRiskList {
  riskDescription: string;
  excessValue: string;
  premium: string;
  sasria: string;
}


