import {FilteredDropDownModel} from '../filtered-dropdown/filtered-drop-down.model';
import {NumberLookupModel} from '../lookup/number-lookup.model';

export class NumberFilteredDropDownModel extends FilteredDropDownModel<NumberLookupModel> {

    doFilter(filterValue: string) {
        this.filteredValues = this.values.filter(option =>
            option.toString().toLowerCase().startsWith(filterValue)
        );
    }
}
