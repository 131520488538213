export class PreviousClaimsModel {
  public event: string; // DELETE ,CREATE, UPDATE
  public claimId: number;
  public riskCode: string;
  public dateOfClaim: string;
  public typeOfClaimCode: string;
  public claimAmount: string;
  public previousClaimHistoryId: string;
  public sectionCode: string;
  public customeEntry: boolean;
//New filed for IDS claims
  public discardyn: string;// discard yes or no
  public origin: string;// User Entered claim or IDC claim

  constructor(claimId: number, event: string, origin: string) {
    this.claimId = claimId;
    this.event = event;
    this.origin = origin;

  }
}
