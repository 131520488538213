import {Injectable} from '@angular/core';
import {MSTIService} from '../../shared/services/msti.service';
import {UrlService} from '../../shared/services/url.service';
import {AppValidationModel, SectionValidationModel} from '../models/app-validation-model.model';

@Injectable()
export class AppMangerService {
    public appValidations: AppValidationModel;

    constructor(private mstiService: MSTIService, private urlService: UrlService) {

    }

    public getConfig() {
        return this.mstiService.getConfig(this.urlService.getConfigUrl());

    }

    public setValidationsAndConfigData(response: any) {
        this.appValidations = Object.assign(new AppValidationModel(), response);

        this.getValidation('3002');
    }

    public getValidation(sectionCode: string): SectionValidationModel { // returns undefined if the section is not found
        const section = this.appValidations.sectionValidations.find(x => x.sectionCode === sectionCode);
        return section;

    }

}
