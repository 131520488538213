import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {MainAddressComponent} from './component/main-address/main-address.component';
import {PersonalComponent} from './component/personal.component';
import {PersonalDetailsComponent} from './component/personal-details/personal-details.component';
import {PersonalService} from './service/personal.service';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PersonalDetailsHandlerService} from './service/personal-details-handler.service';
import {ClientUrlService} from './service/client-url.service';

@NgModule({
    declarations: [MainAddressComponent, PersonalComponent, PersonalDetailsComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule
    ],
    exports: [],
    providers: [PersonalService, PersonalDetailsHandlerService, ClientUrlService]
})
export class PersonalModule {
}
