import {Injectable} from '@angular/core';
import {RiskOptionService} from '../../../shared/risk/risk-option.service';

@Injectable()
export class RiskPickService {


    constructor(private riskOptionService: RiskOptionService) {
    }

    getRiskOptions() {
        return this.riskOptionService.getRiskOptions();
    }

}
