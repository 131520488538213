import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {VehicleService} from './vehicle.service';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {AutocompleteDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-control.model';
import {AutocompleteMultiSelectDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete-multiselect/autocomplete-multiselect-dynamic-control.model';
import {RadioButtonYesNoDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-yesno/radiobutton-yesno-dynamic-control.model';
import {AddressDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/address/address-dynamic-control.model';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {TitleDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-control.model';
import {TitleDynamicFormControlComponent} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-form-control.component';

@Injectable()
export class VehicleDetailsService extends BaseRiskTabService {

    vehicleModificationTypes: LookupFilteredDropDownModel;
    vehicleSecurityDevices: LookupFilteredDropDownModel;
    vehicleNightTimeLocations: LookupFilteredDropDownModel;
    vehicleNightTimeAreaTypes: LookupFilteredDropDownModel;
    vehicleDayTimeLocations: LookupFilteredDropDownModel;

    private isPhysicalSameAsNightAddressSelectedSubject: BehaviorSubject<boolean>;
    private isPhysicalSameAsDayAddressSelectedSubject: BehaviorSubject<boolean>;

    constructor(
        private loaderService: LoaderService,
        private vehicleService: VehicleService
    ) {
        super();
        this.vehicleModificationTypes = new LookupFilteredDropDownModel([]);
        this.vehicleSecurityDevices = new LookupFilteredDropDownModel([]);
        this.vehicleNightTimeLocations = new LookupFilteredDropDownModel([]);
        this.vehicleNightTimeAreaTypes = new LookupFilteredDropDownModel([]);
        this.vehicleDayTimeLocations = new LookupFilteredDropDownModel([]);
    }

    init(): void {
        this.isPhysicalSameAsNightAddressSelectedSubject = new BehaviorSubject<boolean>(true);
        this.isPhysicalSameAsDayAddressSelectedSubject = new BehaviorSubject<boolean>(true);

        this.loaderService.show();
        this.vehicleService.getModifications().then(data => {
            this.vehicleModificationTypes.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getSecurityDevices().then(data => {
            this.vehicleSecurityDevices.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getStorageTypes().then(data => {
            this.vehicleNightTimeLocations.setValues(data);
            this.vehicleDayTimeLocations.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getAreaTypes().then(data => {
            this.vehicleNightTimeAreaTypes.setValues(data);
        }).then(() => this.loaderService.hide());
    }

    isPhysicalSameAsNightAddressChanged(selectedValue) {
        this.isPhysicalSameAsNightAddressSelectedSubject.next(!this.isRadioOptionNo(selectedValue));
    }

    isPhysicalSameAsDayAddressChanged(selectedValue) {
        this.isPhysicalSameAsDayAddressSelectedSubject.next(!this.isRadioOptionNo(selectedValue));
    }

    getDynamicFormControls(): BaseDynamicControlModel<any>[] {
        const dynamicFormControls: BaseDynamicControlModel<any>[] = [
          new TitleDynamicControlModel(
            {
              key: 'Client\'s vehicle details',
              label: 'Client\'s vehicle details',
              value: '',
              style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
              pullRight: false,
              center: true,
              validation: [],
              order: 1,
            }
          ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleModificationType',
                    label: 'Are there any modifications to the vehicle?',
                    hint: 'Any performance enhancements that did not come standard with the vehicle.',
                    validation: [Validators.required],
                    order: 2,
                    availableOptionsObservable:
                        new BehaviorSubject<LookupModel[]>(this.vehicleModificationTypes.filteredValues).asObservable()
                }
            ),
            new AutocompleteMultiSelectDynamicControlModel(
                {
                    key: 'vehicleSecurityDevices',
                    label: 'Which of the following security devices does the vehicle have?',
                    hint: '',
                    value: '',
                    validation: [Validators.required],
                    order: 3,
                    options: this.vehicleSecurityDevices.filteredValues
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleNightTimeLocation',
                    label: 'Where is the vehicle parked at night?',
                    hint: '',
                    // value: '',
                    validation: [Validators.required],
                    order: 4,
                    availableOptionsObservable:
                        new BehaviorSubject<LookupModel[]>(this.vehicleNightTimeLocations.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleNightTimeAreaType',
                    label: 'Select the area where the vehicle is parked at night.',
                    hint: '',
                    validation: [Validators.required],
                    order: 5,
                    availableOptionsObservable:
                        new BehaviorSubject<LookupModel[]>(this.vehicleNightTimeAreaTypes.filteredValues).asObservable()
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'physicalAddressIsNightAddress',
                    label: 'Is your client\'s home address the same as where they park their vehicle at night?',
                    hint: '',
                    value: '',
                    validation: [Validators.required],
                    order: 6
                }
            ),
            new AddressDynamicControlModel(
                {
                    key: 'vehicleNightTimeAddress',
                    label: 'Night time address',
                    hint: '',
                    value: new AddressTypeModel('NIGHTTIME'),
                    validation: [],
                    order: 7,
                    hidden: this.isPhysicalSameAsNightAddressSelectedSubject.asObservable(),
                    clearValueObservable: this.isPhysicalSameAsNightAddressSelectedSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleDayTimeLocation',
                    label: 'Where is the vehicle parked during the day?',
                    hint: '',
                    validation: [Validators.required],
                    order: 8,
                    availableOptionsObservable:
                        new BehaviorSubject<LookupModel[]>(this.vehicleDayTimeLocations.filteredValues).asObservable()
                }
            ),
            new RadioButtonYesNoDynamicControlModel(
                {
                    key: 'physicalAddressIsDayAddress',
                    label: 'Is your client\'s home address the same as where they park their vehicle during the day?',
                    hint: '',
                    value: '',
                    validation: [Validators.required],
                    order: 9
                }
            ),
            new AddressDynamicControlModel(
                {
                    key: 'vehicleDayTimeAddress',
                    label: 'Day time address',
                    hint: '',
                    value: new AddressTypeModel('DAYTIME'),
                    validation: [],
                    order: 10,
                    hidden: this.isPhysicalSameAsDayAddressSelectedSubject.asObservable(),
                    clearValueObservable: this.isPhysicalSameAsDayAddressSelectedSubject.asObservable()
                }
            )
        ];

        return dynamicFormControls.sort((a, b) => a.order - b.order);
    }

}
