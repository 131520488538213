import {Component, Injector} from '@angular/core';
import {VehicleDriverDetailsService} from '../../service/vehicle-driver-details.service';
import {DigitalAnalyticsPageModel} from '../../../../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsElementModel} from '../../../../../shared/digital-analytics/model/digital-analytics-element.model';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';
import {Validators} from '@angular/forms';
import {driverAgeValidator} from '../../../../../shared/validators/driverAge.validator';

@Component({
  selector: 'app-vehicle-driver-details',
  templateUrl: './vehicle-driver-details.component.html',
  styleUrls: ['./vehicle-driver-details.component.scss'],
  providers: [VehicleDriverDetailsService]
})
export class VehicleDriverDetailsComponent extends BaseRiskTabComponent {

  constructor(
    injector: Injector,
    private vehicleDriverDetailsService: VehicleDriverDetailsService
  ) {
    super(injector);
  }

  getRiskTabService(): BaseRiskTabService {
    return this.vehicleDriverDetailsService;
  }

  initAnalytics(): void {
    this.subscriptions.push(
      this.vehicleDriverDetailsService.regularDriverHintClickedEvent.subscribe(() => {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_VEHICLE_REGULAR_DRIVER_INFO,
          attributes
        );
      })
    );

    this.subscriptions.push(
      this.vehicleDriverDetailsService.drivingConvictionsHintClickedEvent.subscribe(() => {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_VEHICLE_DRIVING_CONVICTIONS_INFO,
          attributes
        );
      })
    );

    this.subscriptions.push(
      this.tabLoadedEventEmitter.subscribe(() => {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_DRIVER_DETAILS_PAGE_ID,
          attributes);
      })
    );
  }

  doComponentInit(): void {
    this.subscriptions.push(
      this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleIsRegularDriver').valueChanges.subscribe(
        selectedValue => {
          const val = this.vehicleDriverDetailsService.isRadioOptionYes(selectedValue);
          if (val) {
            const rsaId = this.vehicleDriverDetailsService.getRsaIdForInsurer();
            const dob = this.vehicleDriverDetailsService.getDobFromRsaId(rsaId);
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').setValue(null);
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').clearValidators();
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').setValidators(Validators.compose([Validators.required, driverAgeValidator(dob)]));

          } else {
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverIDNumber').valueChanges.subscribe(
              selectedId => {
                if (selectedId) {
                  const dob = this.vehicleDriverDetailsService.getDobFromRsaId(selectedId);
                  this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').setValue(null);
                  this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').clearValidators();
                  this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').setValidators(Validators.compose([Validators.required, driverAgeValidator(dob)]));
                }

              }
            );
          }
          this.vehicleDriverDetailsService.isRegularDriverChanged(selectedValue);
        }
      )
    );

    this.subscriptions.push(
      this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverTitle').valueChanges.subscribe(
        selectedValue => {
          this.vehicleDriverDetailsService.updateVehicleRegularDriverGender(selectedValue);
        }
      )
    );
    this.subscriptions.push(
      this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverIDNumber').valueChanges.subscribe(
        selectedValue => {
          if (selectedValue) {
            const dob = this.vehicleDriverDetailsService.getDobFromRsaId(selectedValue);
            this.cardTabContainerComponent.dynamicFormComponent.form.get('vehicleRegularDriverLicenseDateIssued').setValidators(Validators.compose([Validators.required, driverAgeValidator(dob)]));
          }

        }
      )
    );
  }

  propagatePostLoadUpdates(): void {
  }

  getGoogleAnalyticsEventLabel(): string {
    return GoogleAnalyticsEventModel.EVENT_COMPLETE_DRIVER_DETAILS_ON_VEHICLE_COVER;
  }

}
