import {BuildingStructureDetailsModel} from './building-structure-details.model';
import {BaseRiskModel} from '../../base/model/base-risk.model';
import {BuildingDetailsModel} from './building-details.model';
import {CifBuildingStructureModel} from './cif/cif-building-structure.model';
import {CifSecurityFeatureModel} from '../../vehicle/model/cif/cif-security-feature.model';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

export class BuildingModel extends BaseRiskModel {

    public riskId: string;
    sectionCode: string;
    buildingTypeCode: string;
    industryTypeCode: string;
    sumInsured: string;
    isHolidayResidence: string;
    previousBurglaries: string;
    neighbourTypeCode: string;
    ownershipTypeCode: string;
    occupancyTypeCode: string;
    occupantTypeCode: string;
    riskAddress: PifRiskAddressModel = new PifRiskAddressModel();
    structure: BuildingStructureDetailsModel;

    constructor() {
        super();
    }

    static create(riskId: string, buildingDetailsModel: BuildingDetailsModel, structureDetailsModel: BuildingStructureDetailsModel) {
        const building = new BuildingModel;
        building.riskId = riskId;
        building.sectionCode = '3001';
        building.buildingTypeCode = structureDetailsModel.structureType.getCode();
        building.industryTypeCode = buildingDetailsModel.industryTypeCode;
        building.sumInsured = buildingDetailsModel.buildingAmount;

        if (buildingDetailsModel.buildingAddressSameAsPhysical === '0') { // Address is not the same
            building.riskAddress = PifRiskAddressModel.getPifAddressModel(buildingDetailsModel.buildingAddress);
            building.riskAddress.defaultToPhysicalAddress = false;
        } else {
           // building.riskAddress = new CifRiskAddressModel();
            building.riskAddress.defaultToPhysicalAddress = true;
        }

        building.riskAddress.areaTypeCode = buildingDetailsModel.buildingAreaType.getCode();
        building.isHolidayResidence = buildingDetailsModel.buildingIsHolidayResidence;
        building.previousBurglaries = buildingDetailsModel.buildingBurglaries;
        building.neighbourTypeCode = buildingDetailsModel.buildingNeighbourhood.getCode();
        building.ownershipTypeCode = buildingDetailsModel.buildingOwnership.getCode();
        building.occupancyTypeCode = buildingDetailsModel.buildingPropertyOccupation.getCode();
        building.occupantTypeCode = buildingDetailsModel.buildingPropertyOccupants.getCode();

        building.structure = structureDetailsModel;

        return building;
    }

    getCifStructureModel(): CifBuildingStructureModel {
        const structure = new CifBuildingStructureModel();
        structure.constructionApproved = (this.structure.structureApproved === '0' ? false : true);

        if (this.structure.structureLighting === null) {
            structure.lightingConductor = null;
        } else {
            structure.lightingConductor = (this.structure.structureLighting === '0' ? false : true);
        }

        structure.numberOfBedrooms = this.structure.structureBedRooms;
        structure.roofConstructionTypeCode = this.structure.structureRoofMaterial.getCode();

        for (const securityFeature of this.structure.structureSecurity) {
            structure.securityFeatures.push(new CifSecurityFeatureModel('', securityFeature.getCode()));
        }

        structure.commune = (this.structure.structureCommune === '0' ? false : true);
        structure.wallConstructionTypeCode = this.structure.structureWallMaterial.getCode();

        if (this.structure.structureHasLapa === '1') {
            structure.lapaOnPremises = true;
            structure.thatchLapa = (this.structure.structureHasThatch === '0' ? false : true);
            structure.thatchTreated = structure.thatchLapa ? (this.structure.structureIsTreated === '0' ? false : true) : null;
            structure.thatchDistanceTypeCode = structure.thatchLapa ? this.structure.structureDistance.getCode() : null;
            structure.sizeOfDwelling = structure.thatchLapa ? this.structure.structureSize : null;
        } else {
            structure.lapaOnPremises = false;
            structure.thatchDistanceTypeCode = null;
            structure.thatchLapa = null;
            structure.thatchTreated = null;
            structure.sizeOfDwelling = null;
        }

        structure.numberOfStoreysTypeCode = this.structure.structureNumberOfStoreys.getCode();
        structure.numberOfStoreysOccupiedCode = this.structure.structureOccupiedStoreys.getCode();
        structure.numberOfWaterHeatingSystems = this.structure.structureNumberOfGeysers.getCode();
        structure.roofPitchTypeCode = this.structure.structureRoofPitch.getCode();
        structure.swimmingPool = (this.structure.structureHasSwimmingPool === '0' ? false : true);
        return structure;
    }

}
