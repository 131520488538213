import {ContentsDetailsComponent} from './component/contents-details/contents-details.component';
import {StructureDetailsComponent} from './component/structure-details/structure-details.component';
import {PersonalBelongingsDetailsComponent} from '../personal-belongings/component/personal-belongings-details/personal-belongings-details.component';

export class ContentsConfig {

    public static readonly CONFIG_NAME = 'Personal Contents';
    public static readonly DEFAULT_STATUS = 'Not yet completed';
    public static readonly DEFAULT_DESCRIPTION = '';
    public static readonly DIALOG_CONFIRMATION_CLOSE_MESSAGE = 'Are you sure you want to remove this content risks cover?';
    public static readonly DIALOG_CONFIRMATION_CLOSE_TITLE = '';
    public static readonly DIALOG_CONFIRMATION_WIDTH = '45%';

    public static readonly TAB_COMPONENT_CONFIG = {
        'configTab1': ContentsDetailsComponent,
        'configTab2': StructureDetailsComponent,
        'configTab3': PersonalBelongingsDetailsComponent // TODO - Make this third  tab dynamic
    };

    public static readonly TAB_COMPONENT_HEADING_CONFIG = {
        'configTab1': 'Home contents details',
        'configTab2': 'Structure details',
        'configTab3': 'Personal belongings' // TODO - Make this third  tab dynamic
    };

    public static readonly TAB_COMPONENT_SUBHEADING_CONFIG = {
        'configTab1': 'To determine the correct amount, your client needs to calculate the total replacement value of all the personal possessions in their home and outbuildings.',
        'configTab2': 'Complete a few details about your client\'s home contents, the construction of their home and their personal belongings to make sure that we give you the right cover.',
        'configTab3': '' // TODO - Make this third  tab dynamic
    };

    public static readonly TAB_COMPONENT_ACTIVE_STATUS_CONFIG = {
        'configTab1': true,
        'configTab2': false,
        'configTab3': false // TODO - Make this third  tab dynamic
    };

    public static readonly TAB_COMPONENT_METADATA_CONFIG = {
        'configTab1': {},
        'configTab2': {},
        'configTab3': {} // TODO - Make this third  tab dynamic
    };

 }
