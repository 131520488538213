import {Injectable} from '@angular/core';
import {MSTIConfiguration} from '../../shared/msti.config';

@Injectable()
export class AdminFeeUrlService  {

  constructor() {
  }

  public readonly BROKERBFF_API = 'broker-bff/';

  getSaveUpdateGetBrokerFeeURL(agreementResourceRef: string, clientResourceRef: string): string {
    return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API + 'brokersApi/broker/' + agreementResourceRef + '/fee/' + clientResourceRef;
  }

}
