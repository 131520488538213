import {Component, Input} from '@angular/core';
import {ImageService} from '../../../shared/services/image.service';
import {SmoothScroll} from '../../../shared/utilities/smooth-scroll';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../shared/digital-analytics/model/digital-analytics-element.model';
import {GoogleAnalyticsService} from '../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../shared/google-analytics/model/google-analytics-action.model';
import {UiUtilsService} from '../../../shared/utilities/ui-utils.service';

@Component({
    selector: 'app-marketing-top',
    templateUrl: './marketing-top.component.html'
})
export class MarketingTopComponent {

    @Input() isCallCentreQuotable: boolean;

    HEADING = 'Car and Home Insurance';
    SUB_HEADING = 'At Momentum, we provide you with reliable short-term insurance to protect the stuff you worked hard for such as your car, home, and other valuables.';

    img_vehicle: string;
    img_icon_scroll: string;

    constructor(
        private imageService: ImageService,
        private digitalAnalyticsService: DigitalAnalyticsService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private uiUtilsService: UiUtilsService,
    ) {
        this.img_vehicle = this.imageService.getImage('VEHICLE');
        this.img_icon_scroll = this.imageService.getImage('SCROLL');
        this.uiUtilsService.giveAppWrapperMargin();
        window.addEventListener('resize', () => { this.uiUtilsService.giveAppWrapperMargin(); });
    }
    scrollToQuoteSection() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_MARKETING_START_QUOTE, attributes);

        const scrollToNode = document.getElementById('scrollToCoverOptions');
        SmoothScroll.smoothScrollToElement(scrollToNode, 25, 80);
    }

    scrollToComplimentarySection() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_MARKETING_WHY_US, attributes);
        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_REQUEST_MORE_INFORMATION,
            GoogleAnalyticsEventModel.EVENT_WHY_CHOOSE_CAR_AND_HOME_INSURANCE_ON_THE_MARKETING_PAGE
        );

        const scrollToNode = document.getElementById('complimentarySection');
        SmoothScroll.smoothScrollToElement(scrollToNode, 25, 80);
    }

}
