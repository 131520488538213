import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {NumberFilteredDropDownModel} from '../../../../shared/filtered-dropdown/number-filtered-drop-down.model';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {VehicleService} from './vehicle.service';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {NumberLookupModel} from '../../../../shared/lookup/number-lookup.model';
import {VehicleModelFilteredDropDownModel} from '../model/vehicle-model-filtered-drop-down.model';
import {VehicleModelModel} from '../model/vehicle-model.model';
import {DefaultLookupModel} from '../../../../shared/lookup/default-lookup.model';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {AutocompleteDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-control.model';
import {TextboxDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/textbox/textbox-dynamic-control.model';
import {ButtonDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/button/button-dynamic-control.model';
import {ButtonDynamicFormControlComponent} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/button/button-dynamic-form-control.component';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {TitleDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-control.model';
import {TitleDynamicFormControlComponent} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-form-control.component';

@Injectable()
export class VehicleDescriptionService extends BaseRiskTabService {

    private vehicleYears: NumberFilteredDropDownModel;
    private vehicleConditions: LookupFilteredDropDownModel;
    private vehicleColours: LookupFilteredDropDownModel;
    private vehicleTypes: LookupFilteredDropDownModel;
    private vehiclePossessions: LookupFilteredDropDownModel;

    private isVehicleNewSubject: Subject<boolean>;
    private vehicleMakesSubject: Subject<LookupModel[]>;
    private vehicleModelsSubject: Subject<LookupModel[]>;
    private vehicleYearUpdateSubject: Subject<NumberLookupModel>;
    private clearVehicleYearObservable: Subject<boolean>;
    private clearVehicleColourSubject: Subject<boolean>;

    public cannotFindVehicleClickedSubject: Subject<any>;

    constructor(
        private loaderService: LoaderService,
        private vehicleService: VehicleService
    ) {
        super();
        this.vehicleYears = new NumberFilteredDropDownModel();
        this.vehicleConditions = new LookupFilteredDropDownModel([]);
        this.vehicleColours = new LookupFilteredDropDownModel([]);
        this.vehicleTypes = new LookupFilteredDropDownModel([]);
        this.vehiclePossessions = new LookupFilteredDropDownModel([]);
        this.isVehicleNewSubject = new Subject<boolean>();
        this.vehicleMakesSubject = new Subject<LookupModel[]>();
        this.vehicleModelsSubject = new Subject<LookupModel[]>();
        this.vehicleYearUpdateSubject = new Subject<NumberLookupModel>();
        this.clearVehicleYearObservable = new Subject<boolean>();
        this.cannotFindVehicleClickedSubject = new Subject<any>();
        this.clearVehicleColourSubject = new Subject<boolean>();
    }

    init(): void {
        this.vehicleYears.setValues(this.vehicleService.getYears());

        this.loaderService.show();
        this.vehicleService.getConditions().then(data => {
            this.vehicleConditions.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getColours().then(data => {
            this.vehicleColours.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getTypes().then(data => {
            this.vehicleTypes.setValues(data);
        }).then(() => this.loaderService.hide());

        this.loaderService.show();
        this.vehicleService.getPossessions().then(data => {
            this.vehiclePossessions.setValues(data);
        }).then(() => this.loaderService.hide());
    }

    updateVehicleCondition(vehicleCondition, loadingPreviousData: boolean) {
        this.isVehicleNewSubject.next(this.isVehicleNew(vehicleCondition));

        if (loadingPreviousData === false) {
            if (this.isVehicleNew(vehicleCondition) === true) {
                const currentYear = (new Date()).getFullYear().toString();

                const currentYearLookup = this.vehicleYears.values.find((element: NumberLookupModel) => {
                    return element.getDescription() === currentYear;
                });

                this.vehicleYearUpdateSubject.next(currentYearLookup);
            } else {
                this.clearVehicleYearObservable.next(true);
            }
        }
    }

    loadMakes(vehicleType: LookupModel, vehicleYear: LookupModel) {
        if (vehicleType && vehicleType.getCode && vehicleYear && vehicleYear.getCode) {
            this.loaderService.show();
            this.vehicleService
                .getMakes(
                    vehicleType.getCode(),
                    vehicleYear.getCode()
                ).then(vehicleMakes => {
                    this.propagateMakeListUpdate(vehicleMakes);
                }).then(() => this.loaderService.hide());
        }
    }

    propagateMakeListUpdate(vehicleMakes: DefaultLookupModel[]) {
        // TODO - We should probably refactor the reactive form partials to use the LookupFilteredDropDownModel directly
        this.vehicleMakesSubject.next(new LookupFilteredDropDownModel(vehicleMakes).filteredValues);
    }

    loadModels(vehicleType: LookupModel, vehicleYear: LookupModel, vehicleMake: LookupModel) {
        if (vehicleType && vehicleType.getCode && vehicleYear && vehicleYear.getCode && vehicleMake && vehicleMake.getCode) {
            this.loaderService.show();
            this.vehicleService.getModels(
                vehicleType.getCode(),
                vehicleYear.getCode(),
                vehicleMake.getCode()
            ).then(vehicleModels => {
                this.propagateModelListUpdate(vehicleModels);
            }).then(() => this.loaderService.hide());
        }
    }

    propagateModelListUpdate(vehicleModels: VehicleModelModel[]) {
        // TODO - We should probably refactor the reactive form partials to use the LookupFilteredDropDownModel directly
        this.vehicleModelsSubject.next(new VehicleModelFilteredDropDownModel(vehicleModels).filteredValues);
    }

    clearMakes() {
        this.vehicleMakesSubject.next([]);
    }

    clearModels() {
        this.vehicleModelsSubject.next([]);
    }

    clearVehicleColours() {
        this.clearVehicleColourSubject.next(true);
    }

    getDynamicFormControls(): BaseDynamicControlModel<any>[] {
        const dynamicFormControls: BaseDynamicControlModel<any>[] = [
          new TitleDynamicControlModel(
            {
              key: 'Client\'s vehicle description',
              label: 'Client\'s vehicle description',
              value: '',
              style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
              pullRight: false,
              center: true,
              validation: [],
              order: 1,
            }
          ),
          new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleCondition',
                    label: 'Current vehicle condition',
                    hint: '',
                    validation: [Validators.required],
                    order: 2,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.vehicleConditions.filteredValues).asObservable()
                }
            ),
            new TextboxDynamicControlModel(
                {
                    key: 'vehicleRegistrationNumber',
                    label: 'Registration',
                    hint: '',
                    value: '',
                    clearValueObservable: this.isVehicleNewSubject.asObservable(),
                    validation: [Validators.required],
                    order: 3,
                    hidden: this.isVehicleNewSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleType',
                    label: 'Type of vehicle',
                    hint: '',
                    validation: [Validators.required],
                    order: 4,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.vehicleTypes.filteredValues).asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleYear',
                    label: 'Year model',
                    hint: 'The year must be this year for a brand new vehicle.',
                    updateValueObservable: this.vehicleYearUpdateSubject.asObservable(),
                    clearValueObservable: this.clearVehicleYearObservable.asObservable(),
                    validation: [Validators.required],
                    order: 5,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.vehicleYears.filteredValues),
                    readonly: this.isVehicleNewSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleMake',
                    label: 'Manufacturer',
                    hint: '',
                    validation: [Validators.required],
                    order: 6,
                    availableOptionsObservable: this.vehicleMakesSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleModel',
                    label: 'Model',
                    hint: 'If you do not find your vehicle model, it could be that your year model is not correct.',
                    validation: [Validators.required],
                    order: 7,
                    availableOptionsObservable: this.vehicleModelsSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehicleColour',
                    label: 'Colour',
                    hint: 'Please pick the color that is closest to your vehicle.',
                    clearValueObservable: this.clearVehicleColourSubject.asObservable(),
                    validation: [Validators.required],
                    order: 8,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.vehicleColours.filteredValues).asObservable()
                }
            ),
            new TextboxDynamicControlModel(
                {
                    key: 'vehicleVinNumber',
                    label: 'Vin number (optional)',
                    hint: 'This number can be found on your licence disc.',
                    value: '',
                    clearValueObservable: this.isVehicleNewSubject.asObservable(),
                    validation: [],
                    order: 9,
                    hidden: this.isVehicleNewSubject.asObservable()
                }
            ),
            new TextboxDynamicControlModel(
                {
                    key: 'vehicleEngineNumber',
                    label: 'Engine number (optional)',
                    hint: '',
                    value: '',
                    clearValueObservable: this.isVehicleNewSubject.asObservable(),
                    validation: [],
                    order: 10,
                    hidden: this.isVehicleNewSubject.asObservable()
                }
            ),
            new AutocompleteDynamicControlModel(
                {
                    key: 'vehiclePossession',
                    label: 'Possession of the vehicle',
                    hint: '',
                    validation: [Validators.required],
                    order: 11,
                    availableOptionsObservable: new BehaviorSubject<LookupModel[]>(this.vehiclePossessions.filteredValues).asObservable()
                }
            ),
            new ButtonDynamicControlModel(
                {
                    key: 'cannotFindVehicle',
                    label: 'Help, I can\'t find vehicle make or model',
                    hint: '',
                    value: '',
                    style: ButtonDynamicFormControlComponent.STYLE_LINK,
                    pullRight: true,
                    validation: [],
                    order: 12,
                    onClickSubject: this.cannotFindVehicleClickedSubject
                }
            )
        ];

        return dynamicFormControls.sort((a, b) => a.order - b.order);
    }

    private isVehicleNew(vehicleCondition: LookupModel) {
        if (vehicleCondition && vehicleCondition.getCode) {
            return vehicleCondition.getCode() === VehicleService.CODE_VEHICLE_CONDITION_NEW;
        } else {
            return false;
        }
    }

}
