import {PifAddressDetailsModel} from './pif-address-details.model';
import {CifRiskAddressModel} from '../cif/cif-risk-address.model';
import {AddressTypeModel} from '../../../shared/msti-agm/model/address-type.model';

export class PifRiskAddressModel {


  storageTypeCode: string; // Mandatory
  defaultToPhysicalAddress: boolean; // Mandatory - sameAsHome,if false the inherited address fields are required
  areaTypeCode: string;
  addressDetails?: PifAddressDetailsModel | null;


  static getPifInfoModel(addressTypeModel: CifRiskAddressModel): PifRiskAddressModel {
    const pifContentAddressModel: PifRiskAddressModel = new PifRiskAddressModel();
    ///  cifAddressInfoModel.addressType = addressTypeModel.addressType;
    pifContentAddressModel.addressDetails.unitNo = addressTypeModel.unitNumber;
    pifContentAddressModel.addressDetails.sectSchemeName = addressTypeModel.sectionSchemeName;
    pifContentAddressModel.addressDetails.estateCode = addressTypeModel.estateCode;
    pifContentAddressModel.addressDetails.estateName = addressTypeModel.estateName;
    pifContentAddressModel.addressDetails.streetCode = addressTypeModel.streetCode;
    // pifBuildingAddressModel.addressDetails.streetTypeCode = addressTypeModel.streetTypeCode;
    pifContentAddressModel.addressDetails.streetTypeDesc = addressTypeModel.streetTypeDesc;
    pifContentAddressModel.addressDetails.streetDesc = addressTypeModel.streetDesc;
    pifContentAddressModel.addressDetails.streetNo = addressTypeModel.streetNumber;
    pifContentAddressModel.addressDetails.suburbCode = '' + addressTypeModel.suburbCode;
    pifContentAddressModel.addressDetails.suburbDesc = addressTypeModel.suburbDesc;
    pifContentAddressModel.addressDetails.municipalityCode = '' + addressTypeModel.municipalityCode;
    pifContentAddressModel.addressDetails.municipalityName = addressTypeModel.municipalityDesc;
    pifContentAddressModel.addressDetails.provinceCode = '' + addressTypeModel.provinceCode;
    pifContentAddressModel.addressDetails.provinceDesc = addressTypeModel.provinceDesc;
    pifContentAddressModel.addressDetails.postalCode = addressTypeModel.postalCode;
    pifContentAddressModel.addressDetails.poBoxNo = addressTypeModel.poBoxNumber;
    pifContentAddressModel.areaTypeCode = addressTypeModel.areaTypeCode; /// CBD etc
    pifContentAddressModel.defaultToPhysicalAddress = addressTypeModel.sameAsHome;
    return pifContentAddressModel;
  }


  static getPifAddressModel(addressTypeModel: AddressTypeModel): PifRiskAddressModel {
    const pifBuildingAddressModel: PifRiskAddressModel = new PifRiskAddressModel();
    ///  cifAddressInfoModel.addressType = addressTypeModel.addressType;
    pifBuildingAddressModel.addressDetails = new PifAddressDetailsModel();
    pifBuildingAddressModel.addressDetails.unitNo = addressTypeModel.unitNumber;
    pifBuildingAddressModel.addressDetails.sectSchemeName = addressTypeModel.sectionSchemeName;
    pifBuildingAddressModel.addressDetails.estateCode = addressTypeModel.estateCode;
    pifBuildingAddressModel.addressDetails.estateName = addressTypeModel.estateName;
    pifBuildingAddressModel.addressDetails.streetCode = addressTypeModel.streetCode;
    // pifBuildingAddressModel.addressDetails.streetTypeCode = addressTypeModel.streetTypeCode;
    pifBuildingAddressModel.addressDetails.streetTypeDesc = addressTypeModel.streetTypeDesc;
    pifBuildingAddressModel.addressDetails.streetDesc = addressTypeModel.streetDesc;
    pifBuildingAddressModel.addressDetails.streetNo = addressTypeModel.streetNo;
    pifBuildingAddressModel.addressDetails.suburbCode = '' + addressTypeModel.suburbCode;
    pifBuildingAddressModel.addressDetails.suburbDesc = addressTypeModel.suburbDesc;
    pifBuildingAddressModel.addressDetails.municipalityCode = '' + addressTypeModel.municipalityCode;
    pifBuildingAddressModel.addressDetails.municipalityName = addressTypeModel.municipalityName;
    pifBuildingAddressModel.addressDetails.provinceCode = '' + addressTypeModel.provinceCode;
    pifBuildingAddressModel.addressDetails.provinceDesc = addressTypeModel.provinceDesc;
    pifBuildingAddressModel.addressDetails.postalCode = addressTypeModel.postalCode;
    pifBuildingAddressModel.addressDetails.poBoxNo = addressTypeModel.poBoxNo;
    return pifBuildingAddressModel;
  }

  static piftoAddressTypeModel(pifRiskAddressModel: PifRiskAddressModel): AddressTypeModel {
    const addressTypeModel = new AddressTypeModel('');
    addressTypeModel.unitNumber = pifRiskAddressModel.addressDetails.unitNo || '';
    addressTypeModel.sectionSchemeName = pifRiskAddressModel.addressDetails.sectSchemeName || '';
    addressTypeModel.estateCode = pifRiskAddressModel.addressDetails.estateCode || '';
    addressTypeModel.estateName = pifRiskAddressModel.addressDetails.estateName || '';
    addressTypeModel.streetCode = pifRiskAddressModel.addressDetails.streetCode || '';
    addressTypeModel.streetDesc = pifRiskAddressModel.addressDetails.streetDesc || '';
    // addressTypeModel.streetTypeCode = cifRiskAddressModel.addressDetails.streetTypeCode || '';
    addressTypeModel.streetTypeDesc = pifRiskAddressModel.addressDetails.streetTypeDesc || '';
    addressTypeModel.streetNo = pifRiskAddressModel.addressDetails.streetNo || '';
    addressTypeModel.suburbCode = '' + pifRiskAddressModel.addressDetails.suburbCode || '';
    addressTypeModel.suburbDesc = pifRiskAddressModel.addressDetails.suburbDesc || '';
    addressTypeModel.municipalityCode = '' + pifRiskAddressModel.addressDetails.municipalityCode || '';
    addressTypeModel.municipalityName = pifRiskAddressModel.addressDetails.municipalityName || '';
    addressTypeModel.provinceCode = '' + pifRiskAddressModel.addressDetails.provinceCode || '';
    addressTypeModel.provinceDesc = pifRiskAddressModel.addressDetails.provinceDesc || '';
    addressTypeModel.postalCode = pifRiskAddressModel.addressDetails.postalCode || '';
    addressTypeModel.poBoxNo = pifRiskAddressModel.addressDetails.poBoxNo || '';
    addressTypeModel.formatAddressLine();
    return addressTypeModel;
  }
}
