import {MSTIConfiguration} from '../msti.config';
import {Logger} from './logger';
import {Level} from './logger-level';

export class SmoothScroll {

    public static TIMING_MAP = {
        linear: t => t,
        'ease-in': t => t * t,
        'ease-out': t => t * (2 - t),
        'ease-in-out': t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
    };

    private static getElementOffset(el, extraOffSet?: number) {
        const rect = el.getBoundingClientRect();

        return {
            top: (rect.top + window.pageYOffset) - (extraOffSet || 0),
            left: rect.left + window.pageXOffset
        };
    }

    /**
     * Scroll up or down to targeted element
     * @param {HTMLElement} scrollToNode - Element to scroll to
      * @param {number} speed - Amount of scroll speed
     **/
    public static smoothScrollToElement(scrollToNode: HTMLElement, speed: number, extraOffSet?: number) {
        const getElementOffSetTop = this.getElementOffset(scrollToNode, extraOffSet);
        Logger.log(Level.LOG, 'Scroll to this Element ', scrollToNode);
        if (MSTIConfiguration.constants.enableSmoothScrolling === true) {

            const getScrollerOffSet = window.pageYOffset,
                convertToEqualOffsetTop = getElementOffSetTop.top;

            if (convertToEqualOffsetTop >= getScrollerOffSet) { // go down
                this.scrollDown(speed, convertToEqualOffsetTop, scrollToNode);
            } else { // go up
                this.scrollUp(speed, convertToEqualOffsetTop);
            }
        }
    }
    private static scrollDown(speed, convertToEqualOffsetTop, scrollToNode) {
        const scrollInterval = setInterval(() => {
            window.pageYOffset >= convertToEqualOffsetTop ? clearInterval(scrollInterval) : window.scrollBy(0, speed);
            Logger.log(
                Level.LOG,
                'Smooth scroll: going down',
                document.documentElement.scrollTop + ' ' + window.pageYOffset + ' ' + convertToEqualOffsetTop + ' ' + scrollToNode.offsetTop
            );
        }, 15);
    }

    private static scrollUp(speed, convertToEqualOffsetTop) {
        const scrollInterval = setInterval(() => {
            window.pageYOffset <= convertToEqualOffsetTop ? clearInterval(scrollInterval) : window.scrollBy(0, speed);
        }, 15);
    }


    /**
     * Scroll from current position to top of Window OR target an element to scroll to an focus on
     * @param {string} selector - Element to scroll to
     **/
    public static smoothScrollToTop(selector?): void {
        const scrollToNode = document.querySelector(selector);
        if (MSTIConfiguration.constants.enableSmoothScrolling === true) {
            if (scrollToNode) {
                Logger.log(Level.LOG, 'Scroll to Top targeting this element ', scrollToNode);
                const convertToEqualOffsetTop = this.getElementOffset(scrollToNode, 60).top;
                const scrollToTop = window.setInterval(() => {
                    window.pageYOffset <= (convertToEqualOffsetTop - 100) ? clearInterval(scrollToTop) : window.scrollBy(0, -100);
                }, 5);

            } else {
                Logger.log(Level.LOG, 'Scroll to Top of Page ', scrollToNode);
                const scrollToTop = window.setInterval(() => {
                    const position = window.pageYOffset;
                    position > 0 ? window.scrollBy(0, -100) : window.clearInterval(scrollToTop);
                }, 5);
            }
        }
    }

    /** Below method does not work for IE versions **/
    /**
     * Scroll from 0 to initY
     * @param {number} initY - initial scroll Y
     * @param {number} duration - transition duration
     * @param {string} timingName - timing function name. Can be one of linear, ease-in, ease-out, ease-in-out
     */
    public static scrollToSmooth(
        initY: number,
        duration: number = 300,
        timingName: string = 'linear'
    ) {
        if (MSTIConfiguration.constants.enableSmoothScrolling === true) {
            const timingFunc = this.TIMING_MAP[timingName];
            let start = null;
            const step = timestamp => {
                start = start || timestamp;
                const progress = timestamp - start,
                    // Growing from 0 to 1
                    time = Math.min(1, (timestamp - start) / duration);

                window.scrollTo(0, timingFunc(time) * initY);

                if (progress < duration) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        }
    }

    /**
     * Scroll to a HTML Node when a Sticky Header is present
     * @param {HTMLElement} stickyHeaderNode - Sticky Header HTML Node
     * @param {HTMLElement} scrollToNode - HTML Node to scroll to
     * @param {number} duration - transition duration
     * @param {string} timingName - timing function name. Can be one of linear, ease-in, ease-out, ease-in-out
     */
    public static scrollToElementWithStickyHeader(
        stickyHeaderNode: HTMLElement,
        scrollToNode: HTMLElement,
        duration: number = 300,
        timingName: string = 'linear'
    ) {
        if (MSTIConfiguration.constants.enableSmoothScrolling === true) {
            scrollToNode.scrollIntoView(true);
            this.scrollToSmooth(
                window.scrollY - stickyHeaderNode.offsetHeight,
                duration,
                timingName
            );
        }
    }

}
