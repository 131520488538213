export class MarketingBottomConfig {
    // Marketing Bottom General config
    public static readonly COMPLIMENTARY_SECTION_HEADING = 'Reasons to choose Momentum \n for your short-term insurance needs';
    public static readonly INSTRUCTIONS_SECTION_HEADING = 'You\'re 3 steps away from getting your quote';
    public static readonly CASHBACK_REWARDS_SECTION_HEADING = 'More about our cashback rewards';
    public static readonly CALL_CENTRE_SECTION_HEADING = 'It only takes one phone call to get currentlyInsured.';

    // Marketing Bottom config
    public static readonly COMPLIMENTARY_SECTION_ITEM_HEADING_CONFIG = {
        configKey1: 'Cashback rewards',
        configKey2: 'We understand your needs',
        configKey3: 'Convenience at your fingertips'
    };

    // Marketing Bottom Description config
    public static readonly COMPLIMENTARY_SECTION_ITEM_DESCRIPTION_CONFIG = {
        configKey1: 'You can earn up to <a href="#" target="_blank">30% cash back</a> every year on your car and home insurance premiums, even if you claim, as part of your Multiply benefit. Plus, with Momentum Rewarder, after four consecutive claim-free years, you\'ll get an additional 15% of your premiums back in cash.',
        configKey2: 'With you in mind, we have designed simple and flexible products that can be customised to your individual needs. Your premium is also based on your individual profile, meaning you only pay for your risk.',
        configKey3: 'With the Momentum app, you can conveniently submit hail and windscreen claims, as well as notify us of all other claims. In addition, you get emergency roadside and home assistance at the touch of a button.'
    };

    //  Marketing Bottom Icon config
    public static readonly COMPLIMENTARY_SECTION_ITEM_URL_CONFIG = {
        configKey1: 'assets/images/marketing/msti-cashback-reward.png',
        configKey2: 'assets/images/marketing/msti-client-needs.png',
        configKey3: 'assets/images/marketing/msti-momentum-app.png'
    };

}
