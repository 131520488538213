import {NgModule} from '@angular/core';
import {AdminFeeComponent} from './admin-fee.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MarketingModule} from '../marketing/marketing.module';
import {AdminFeeService} from './services/admin-fee.service';
import {AdminFeeUrlService} from './services/admin-fee-url.service';

@NgModule({
  declarations: [
    AdminFeeComponent
  ],
  imports: [CommonModule,
    FormsModule,
    SharedModule,
    MarketingModule],
  exports: [],
  providers: [AdminFeeService, AdminFeeUrlService]
})

export class AdminFeeModule {
}
