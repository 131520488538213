import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sentenceToName'
})
export class SentenceToNamePipe implements PipeTransform {
    /**
     * Formats a string value to a name value
     * @param {string} value - Input string to be formatted
     */
    transform(
        value: string
    ) {
        if (!value) {
            return '';
        } else {
            return value.trim().split(' ').join('-').toLowerCase();
        }
    }
}
