import {RiskOptionModel} from './risk.option.model';
import {Injectable} from '@angular/core';
import {RiskOptionDataService} from './risk-options-data.service';

@Injectable()
export class RiskOptionService {

  // TODO Remove later
  private risks: string[] = [];
  private maxNumberOfRisks = 0;

  availableRiskOptions: RiskOptionModel[] = [];

  constructor(private riskOptionDataService: RiskOptionDataService) {
  }

  public getRiskOptions(): RiskOptionModel[] {
    const risks: string[] = this.getAvailableRiskOptions();
    // TODO :: Needs Impact Analysis Later
    if (this.availableRiskOptions.length < 1) {
      // We must deep clone the options returned from the service in order to prevent the same options persisting between sessions

      this.availableRiskOptions = JSON.parse(JSON.stringify(this.riskOptionDataService.getRiskOptionsForGivenRisks(risks)));
    }
    // Sort availableRiskOptions array by Sequence
    this.availableRiskOptions = this.availableRiskOptions.sort(function (item1, item2) {
      // Ascending: first item sequence less than the previous item sequence
      return item1.sequence - item2.sequence;
    });

    return this.availableRiskOptions;
  }

  public setRisks(risks: string[]) {
    this.risks = risks;
  }

  public setMaxNumberOfRisks(maxNumberOfRisks: number) {
    this.maxNumberOfRisks = maxNumberOfRisks;
  }

  public getMaxNumberOfRisks() {
    return this.maxNumberOfRisks;
  }

  public clearAvailableOptions() {
    this.availableRiskOptions = [];
  }

  public isSingleRisk() {
    return this.availableRiskOptions.length === 1;
  }

  public selectOnlyAvailableRiskOption() {
    if (this.isSingleRisk()) {
      this.availableRiskOptions[0].isSelected = true;
    }
  }

  public selectSpecificRiskOption(riskOptionName: string) {
    const riskOption = this.findRiskOption(riskOptionName);

    if (riskOption) {
      riskOption.isSelected = true;
    }
  }

  public selectAllAvailableRiskOptions() {
    this.availableRiskOptions.forEach(riskOption => {
      riskOption.isSelected = true;
    });
  }

  public setSpecificRiskOptionOrder(riskOptionName: string, index: number) {
    const riskOption = this.findRiskOption(riskOptionName);

    if (riskOption) {
      riskOption.viewIndex = index;
    }
  }

  public getSpecificRiskOptionOrder(riskOptionName: string) {
    const riskOption = this.findRiskOption(riskOptionName);
    let orderIndex = 0;
    if (riskOption) {
      orderIndex = riskOption.viewIndex;
    }
    return orderIndex;
  }

  private findRiskOption(riskOptionName: string): RiskOptionModel {
    return this.availableRiskOptions.find(riskOption => {
      return riskOption.name === riskOptionName;
    });
  }

  public isAtLeastOneOptionSelected(): boolean {
    for (let idx = 0; idx < this.availableRiskOptions.length; idx++) {
      if (this.availableRiskOptions[idx].isSelected) {
        return true;
      }
    }
    return false;
  }

  public isNoRiskSelected(): boolean {
    return !this.isAtLeastOneOptionSelected();
  }

  public getSelectedRiskOptions() {
    const selectedRisks: RiskOptionModel[] = this.availableRiskOptions.filter((risk) => {
      return risk.isSelected;
    });

    return selectedRisks;
  }

  // Helper for Service Call
  private getAvailableRiskOptions(): string[] {
    if (this.risks.length > 0) {
      return this.risks;
    } else {
      const availableRisks: string[] = [];
      return availableRisks;
    }
  }

}
