import {CifPartyInfoModel} from './cif-party-info.model';
import {ContactInfoTypeModel} from '../contact-info-type.model';
import {CifAddressInfoModel} from './cif-address-info.model';

export class SaveClientModel {
  public clientResourceRef: string;
  public clientNumber: string;
  public personal: CifPartyInfoModel = new CifPartyInfoModel();
  public contact: ContactInfoTypeModel = new ContactInfoTypeModel();
  public addressList: CifAddressInfoModel[] = [];

}
