import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {ButtonDynamicFormControlComponent} from './button-dynamic-form-control.component';
import {Subject} from 'rxjs';

export class ButtonDynamicControlModel extends BaseDynamicControlModel<string> {

    onClickSubject: Subject<any>;
    style: string;
    pullRight: boolean;

    constructor(controlModel?: Partial<ButtonDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return ButtonDynamicFormControlComponent;
    }

}
