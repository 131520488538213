import {Injectable} from '@angular/core';

@Injectable()
export class StepperService {

    getAllSteps(): String[] {
        return ['Select cover', 'Client\'s details', 'Disclosures', 'Cover details', 'Admin fee', 'Quote'];
    }


}
