import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {CifVehicleModel} from './cif/cif-vehicle.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifVehicleAddressModel} from './cif/cif-vehicle-address.model';

export class VehicleDetailsModel {

    public vehicleModificationType: LookupModel;
    public vehicleSecurityDevices: LookupModel[];
    public vehicleNightTimeLocation: LookupModel;
    public vehicleNightTimeAreaType: LookupModel;
    public physicalAddressIsNightAddress: string;
    public vehicleNightTimeAddress: AddressTypeModel;
    public vehicleDayTimeLocation: LookupModel;
    public physicalAddressIsDayAddress: string;
    public vehicleDayTimeAddress: AddressTypeModel;

    constructor() { }

    populateFromFormData(formData) {
        this.vehicleModificationType = formData.vehicleModificationType;
        this.vehicleSecurityDevices = formData.vehicleSecurityDevices;
        this.vehicleNightTimeLocation = formData.vehicleNightTimeLocation;
        this.vehicleNightTimeAreaType = formData.vehicleNightTimeAreaType;
        this.physicalAddressIsNightAddress = formData.physicalAddressIsNightAddress;
        this.vehicleNightTimeAddress = formData.vehicleNightTimeAddress;
        this.vehicleDayTimeLocation = formData.vehicleDayTimeLocation;
        this.physicalAddressIsDayAddress = formData.physicalAddressIsDayAddress;
        this.vehicleDayTimeAddress = formData.vehicleDayTimeAddress;
    }

    async populateFromCifModel(cifModel: CifVehicleModel, lookupServiceInstance: LookupService) {
        if (cifModel) {
            this.vehicleModificationType = await lookupServiceInstance.getLookUpObjFromCode(
                cifModel.modificationCode,
                LookupService.GROUP_VEHICLE_MODIFICATION
            );

            this.vehicleSecurityDevices = [];

            for (const cifSecurityFeatureModel of cifModel.securityFeatures) {
                const lookupModel = await lookupServiceInstance.getLookUpObjFromCode(
                    cifSecurityFeatureModel.securityFeatureCode,
                    LookupService.GROUP_VEHICLE_SECURITY_DEVICE
                );

                this.vehicleSecurityDevices.push(lookupModel);
            }

            for (const cifAddressModel of cifModel.addresses) {
                if (cifAddressModel.addressType === 'NIGHTTIME') {
                    this.vehicleNightTimeLocation = await lookupServiceInstance.getLookUpObjFromCode(
                        cifAddressModel.storageTypeCode,
                        LookupService.GROUP_RISK_STORAGE_TYPE
                    );

                    this.vehicleNightTimeAreaType = await lookupServiceInstance.getLookUpObjFromCode(
                        cifAddressModel.areaTypeCode,
                        LookupService.GROUP_VEHICLE_AREA_TYPE
                    );

                    this.physicalAddressIsNightAddress = cifAddressModel.sameAsHome ? '1' : '0';
                    this.vehicleNightTimeAddress = this.readCifAddressModel(cifAddressModel);
                } else if (cifAddressModel.addressType === 'DAYTIME') {
                    this.vehicleDayTimeLocation = await lookupServiceInstance.getLookUpObjFromCode(
                        cifAddressModel.storageTypeCode,
                        LookupService.GROUP_RISK_STORAGE_TYPE
                    );

                    this.physicalAddressIsDayAddress = cifAddressModel.sameAsHome ? '1' : '0';
                    this.vehicleDayTimeAddress = this.readCifAddressModel(cifAddressModel);
                }
            }
        }
    }

    // TODO - This function is duplicated in the VehicleDriverDetailsModel class
    private readCifAddressModel(cifVehicleAddressModel: CifVehicleAddressModel): AddressTypeModel {
        const addressTypeModel = new AddressTypeModel(cifVehicleAddressModel.addressType);
        addressTypeModel.unitNumber = cifVehicleAddressModel.addressDetails.unitNo || '';
        addressTypeModel.sectionSchemeName = cifVehicleAddressModel.addressDetails.sectSchemeName || '';
        addressTypeModel.estateCode = cifVehicleAddressModel.addressDetails.estateCode || '';
        addressTypeModel.estateName = cifVehicleAddressModel.addressDetails.estateName || '';
        addressTypeModel.streetCode = cifVehicleAddressModel.addressDetails.streetCode || '';
        addressTypeModel.streetDesc = cifVehicleAddressModel.addressDetails.streetDesc || '';
        addressTypeModel.streetTypeCode = cifVehicleAddressModel.addressDetails.streetTypeCode || '';
        addressTypeModel.streetTypeDesc = cifVehicleAddressModel.addressDetails.streetTypeDesc || '';
        addressTypeModel.streetNo = cifVehicleAddressModel.addressDetails.streetNo || '';
        addressTypeModel.suburbCode = cifVehicleAddressModel.addressDetails.suburbCode || '';
        addressTypeModel.suburbDesc = cifVehicleAddressModel.addressDetails.suburbDesc || '';
        addressTypeModel.municipalityCode = cifVehicleAddressModel.addressDetails.municipalityCode || '';
        addressTypeModel.municipalityName = cifVehicleAddressModel.addressDetails.municipalityName || '';
        addressTypeModel.provinceCode = cifVehicleAddressModel.addressDetails.provinceCode || '';
        addressTypeModel.provinceDesc = cifVehicleAddressModel.addressDetails.provinceDesc || '';
        addressTypeModel.postalCode = cifVehicleAddressModel.addressDetails.postalCode || '';
        addressTypeModel.poBoxNo = cifVehicleAddressModel.addressDetails.poBoxNo || '';
        addressTypeModel.formatAddressLine();
        return addressTypeModel;
    }

}
