import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replaceLineBreaks'
})
export class ReplaceLineBreaksPipe implements PipeTransform {
    /**
     * Replaces line breaks in a string value
     * @param {string} value - Input string to be formatted
     */
    transform(
        value: string
    ) {
        if (!value) {
            return '';
        } else {
            return value.replace(/\n/g, '<br/>');
        }
    }
}
