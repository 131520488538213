import {RiskOptionDataService} from '../shared/risk/risk-options-data.service';

export class QuoteConfig {
    // Quote General config
    public static readonly HEADING = 'Cover details';
    public static readonly SUB_HEADING = 'Please provide us with accurate information.';
    public static readonly COVER_SELECTION_HEADING = 'Select new cover';
    public static readonly COVER_SELECTION_SUB_HEADING = 'Which cover would you like to add?';
    public static readonly ADD_COVER_BUTTON_TEXT = 'Add cover';

    public static DIALOG_INFO_CONFIG = {
        popUpTitle: 'You have successfully completed your client\'s quote.',
        popUpCaption: 'Please wait a moment while we calculate the best premium that will suit your client\'s cover needs.'
    };

    // Quote Cover Type config
    public static readonly COVER_TYPE_CONFIG = {
        configCoverType1: 'Vehicle',
        configCoverType2: 'Contents',
        configCoverType3: 'Building',
        configCoverType4: 'Watercraft',
        configCoverType5: 'Caravan',
        configCoverType6: 'Trailer',
        configCoverType7: 'Motorcycle',
        configCoverType8: 'AllRisk',
        configCoverType9: 'PersonalRisk'
    };

    // Quote Cover Description config
    public static readonly COVER_TYPE_DESCRIPTION_CONFIG = {
        configCoverType1: RiskOptionDataService.RISK_DESCRIPTION_VEHICLE,
        configCoverType2: RiskOptionDataService.RISK_DESCRIPTION_CONTENTS,
        configCoverType3: RiskOptionDataService.RISK_DESCRIPTION_BUILDING,
        configCoverType4: 'Watercraft',
        configCoverType5: 'Caravan',
        configCoverType6: 'Trailer',
        configCoverType7: 'Motorcycle',
        configCoverType8: 'All risk',
        configCoverType9: 'Personal risk'
    };

    // Quote Cover Description config
    public static readonly COVER_TYPE_RISK_NAME_CONFIG = {
        configCoverType1: RiskOptionDataService.RISK_NAME_VEHICLE,
        configCoverType2: RiskOptionDataService.RISK_NAME_CONTENTS,
        configCoverType3: RiskOptionDataService.RISK_NAME_BUILDING,
        configCoverType4: 'Watercraft',
        configCoverType5: 'Caravan',
        configCoverType6: 'Trailer',
        configCoverType7: 'Motorcycle',
        configCoverType8: 'All risk',
        configCoverType9: 'Personal risk'
    };

    // Quote  Cover Type Tag line config
    public static readonly COVER_TYPE_TAGLINE_CONFIG = {
        configCoverType1: 'Get peace of mind knowing your car is covered by Momentum Car Insurance, with a premium tailored to your profile. You can also insure motorcycles, caravans and trailers, and choose between comprehensive, liability and limited cover.',
        configCoverType2: 'Insure items in your home and the valuables you carry when you leave the house. You need to have Home Contents Insurance to be currentlyInsured for Personal Belongings.',
        configCoverType3: 'Be prepared for any structural damage caused by rebuilding, repairing, or renovations to your home with Momentum\'s short-term insurance.',
        configCoverType4: 'Watercraft tagline',
        configCoverType5: 'Caravan tagline',
        configCoverType6: 'Trailer tagline',
        configCoverType7: 'Motorcycle tagline',
        configCoverType8: 'We will insure your personal belongings such as your jewellery, handbag, cell phone, travel luggage, and camera, wherever you are.',
        configCoverType9: 'Personal risk tagline'
    };

    //  Quote Cover Type Icon config
    public static readonly COVER_TYPE_ICON_URL_CONFIG = {
        configCoverType1: 'MSTI-VEHICLE',
        configCoverType2: 'MSTI-HOME-CONTENT',
        configCoverType3: 'MSTI-BUILDING',
        configCoverType4: 'TEMP_260_150',
        configCoverType5: 'TEMP_260_150',
        configCoverType6: 'TEMP_260_150',
        configCoverType7: 'TEMP_260_150',
        configCoverType8: 'MSTI-ALL-RISK',
        configCoverType9: 'TEMP_260_150'
    };

    //  Quote Cover Type Active status config
    public static readonly COVER_TYPE_ACTIVE_STATUS_CONFIG = {
        configCoverType1: true,
        configCoverType2: true,
        configCoverType3: true,
        configCoverType4: false,
        configCoverType5: false,
        configCoverType6: false,
        configCoverType7: false,
        configCoverType8: false,
        configCoverType9: false
    };

    // Quote Cover Type Order config
    public static readonly COVER_TYPE_ORDER_CONFIG = {
        configCoverType1: 1,
        configCoverType2: 2,
        configCoverType3: 4,
        configCoverType4: 5,
        configCoverType5: 6,
        configCoverType6: 7,
        configCoverType7: 8,
        configCoverType8: 3,
        configCoverType9: 9
    };

    // Quote Cover Type metadata config
    public static readonly COVER_TYPE_METADATA_CONFIG = {
        configCoverType1: {},
        configCoverType2: {},
        configCoverType3: {},
        configCoverType4: {},
        configCoverType5: {},
        configCoverType6: {},
        configCoverType7: {},
        configCoverType8: {},
        configCoverType9: {}
    };
}
