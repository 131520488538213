import {Injectable} from '@angular/core';
import {UrlService} from '../../shared/services/url.service';
import {MSTIConfiguration} from '../../shared/msti.config';

@Injectable()
export class ClientUrlService extends UrlService {

    constructor() {
        super();
    }

    // save client url
    public getSaveClientURL(): string {
        // return MSTIConfiguration.constants.urlPrefix + this.FULFILLMENT_API_V2 + partyKey + '/clients';
        return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API + 'brokerClientsApi/clients';
    }

    // get client url
    public getClientDetailsURL(clientId: string): string {
        // return MSTIConfiguration.constants.urlPrefix + this.FULFILLMENT_API_V2 + partyKey + '/clients/' + clientResourceRef;
      return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API +  'brokerClientsApi/clients/' + clientId;
    }

    // get party key url
    public getPartyKeyURL(ldapGuid: string): string {
        return this.getUrl(UrlService.KEY_PARTY_KEY) + ldapGuid;
    }

    // update client url
    public getUpdateClientURL( clientId: string): string {
        // return MSTIConfiguration.constants.urlPrefix + this.FULFILLMENT_API_V2 + partyKey + '/clients/' + clientResourceRef;
      return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API +  'brokerClientsApi/clients/' + clientId;

    }

}
