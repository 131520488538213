import {Component, OnInit} from '@angular/core';
import {NeedHelpDialogService} from './need-help/services/need-help-dialog.service';
import {NeedHelpService} from './need-help/services/need-help.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

    showNeedHelp = true;

    constructor(private needHelpDialogService: NeedHelpDialogService, private needHelpService: NeedHelpService) {
        this.needHelpService.currentPageChange.subscribe((value) => {
            this.showNeedHelp = value;
        });
    }

    ngOnInit() {
    }

    openDialog() {
        this.needHelpDialogService.showDialog();
    }

}
