import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifContentStructureModel} from './cif/cif-content-structure.model';

export class ContentsStructureDetailsModel {

    constructor() {
    }

    dwellingTypeCode: string;
    structureSize: number;
    structureBedRooms: number;
    structureWallMaterial: LookupModel;
    structureType: LookupModel;
    structureCommune: string;
    structureApproved: string;
    structureLighting: string;
    structureHasThatch: string;
    structureIsTreated: string;
    structureDistance: LookupModel;
    structureSecurity: LookupModel[] = new Array();
    structureRoof: LookupModel;
    structureHasLapa: string;


    populateFromFormData(formData) {
        this.structureSize = formData.structureSize;
        this.structureWallMaterial = formData.structureWallMaterial;
        this.structureType = formData.structureType;
        this.structureBedRooms = formData.structureBedRooms;
        this.structureSecurity = formData.structureSecurity;
        this.structureCommune = formData.structureCommune;
        this.structureApproved = formData.structureApproved;
        this.structureHasThatch = formData.structureHasThatch;
        this.structureRoof = formData.structureRoof; // roof type
        if (formData.structureLighting === null) {
            this.structureLighting = null;
        } else {
            if (this.structureRoof.getDescription() === 'Thatch' || this.structureRoof.getDescription() === 'Treated Thatch') { // TODO confirm if we need to add this to building model
                this.structureLighting = formData.structureLighting;
            } else {
                this.structureLighting = null;
            }

        }
        this.structureIsTreated = formData.structureIsTreated;
        this.structureDistance = formData.structureDistance;
        this.structureHasLapa = formData.structureHasLapa;
    }

    async populateStructureFromCifModel(cifStructureModel: CifContentStructureModel, lookupServiceInstance: LookupService) {
        this.structureSize = cifStructureModel.sizeOfDwelling;
        this.structureBedRooms = cifStructureModel.numberOfBedrooms;
        this.structureCommune = (cifStructureModel.commune) ? '1' : '0';
        this.structureApproved = (cifStructureModel.constructionApproved) ? '1' : '0';

        if (cifStructureModel.lightingConductor === null) {
            this.structureLighting = null;
        } else {
            this.structureLighting = (cifStructureModel.lightingConductor) ? '1' : '0';
        }

        this.structureType = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.dwellingTypeCode, LookupService.GROUP_CONTENTS_STRUCTURE_TYPE
        );
        this.structureWallMaterial = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.wallConstructionTypeCode, LookupService.GROUP_CONTENTS_STRUCTURE_WALL_MATERIAL
        );
        this.structureRoof = await lookupServiceInstance.getLookUpObjFromCode(
            cifStructureModel.roofConstructionTypeCode, LookupService.GROUP_CONTENTS_STRUCTURE_ROOF_MATERIAL
        );
        for (const securityFeature of cifStructureModel.securityFeatures) {
            const value = await lookupServiceInstance.getLookUpObjFromCode(securityFeature.securityFeatureCode,
                LookupService.GROUP_CONTENTS_STRUCTURE_SECURITY_FEATURE);
            this.structureSecurity.push(value);
        }
        this.structureHasLapa = (cifStructureModel.lapaOnPremises) ? '1' : '0';
        if (cifStructureModel.lapaOnPremises) {
            this.structureHasThatch = (cifStructureModel.thatchLapa) ? '1' : '0';
            this.structureIsTreated = (cifStructureModel.thatchTreated) ? '1' : '0';
            this.structureDistance = await lookupServiceInstance.getLookUpObjFromCode(
                cifStructureModel.thatchDistanceTypeCode, LookupService.GROUP_CONTENTS_STRUCTURE_LAPA_DISTANCE
            );
        } else {
            this.structureHasThatch = null;
            this.structureIsTreated = null;
            this.structureDistance = null;
        }

    }

}
