export class MstiErrorModel extends Error {
  type: string;
  errors: ErrorMessages[];
  action: Action;

  constructor(error) {
    super(error);
    if (error) {
      this.type = error.type;
      this.errors = error.errors;
      this.action = error.action;
    }
  }
}

export class ErrorMessages {
  errorCode: string;
  messageType: string; // FATAL, WARNING, INFORM
  message: string;
}

export class Action {
  actionTypeCode: string;
  actionDescription: string; // FATAL, WARNING, INFORM
}
