import {NgModule} from '@angular/core';
import {SelectCoverComponent} from './select-cover.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MarketingModule} from '../marketing/marketing.module';

@NgModule({
  declarations: [
    SelectCoverComponent
  ],
  imports: [CommonModule,
    FormsModule,
    SharedModule,
    MarketingModule],
  exports: [],

})

export class SelectCoverModule {
}
