export class GoogleAnalyticsActionModel {

    public static ACTION_COMPLETED = 'Completed';
    public static ACTION_MY_CAR = 'My car';
    public static ACTION_NO = 'No';
    public static ACTION_REQUEST_MORE_INFORMATION = 'Request more information';
    public static ACTION_START_QUOTE = 'Start quote';
    public static ACTION_SUBMITTED = 'Submitted';
    public static ACTION_VIEWED = 'Viewed';
    public static ACTION_YES = 'Yes';
    public static ACTION_HOME_CONTENTS = 'Personal Belongings & Home Contents Insurance';
    public static ACTION_MY_BUILDING = 'My building';
}
