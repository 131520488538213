export class CifClientInfoModel {

  constructor(public clientResourceRef: string,
    public clientName: string,
    public clientNumber: string
  ) {
  }

  static create(clientInfo: CifClientInfoModel) {
    return new CifClientInfoModel(
      clientInfo.clientResourceRef,
      clientInfo.clientName,
      clientInfo.clientNumber
    );
  }

}
