import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {ImageService} from '../../../shared/services/image.service';
import {MSTIParentComponent} from '../../../shared/msti-parent';
import {AddressTypeModel} from '../../../shared/msti-agm/model/address-type.model';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MstiAgmPopupDialogComponent} from '../../../shared/msti-agm/msti-agm-popup-dialog/msti-agm-popup-dialog.component';
import {CollectiveAddressTypeModel} from '../../../shared/msti-agm/model/collective-address-type.model';

@Component({
  selector: 'app-main-address',
  templateUrl: './main-address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MainAddressComponent),
      multi: true
    }
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class MainAddressComponent extends MSTIParentComponent implements OnInit, ControlValueAccessor {

  @Input() value: AddressTypeModel;
  collectiveAddressTypeModel = new CollectiveAddressTypeModel();
  mapIcon: string;

  private dialogRef: MatDialogRef<MstiAgmPopupDialogComponent>;
  multipleAddressExists = false;
  lightstoneAddressError = false;

  constructor(private imageService: ImageService, public dialog: MatDialog) {
    super();
    this.mapIcon = imageService.getImage('MAP-ICON');
  }

  async ngOnInit() {
  }

  showMapPopup(value) {
    this.dialogRef = this.dialog.open(MstiAgmPopupDialogComponent, {
      width: '52%',
      panelClass: 'agm-popup-dialog',
      data: {addressModel: value, collectiveAddressTypeModel: this.collectiveAddressTypeModel}
    });

    this.dialogRef.afterClosed().subscribe(data => {

      if (this.collectiveAddressTypeModel.lightstoneAddressError) {
        this.lightstoneAddressError = true;
        this.value.formattedAddressLine = null;
      } else {
        this.lightstoneAddressError = false;
        this.updateAddress(this.collectiveAddressTypeModel.addressTypeModels[0]);
        this.multipleAddressExists = this.collectiveAddressTypeModel.multipleAddressExists;
      }

    });
  }

  writeValue(obj: AddressTypeModel) {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    // We are not handling touch input events in this component
  }

  // Private Methods
  private propagateChange = (_: any) => {
  }

  updateAddress(address) {
    this.value.estateName = address.estateName;
    this.value.formattedAddressLine = address.formattedAddressLine;
    this.value.municipalityCode = '' + address.municipalityCode;
    this.value.municipalityName = address.municipalityName;
    this.value.postalCode = address.postalCode;
    this.value.provinceCode = '' + address.provinceCode;
    this.value.provinceDesc = address.provinceDesc;
    this.value.sectionSchemeName = address.sectionSchemeName;
    this.value.streetCode = address.streetCode;
    this.value.streetDesc = address.streetDesc;
    this.value.streetNo = address.streetNo;
    this.value.streetTypeDesc = address.streetTypeDesc;
    this.value.suburbCode = '' + address.suburbCode;
    this.value.suburbDesc = address.suburbDesc;
    this.value.unitNumber = address.unitNo;
  }
}
