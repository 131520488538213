import {RegisteredIdTypeModel} from './registered-id-type.model';

export class PersonalInfoTypeModel {

    public titleCode: string;
    public titleDescription: string;
    public firstName: string;
    public lastName: string;
    public genderCode: string;
    public genderDescription: string;
    public dob: string;
    public idDetails: RegisteredIdTypeModel = new RegisteredIdTypeModel();
    public maritalStatusCode: string;
    public maritalStatusDescription: string;
    public occupationCode: string;
    public occupationDescription: string;

}
