import {ContentsStructureDetailsModel} from './contents-structure-details.model';
import {BaseRiskModel} from '../../base/model/base-risk.model';
import {ContentsDetailsModel} from './contents-details.model';
import {CifContentStructureModel} from './cif/cif-content-structure.model';
import {CifSecurityFeatureModel} from '../../vehicle/model/cif/cif-security-feature.model';
import {MstiSectionCode} from '../../../MstiSectionCode';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

export class ContentsModel extends BaseRiskModel {

  public riskId: string;
  sectionCode: string;
  industryTypeCode: string;
  sumInsured: string;
  previousBurglaries: string;
  neighbourTypeCode: string;
  occupancyTypeCode: string;
  occupantTypeCode: string;
  riskAddress: PifRiskAddressModel = new PifRiskAddressModel();
  structure: ContentsStructureDetailsModel;

  constructor() {
    super();
  }

  static create(riskId: string, contentsDetailsModel: ContentsDetailsModel, structureDetailsModel: ContentsStructureDetailsModel) {
    const content = new ContentsModel;
    content.riskId = riskId;
    content.sectionCode = MstiSectionCode.HOME_CONTENTS;
    content.industryTypeCode = contentsDetailsModel.industryTypeCode;
    content.sumInsured = contentsDetailsModel.contentsAmount;

    if (contentsDetailsModel.contentsAddressSameAsPhysical === '0') { // Address is not the same
      content.riskAddress = PifRiskAddressModel.getPifAddressModel(contentsDetailsModel.contentsAddress);
      content.riskAddress.defaultToPhysicalAddress = false;
    } else {
     // content.riskAddress = new PifRiskAddressModel();
      content.riskAddress.defaultToPhysicalAddress = true;

    }

    content.riskAddress.areaTypeCode = contentsDetailsModel.contentsAreaType.getCode();
    content.previousBurglaries = contentsDetailsModel.contentsBurglaries;
    content.neighbourTypeCode = contentsDetailsModel.contentsNeighbourhood.getCode();
    content.occupancyTypeCode = contentsDetailsModel.contentsPropertyOccupation.getCode();
    content.occupantTypeCode = contentsDetailsModel.contentsPropertyOccupants.getCode();
    content.structure = structureDetailsModel;

    return content;
  }

  getCifStructureModel(): CifContentStructureModel {
    const structure = new CifContentStructureModel();
    structure.constructionApproved = (this.structure.structureApproved === '0' ? false : true);
    structure.dwellingTypeCode = this.structure.structureType.getCode();
    if (this.structure.structureLighting === null) {
      structure.lightingConductor = null;
    } else {
      structure.lightingConductor = (this.structure.structureLighting === '0' ? false : true);
    }

    structure.numberOfBedrooms = this.structure.structureBedRooms;
    structure.roofConstructionTypeCode = this.structure.structureRoof.getCode();
    for (const securityFeature of this.structure.structureSecurity) {
      structure.securityFeatures.push(new CifSecurityFeatureModel('', securityFeature.getCode()));
    }
    structure.commune = (this.structure.structureCommune === '0' ? false : true);
    structure.wallConstructionTypeCode = this.structure.structureWallMaterial.getCode();
    if (this.structure.structureHasLapa === '1') {
      structure.lapaOnPremises = true;
      structure.thatchLapa = (this.structure.structureHasThatch === '0' ? false : true);
      structure.thatchTreated = structure.thatchLapa ? (this.structure.structureIsTreated === '0' ? false : true) : null;
      structure.thatchDistanceTypeCode = structure.thatchLapa ? this.structure.structureDistance.getCode() : null;
      structure.sizeOfDwelling = structure.thatchLapa ? this.structure.structureSize : null;
    } else {
      structure.lapaOnPremises = false;
      structure.thatchDistanceTypeCode = null;
      structure.thatchLapa = null;
      structure.thatchTreated = null;
      structure.sizeOfDwelling = null;
    }

    return structure;
  }

}
