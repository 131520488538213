import {CardTabModel} from './card.tab.model';
import {v4 as uuid} from 'uuid';

export class CardModel {

    public id;
    public tabs: CardTabModel[];
    public status: string;
    public heading: string; // TODO - should the heading not be an abstract function with section specific implementations?

    private currentStepIndex = 0;

    constructor() {
        this.id = uuid();
    }

    public getCurrentStepIndex() {
        return this.currentStepIndex;
    }

    public incrementCurrentStepIndex() {
        this.currentStepIndex++;
    }

}
