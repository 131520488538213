import {LookupModel} from '../../../../shared/lookup/lookup.model';

export class VehicleModelModel extends LookupModel {

    readonly modelCode: string;
    readonly modelDescription: string;
    readonly mmCode: string;

    public getCode(): string {
        return this.modelCode;
    }

    public getDescription(): string {
        return this.modelDescription;
    }

}
