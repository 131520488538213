export class AddressTypeModel {

    public addressType: string; // HOME, WORK, POSTAL
    public unitNumber: string;
    public sectionSchemeName: string;
    public estateCode: string;
    public estateName: string;
    public streetNo: string;
    public streetCode: string;
    public streetDesc: string;
    public streetTypeCode: string;
    public streetTypeDesc: string;
    public suburbCode: string;
    public suburbDesc: string;
    public municipalityCode: string;
    public municipalityName: string;
    public provinceCode: string;
    public provinceDesc: string;
    public postalCode: string;
    public poBoxNo: string;

    public formattedAddressLine: string;

    constructor(addressType: string) {
        this.addressType = addressType;
    }

    getLabel() {
        // TODO - Shouldn't the label rather be set as an input on the main component?
        switch (this.addressType) {
            case 'HOME':
                return 'Physical address';
            case 'WORK':
                return 'Work address';
            case 'DAYTIME':
                return 'Day time address';
            case 'NIGHTTIME':
                return 'Night time address';
            default:
                return '';
        }
    }

    formatAddressLine() {
        const address =
            this.addNoComma(this.unitNumber) +
            this.addComma(this.estateName) +
            this.addNoComma(this.streetNo) +
            this.addComma(this.streetDesc) +
            this.addComma(this.suburbDesc) +
            this.addComma(this.provinceDesc) +
            this.addComma(this.postalCode);
        this.formattedAddressLine = address.replace('/"', ' ').trim();
        if (this.formattedAddressLine.endsWith(',')) {
            this.formattedAddressLine = this.formattedAddressLine.substr(0, this.formattedAddressLine.length - 1);
        }
    }

    private addComma(str: string) {
        if (str && str.length > 0) {
            return ' ' + str + ',';
        }
        return '';
    }

    private addNoComma(str: string) {
        if (str && str.length > 0) {
            return ' ' + str;
        }
        return '';
    }

}
