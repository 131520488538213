import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {RadioButtonYesNoDynamicFormControlComponent} from './radiobutton-yesno-dynamic-form-control.component';

export class RadioButtonYesNoDynamicControlModel extends BaseDynamicControlModel<string> {

    constructor(controlModel?: Partial<RadioButtonYesNoDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return RadioButtonYesNoDynamicFormControlComponent;
    }

}
