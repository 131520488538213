import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tocurrency'
})
export class ToCurrencyPipe implements PipeTransform {
  /**
   * Formats a string value to a name value
   * @param {string} value - Input string to be formatted
   */
  transform(value: string) {
    if (isNaN(+value)) {
      return value;
    } else {
      if (!value || value === '') {
        return '';
      } else {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'ZAR',
        });
        const zarValue = formatter.format(+value).replace('ZAR', 'R').replace(/\s/g, '');
        return zarValue;
      }

    }
  }

}
