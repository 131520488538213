import {PreviousClaimsModel} from './previous-claims.model';

export class CifDisclosureQuestionsModel {
  insuranceHistory: CifInsuranceHistoryModel = new CifInsuranceHistoryModel();
  previousClaims: PreviousClaimsModel[] = [];

}

export class CifInsuranceHistoryModel {
  public recordKey: string;
  public event: string;
  public currentlyInsured: boolean;
  public currentInsurerCode: string;
  public currentInsuredPeriodCode: string;
  public uninsuredPeriodCode: string;
  public uninterruptedCoverCode: string;
  public previousInsuranceCancelledCode: string;
}

