import {AddressTypeModel} from './address-type.model';

export class CollectiveAddressTypeModel {

    public addressTypeModels: AddressTypeModel[] = [];

    public multipleAddressExists = false;

    public lightstoneAddressError = false;


}
