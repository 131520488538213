import {AppConfigModel} from './app-config-model.model';

export class AppValidationModel {
    sectionValidations: SectionValidationModel[] = [];
    appconfig: AppConfigModel;
}

export class ValidationField {
    public key: number;
    public required: boolean;
    public minimumValue: number;
    public pattern: string;


}

export class SectionValidationModel {
    public sectionCode: string;
    public description: string;
    public field = new ValidationField();
}
