import {LookupModel} from './lookup.model';

export class DefaultLookupModel extends LookupModel {

    readonly code: string;
    readonly description: string;

    public getCode(): string {
        return this.code;
    }

    public getDescription(): string {
        return this.description;
    }

}
