import {Injectable} from '@angular/core';
import {UrlService} from '../../../../shared/services/url.service';
import {MSTIConfiguration} from '../../../../shared/msti.config';

@Injectable()
export class BuildingUrlService extends UrlService {

  constructor() {
    super();
  }

  public getSaveBuildingURL(quoteId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/buildings`;
  }

  public getUpdateBuildingURL(quoteId: string, buildingId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/buildings/${buildingId}`;
  }

  public getDeleteBuildingURL(quoteId: string, buildingId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/buildings/${buildingId}`;
  }

  public getRetrieveBuildingURL(quoteId: string, buildingId: string): string {
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/buildings/${buildingId}`;
  }

  public getUpdateUnSpecifiedMovablesURL(quoteId: string, buildingId: string): string {
    // updates unspecified item for home cover.
    return `${this.baseUrlPath()}brokerClientsApi/personalQuotations/${quoteId}/buildings/${buildingId}/movables`;
  }

  private baseUrlPath(): string {
    // return `${MSTIConfiguration.constants.urlPrefix}${this.FULFILLMENT_API_V2}${this.partyKey()}`;
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}`;
  }

}
