import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatcurrency'
})
export class FormatCurrencyPipe implements PipeTransform {
    /**
     * Formats a number value to a currency value
     * @param {number} value - Value to be formatted
     * @param {string} customDisplayValue - custom display value
     * @param {string} currencySymbol - currency symbol
     * @param {number} decimalPlaces - number of decimal places
     * @param {number} thousandsSeparatorLength - length of thousands separator sections whole part
     * @param {string} thousandsSeparator - thousands separator sections delimiter
     * @param {string} decimalSeparator - decimal delimiter
     */
    transform(
        value: number,
        customDisplayValue: string = '',
        currencySymbol: string = 'R',
        decimalPlaces: number = 2,
        thousandsSeparatorLength: number = 3,
        thousandsSeparator: string = ' ',
        decimalSeparator: string = '.'
    ) {
        const re =
                '\\d(?=(\\d{' +
                (thousandsSeparatorLength || 3) +
                '})+' +
                (decimalPlaces > 0 ? '\\D' : '$') +
                ')',
            formattedValue = value.toFixed(Math.max(0, ~~decimalPlaces));

        if (customDisplayValue) {
            return customDisplayValue;
        } else {
            return currencySymbol + (decimalSeparator
                ? formattedValue.replace('.', decimalSeparator)
                : formattedValue
            ).replace(new RegExp(re, 'g'), '$&' + (thousandsSeparator || ','));
        }
    }
}
