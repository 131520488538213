export class PifVehicleAddressDetailsModel {

  public storageTypeCode: string;
  public defaultToPhysicalAddress: boolean;
  public unitNo: string;
  public streetNo: string;
  public streetCode: string;
  public streetDesc: string;
  public streetTypeDesc: string;
  public suburbCode: string;
  public municipalityCode: string;
  public provinceCode: string;
  public postalCode: string;

/*
  static cifToPif(addressDetailsModel: AddressDetailsModel): PifVehicleAddressDetailsModel {
    const pifVehicleAddressDetailsModel = new PifVehicleAddressDetailsModel();
    pifVehicleAddressDetailsModel.
    return pifVehicleAddressDetailsModel;
  }*/


}
