import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NeedHelpThankYouMessageComponent} from './need-help/thank-you-message/thank-you-message.component';


const appRoutes: Routes = [
    { path: 'app-need-help-thankyou', component: NeedHelpThankYouMessageComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class FooterRoutingModule {
}
