export class DigitalAnalyticsConversionModel {

    public static PAGE_CONVERSION_SELECT_COVER = 'MSTI: Select cover';
    public static PAGE_CONVERSION_COMPLETE_PERSONAL_INFO = 'MSTI: Complete personal information';
    public static PAGE_CONVERSION_COMPLETE_DISCLOSURES = 'MSTI: Complete disclosures';
    public static PAGE_CONVERSION_COMPLETE_COVER_DETAILS = 'MSTI: Complete cover details';
    public static PAGE_CONVERSION_SUBMIT_QUOTE = 'MSTI: Submit quote';

    public static PAGE_CONVERSION_BEGIN = '1';
    public static PAGE_CONVERSION_COMPLETE = '2';

    public static CATEGORY_MSTI = 'MSTI';

    public eventID: string;
    public actionType: string;
    public eventCategoryID: string;
    public attributeString: string;

}
