import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-marketing-call-centre',
  templateUrl: './marketing-call-centre.component.html',
  styleUrls: ['./marketing-call-centre.component.scss']
})
export class MarketingCallCentreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
