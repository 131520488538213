import {AfterViewChecked, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {CardModel} from '../../../card/model/card.model';
import {CardTabModel} from '../../../card/model/card.tab.model';
import {MstiConfirmationDialogComponent} from '../../../../shared/pop-up-dialogs/msti-confirmation-dialog/msti-confirmation-dialog.component';
import {MatDialog} from '@angular/material';
import {DigitalAnalyticsService} from '../../../../shared/digital-analytics/digital-analytics.service';
import {BaseRiskService} from '../service/base-risk.service';
import {GoogleAnalyticsService} from '../../../../shared/google-analytics/google-analytics.service';
import {UiUtilsService} from '../../../../shared/utilities/ui-utils.service';
import {SmoothScroll} from '../../../../shared/utilities/smooth-scroll';

export abstract class BaseRiskComponent implements OnInit, AfterViewChecked {

    @Input() existingRiskIds: string[];

    @Output() onAllCardsComplete = new EventEmitter();

    private dialog: MatDialog;
    private digitalAnalyticsService: DigitalAnalyticsService;
    private googleAnalyticsService: GoogleAnalyticsService;
    private uiIUtilsService: UiUtilsService;

    constructor(
        injector: Injector
    ) {
        this.dialog = injector.get(MatDialog);
        this.digitalAnalyticsService = injector.get(DigitalAnalyticsService);
        this.googleAnalyticsService = injector.get(GoogleAnalyticsService);
        this.uiIUtilsService = injector.get(UiUtilsService);
    }

    async ngOnInit() {
      SmoothScroll.smoothScrollToTop();
      if (!this.getRiskService().getRiskCards() || this.getRiskService().getRiskCards().length === 0) {
            if (this.existingRiskIds && this.existingRiskIds.length > 0) {
                await this.loadCards();
            } else {
                this.addCard(true);
            }
        }
    }

    ngAfterViewChecked() {
        this.uiIUtilsService.addClassToLongLabels();
        this.checkCompletedCards();
    }

    addCard(isOnInit = false) {
        if (!isOnInit) {
            this.getRiskService().fireAddCardAnalytics(this.getRiskConfig().CONFIG_NAME);
        }

        const cardModel = new CardModel();
        cardModel.tabs = this.getCardTabModels(cardModel.id);
        cardModel.status = this.getRiskConfig().DEFAULT_STATUS;
        cardModel.heading = this.getRiskConfig().DEFAULT_DESCRIPTION;

        this.getRiskService().addRiskCard(cardModel);
    }

    async loadCards() {
        for (const riskId of this.existingRiskIds) {
            const cardModel = new CardModel();

            await this.getRiskService().retrieveRiskDataFromCif(riskId, cardModel.id).then(() => {
                cardModel.tabs = this.getCardTabModels(cardModel.id);
            });

            cardModel.status = '';
            cardModel.heading = this.getRiskService().getExistingCardHeading(cardModel.id);

            this.getRiskService().addRiskCard(cardModel);
        }
    }

    getCardTabModels(cardId: string) {
        const cardTabModels = new Array<CardTabModel>();

        const tabComponentModelConfig = this.getComponentModelConfig(cardId);
        const tabComponentConfig = this.getRiskConfig().TAB_COMPONENT_CONFIG;
        const tabComponentHeadingConfig = this.getRiskConfig().TAB_COMPONENT_HEADING_CONFIG;
        const tabComponentSubHeadingConfig = this.getRiskConfig().TAB_COMPONENT_SUBHEADING_CONFIG;
        const tabComponentActiveStatusConfig = this.getRiskConfig().TAB_COMPONENT_ACTIVE_STATUS_CONFIG;
        const tabComponentMetaDataConfig = this.getRiskConfig().TAB_COMPONENT_METADATA_CONFIG;
        const tabMenuItems = this.getTabMenuItems();

        for (const [key, value] of Object.entries(tabComponentModelConfig)) {
            cardTabModels.push(
                new CardTabModel(
                    tabComponentConfig[key],
                    tabComponentHeadingConfig[key],
                    tabComponentSubHeadingConfig[key],
                    tabComponentActiveStatusConfig[key],
                    tabComponentModelConfig[key],
                    tabComponentMetaDataConfig[key],
                    tabMenuItems,
                    new EventEmitter<any>()
                )
            );
        }

        return cardTabModels;
    }

    getTabMenuItems() {
        const tabComponentHeadingConfig = this.getRiskConfig().TAB_COMPONENT_HEADING_CONFIG;
        const menuItems = new Array<string>();

        if (tabComponentHeadingConfig && Object.entries(tabComponentHeadingConfig).length > 0) {
            for (const [key, value] of Object.entries(tabComponentHeadingConfig)) {
                menuItems.push(value.toString());
            }
        }
        return menuItems;
    }

    confirmRemoveCard(cardToRemove: CardModel) {
        const dialogRef = this.openDialog();

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.removeCard(cardToRemove);
            }
        });
    }

    removeCard(cardToRemove: CardModel) {
        this.getRiskService().fireRemoveCardAnalytics(this.getRiskConfig().CONFIG_NAME);
        this.getRiskService().removeRiskCard(cardToRemove);
    }

    openDialog() {
        return this.dialog.open(MstiConfirmationDialogComponent, {
            width: this.getRiskConfig().DIALOG_CONFIRMATION_WIDTH,
            panelClass: 'confirmation-popup-dialog',
            data: {
                message: this.getRiskConfig().DIALOG_CONFIRMATION_CLOSE_MESSAGE,
                title: this.getRiskConfig().DIALOG_CONFIRMATION_CLOSE_TITLE
            }
        });
    }

    persistFormData($event) {
        this.getRiskService().persistFormData($event);
    }

    onTabStatusChanged() {
        this.checkCompletedCards();
    }

    checkCompletedCards() {
        const completeCardsCount = this.getRiskService().getCompletedCardCount();

        if (
            (completeCardsCount > 0 && completeCardsCount === this.getRiskService().getRiskCards().length) ||
            this.getRiskService().getRiskCards().length === 0
        ) {
            this._onAllCardsComplete(true);
        } else {
            this._onAllCardsComplete(false);
        }
    }

    _onAllCardsComplete(areAllCardsComplete: boolean) {
        // TODO - Ensure that the value in CONFIG_NAME always matches the corresponding value in RiskOptionDataService
        this.onAllCardsComplete.emit({
            'section': this.getRiskConfig().CONFIG_NAME,
            'areAllCardsComplete': areAllCardsComplete,
            'numberOfCards': this.getRiskService().getRiskCards().length
        });
    }

    abstract getRiskService(): BaseRiskService;
    abstract getRiskConfig();
    abstract getComponentModelConfig(cardId: string);

}
