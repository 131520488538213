import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';

@Injectable()
export class RoutingService {

    constructor(
        private router: Router,
    ) { }

    public route(whereToGo: String) {
        this.router.navigate([whereToGo], { skipLocationChange: true });
    }

    public routeWithExtras(whereToGo: String, navigationExtras: NavigationExtras) {
        navigationExtras.skipLocationChange = true;
        this.router.navigate([whereToGo], navigationExtras);
    }

}
