import {Directive, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DynamicComponentService} from './DynamicComponentService';

@Directive({
    selector: '[appDynamicComponentContainerDirective]',
})
export class DynamicComponentContainerDirective implements OnInit {

    @Input() id: string;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private dynamicComponentService: DynamicComponentService
    ) { }

    ngOnInit(): void {
        this.dynamicComponentService.registerContainer(this.id, this.viewContainerRef);
    }

}
