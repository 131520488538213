import {MSTIConfiguration} from '../msti.config';
import {Injectable} from '@angular/core';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';

@Injectable()
export class ImageService {

    images: Map<string, string> = new Map<string, string>();

    private initialised: boolean;

    constructor() {
        this.initialised = false;
    }

    private initialize() {
        // IMAGES
        this.images.set('VEHICLE', MSTIConfiguration.baseUIPath + 'assets/images/slider/post-login-hero-image.jpg');
        this.images.set('BKG-PRE-LOGIN', MSTIConfiguration.baseUIPath + 'assets/images/slider/pre-login-hero-image.jpg');
        this.images.set('FOOTER', MSTIConfiguration.baseUIPath + 'assets/images/profiles/grid.png');
        this.images.set('LOGO', MSTIConfiguration.baseUIPath + 'assets/images/logo/momentum-logo.svg');
        this.images.set('SCROLL', MSTIConfiguration.baseUIPath + 'assets/images/icons/icon-scroll-indicator.svg');
        this.images.set('TEMP_260_150', MSTIConfiguration.baseUIPath + 'assets/images/profiles/206x150.svg');

        this.images.set('MSTI-ALL-RISK', MSTIConfiguration.baseUIPath + 'assets/images/cover-items/msti-all-risk.svg');
        this.images.set('MSTI-BUILDING', MSTIConfiguration.baseUIPath + 'assets/images/cover-items/msti-building.svg');
        this.images.set('MSTI-HOME-CONTENT', MSTIConfiguration.baseUIPath + 'assets/images/cover-items/msti-home-content-and-all-risk.svg');
        this.images.set('MSTI-VEHICLE', MSTIConfiguration.baseUIPath + 'assets/images/cover-items/msti-vehicle.svg');

        this.images.set('MSTI-CASHBACK-REWARD', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-cashback-reward.png');
        this.images.set('MSTI-CLIENT-NEEDS', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-client-needs.png');
        this.images.set('MSTI-MOMENTUM-APP', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-momentum-app.png');
        this.images.set('MSTI-MOBILE-DESKTOP', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-mobile-desktop.png');
        this.images.set('MSTI-MOBILE-DESKTOP_BACK', MSTIConfiguration.baseUIPath + 'assets/images/marketing/mobile-back.png');
        this.images.set('MSTI-MOBILE-DESKTOP_FRONT', MSTIConfiguration.baseUIPath + 'assets/images/marketing/Mobile-front.png');
        this.images.set('MSTI-CASHBACK-REWARD-COVER', MSTIConfiguration.baseUIPath + 'assets/images/marketing/cashback-rewards.png');
        this.images.set('MSTI-CASHBACK-REWARD-COVER-VERTICAL', MSTIConfiguration.baseUIPath + 'assets/images/marketing/cashback-rewards-vertical.png');
        this.images.set('MSTI-QUOTE-STEPS-BACKGROUND', MSTIConfiguration.baseUIPath + 'assets/images/marketing/quotesteps-background.png');

        this.images.set('MSTI-CASHBACK-REWARD-PRELOGIN', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-cashback-reward.png');
        this.images.set('MSTI-CLIENT-NEEDS-PRELOGIN', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-client-needs.png');
        this.images.set('MSTI-MOMENTUM-APP-PRELOGIN', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-momentum-app.png');
        this.images.set('MSTI-MOBILE-DESKTOP-PRELOGIN', MSTIConfiguration.baseUIPath + 'assets/images/marketing/msti-mobile-desktop.png');

        this.images.set('PREMIUM-MOMENTUM-ASSIST', MSTIConfiguration.baseUIPath + 'assets/images/premium/momentum-assist.png');
        this.images.set('PREMIUM-MULTIPLY-STARTER', MSTIConfiguration.baseUIPath + 'assets/images/premium/multiply-starter.png');
        this.images.set('PREMIUM-SAFE-DAYZ', MSTIConfiguration.baseUIPath + 'assets/images/premium/safe-dayz.png');

        // ICONS
        this.images.set('MAP-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/Map.svg');
        this.images.set('INFO-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/Info.svg');
        this.images.set('CLOSE-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/close-icon.svg');
        this.images.set('WELL-DONE-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/well-done.svg');
        this.images.set('CALENDAR-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/calendar.svg');
        this.images.set('MSTI-CASHBACK-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/msti-cashback.svg');
        this.images.set('MSTI-LOCK-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/msti-lock.svg');
        this.images.set('MSTI-MULTIPLY-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/msti-multiply.svg');
        this.images.set('MSTI-SAFEDAYZ-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/msti-safedayz.svg');
        this.images.set('PLUS-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/plus-icon.svg');
        this.images.set('EQUALS-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/equals-icon.svg');
        this.images.set('ERROR-ROBOT', MSTIConfiguration.baseUIPath + 'assets/images/icons/error-robot.svg');
        this.images.set('NO-SUBURB', MSTIConfiguration.baseUIPath + 'assets/images/icons/no-suburb.svg');
        this.images.set('CARD-CLOSE-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/card-close-icon.svg');
        this.images.set('CARD-EDIT-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/card-edit-icon.svg');
        this.images.set('EXCLAMATION-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/exclamation.svg');
        this.images.set('WELL-DONE-THIN-ICON', MSTIConfiguration.baseUIPath + 'assets/images/icons/well-done-thin.svg');

        Logger.log(Level.LOG, 'Image service initialized ' + MSTIConfiguration.baseUIPath);

        this.initialised = true;
    }

    public getImage(key: string): string {
        if (this.initialised === false) {
            this.initialize();
        }

        const imagePath = this.images.get(key);
        Logger.log(Level.LOG, 'Image Path for: ' + key + ' is: ' + imagePath);
        return imagePath;
    }

}
