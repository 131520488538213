import {Injectable} from '@angular/core';
import {UrlService} from '../../shared/services/url.service';

@Injectable()
export class PremiumUrlService extends UrlService {
  constructor(private urlService: UrlService) {
    super();
  }

  // Broker terms and conditions url
  public getSaveTermsAndConditionsURL() {
    return this.urlService.getUrl('PREMIUM_TERMS_AGREEMENT');
  }

  public getBffSaveTermsAndConditionsURL(partyKey: string) {
    return this.urlService.getBffTandCUrl() + partyKey;
  }
}
