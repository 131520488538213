import {Component, OnInit} from '@angular/core';
import {ImageService} from '../../shared/services/image.service';
import {DisclosureService} from '../services/disclosure.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsConversionModel} from '../../shared/digital-analytics/model/digital-analytics-conversion.model';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {RoutingService} from '../../shared/services/routing.service';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';

@Component({
    selector: 'app-disclosure-thankyou',
    templateUrl: './disclosure-thankyou.component.html'
})
export class DisclosureThankyouComponent implements OnInit {

    wellDoneIcon: any;

    constructor(
        private imageService: ImageService,
        private disclosureService: DisclosureService,
        private digitalAnalyticsService: DigitalAnalyticsService,
        private needHelpService: NeedHelpService,
        private routingService: RoutingService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) { }

    ngOnInit() {
        this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CONFIRMATION);
        this.initializeIcons();
        this.setAnalytics();
    }

    private setAnalytics() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_CONFIRMATION_PAGE_ID, attributes);
    }

    private initializeIcons() {
        this.wellDoneIcon = this.imageService.getImage('WELL-DONE-ICON');
    }

    onNext() {
        this.fireAnalyticsConversionTag();
        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_YES,
            GoogleAnalyticsEventModel.EVENT_PROCEED_TO_COVER_DETAILS
        );
        this.routingService.route('/quote');
    }

    onBack() {
        this.disclosureService.goBack();
    }

    private fireAnalyticsConversionTag() {
        const attributes: Map<number, string> = new Map<number, string>();

        const disclosureQuestionsModel = this.disclosureService.getDisclosureQuestionsModel();

        attributes.set(1, ''); // Note: this is a placeholder for the selected risks parameter used for the "MSTI: Select cover" tag
        attributes.set(2, disclosureQuestionsModel.currentlyInsured);
        attributes.set(3, disclosureQuestionsModel.currentInsurerInfo ? disclosureQuestionsModel.currentInsurerInfo.description : '');
        attributes.set(4, disclosureQuestionsModel.uninsuredPeriodInfo ? disclosureQuestionsModel.uninsuredPeriodInfo.description : '');
        attributes.set(5, disclosureQuestionsModel.insuredPeriodInfo ? disclosureQuestionsModel.insuredPeriodInfo.description : '');
        attributes.set(6, disclosureQuestionsModel.hasPreviousClaim);
        attributes.set(7, disclosureQuestionsModel.cancellationReasonInfo ?
            disclosureQuestionsModel.cancellationReasonInfo.description : '');

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_DISCLOSURES,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE,
            attributes);
    }

}
