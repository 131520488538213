import {Component, Input, OnInit} from '@angular/core';
import {StepperService} from './stepper.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html'
})
export class StepperComponent implements OnInit {

    steps: String[] = [];

    @Input('activateIndex') activateIndex: number;

    constructor(private stepperService: StepperService, private router: Router) {
    }

    ngOnInit() {
        this.steps = this.stepperService.getAllSteps();

    }

}
