import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {VehicleModelModel} from './vehicle-model.model';
import {CifVehicleModel} from './cif/cif-vehicle.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {NumberLookupModel} from '../../../../shared/lookup/number-lookup.model';
import {VehicleService} from '../service/vehicle.service';
import {DefaultLookupModel} from '../../../../shared/lookup/default-lookup.model';

export class VehicleDescriptionModel {

    public vehicleCondition: LookupModel;
    public vehicleRegistrationNumber: string;
    public vehicleYear: LookupModel;
    public vehicleMake: LookupModel;
    public vehicleModel: VehicleModelModel;
    public vehicleColour: LookupModel;
    public vehicleVinNumber: string;
    public vehicleEngineNumber: string;
    public vehicleType: LookupModel;
    public vehiclePossession: LookupModel;

    // Variables for the retrieve only
    public makesList: DefaultLookupModel[];
    public modelsList: VehicleModelModel[];

    constructor() { }

    populateFromFormData(formData: any) {
        this.vehicleCondition = formData.vehicleCondition;
        this.vehicleRegistrationNumber = formData.vehicleRegistrationNumber;
        this.vehicleYear = formData.vehicleYear;
        this.vehicleMake = formData.vehicleMake;
        this.vehicleModel = formData.vehicleModel;
        this.vehicleColour = formData.vehicleColour;
        this.vehicleVinNumber = formData.vehicleVinNumber;
        this.vehicleEngineNumber = formData.vehicleEngineNumber;
        this.vehicleType = formData.vehicleType;
        this.vehiclePossession = formData.vehiclePossession;
    }

    async populateFromCifModel(cifModel: CifVehicleModel, lookupServiceInstance: LookupService, vehicleServiceInstance: VehicleService) {
        if (cifModel) {
            this.vehicleYear = new NumberLookupModel(cifModel.vehicle.makeModel.yearModel);

            this.vehicleType = await lookupServiceInstance.getLookUpObjFromCode(
                cifModel.vehicle.typeCode,
                LookupService.GROUP_VEHICLE_TYPE
            );

            this.vehicleCondition = await lookupServiceInstance.getLookUpObjFromCode(
                cifModel.vehicle.conditionCode,
                LookupService.GROUP_VEHICLE_CONDITION
            );

            this.vehicleRegistrationNumber = cifModel.vehicle.registrationNumber;

            this.makesList = await vehicleServiceInstance.getMakes(this.vehicleType.getCode(), this.vehicleYear.getCode());

            this.vehicleMake = vehicleServiceInstance.getMakeByCode(this.makesList, cifModel.vehicle.makeModel.makeCode);

            this.modelsList = await vehicleServiceInstance.getModels(
                this.vehicleType.getCode(),
                this.vehicleYear.getCode(),
                this.vehicleMake.getCode()
            );

            this.vehicleModel = vehicleServiceInstance.getModelByCode(this.modelsList, cifModel.vehicle.makeModel.modelCode);

            this.vehicleColour = await lookupServiceInstance.getLookUpObjFromCode(
                cifModel.vehicle.colourCode,
                LookupService.GROUP_VEHICLE_COLOUR
            );

           // this.vehicleVinNumber = cifModel.vehicleDetails.vin;
            this.vehicleEngineNumber = cifModel.vehicle.engineNumber;

            this.vehiclePossession = await lookupServiceInstance.getLookUpObjFromCode(
                cifModel.possessionCode,
                LookupService.GROUP_VEHICLE_POSSESSION
            );
        }
    }

}
