import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';
import {MSTIConfiguration} from '../msti.config';

@Injectable()
export class InactivityService {

    constructor(
        private http: HttpClient
    ) { }

    public keepSessionAlive() {
      /*  const sessionExtenderUrl = MSTIConfiguration.sessionExtenderUrl;

        if (sessionExtenderUrl) {
            Logger.log(Level.LOG, 'Extending session with url : ' + sessionExtenderUrl);
            this.http.post(sessionExtenderUrl, null);
        } else {
            Logger.log(Level.ERROR, 'Error extending portal session - no session extender url set');
        }*/
    }

}
