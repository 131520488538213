import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {QuoteModule} from './quote/quote.module';
import {PremiumModule} from './premium/premium.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InactivityModule} from './shared/inactivity/inactivity.module';
import {MarketingModule} from './marketing/marketing.module';
import {PersonalModule} from './personal/personal.module';
import {DisclosureModule} from './disclosure/disclosure.module';
import {AppManagerModule} from './mstiappmanager/app_manager.module';
import {FooterModule} from './footer/footer.module';
import {SelectCoverModule} from './covers/select-cover.module';
import {AdminFeeModule} from './admin-fee/admin-fee.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    InactivityModule,
    MarketingModule,
    QuoteModule,
    PersonalModule,
    DisclosureModule,
    PremiumModule,
    FooterModule,
    SelectCoverModule,
    AppManagerModule,
    AdminFeeModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
