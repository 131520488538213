import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ImageService} from '../../shared/services/image.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {NeedHelpService} from './services/need-help.service';
import {GetAdviceModel} from './models/get-advice.model';
import {GetClientIdentityModel} from './models/GetClientIdentityModel';
import {PersonalService} from '../../personal/service/personal.service';
import {RoutingService} from '../../shared/services/routing.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../shared/digital-analytics/model/digital-analytics-element.model';
import {MSTIParentComponent} from '../../shared/msti-parent';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {UiUtilsService} from '../../shared/utilities/ui-utils.service';
import {phoneNumberValidator} from '../../shared/validators/phone-number.validator';
import {emailValidator} from '../../shared/validators/email.validator';
import {GetAdviceDataModel} from './models/GetAdviceDataModel';

@Component({
  selector: 'app-msti-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss']
})
export class MSTINeedHelpComponent extends MSTIParentComponent implements OnInit {

  closeIcon: any;
  needHelpForm: FormGroup;
  submitted = false;
  firstName: FormControl;
  surname: FormControl;
  phoneNumber: FormControl;
  email: FormControl;
  optionalMessage: FormControl;
  callCenterNumber: string;
  askForData = false;
  weWillCallUMsg = 'We will call you';
  hasClicked = false;

  constructor(
    imageService: ImageService,
    private dialogRef: MatDialogRef<MSTINeedHelpComponent>,
    private formBuilder: FormBuilder,
    private userService: BrokerDetailsService,
    private needHelpService: NeedHelpService,
    private personalService: PersonalService,
    private routingService: RoutingService,
    private digitalAnalyticsService: DigitalAnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private uiUtilsService: UiUtilsService, private brokerDetailsService: BrokerDetailsService
  ) {
    super();
    this.initializeIcons(imageService);
    this.callCenterNumber = this.brokerDetailsService.getBrokerContext().callCenterNumber;
  }

  ngOnInit() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireElementTag(
      DigitalAnalyticsElementModel.getDoYouNeedHelpElementLabel(this.needHelpService.getCurrentPage()),
      attributes
    );

    this.googleAnalyticsService.fireGoogleAnalyticsForDoYouNeedHelpPage(this.needHelpService.getCurrentPage());

    if (this.brokerDetailsService.getUser().clientResourceRef) {
      this.askForData = false;
      this.setControls(false);
    } else {
      this.askForData = true;
      this.weWillCallUMsg = 'Please fill in your client\'s details.We will call you';
      this.setControls(true);
    }

    // this.preFill();
  }

  setControls(validationRequired: boolean) {
    this.needHelpForm = this.formBuilder.group({});
    if (validationRequired) {
      this.firstName = new FormControl('', [Validators.required]);
      this.surname = new FormControl('', [Validators.required]);
      this.phoneNumber = new FormControl('', [phoneNumberValidator]);
      this.email = new FormControl('', [emailValidator]);
      this.needHelpForm.addControl('firstName', this.firstName);
      this.needHelpForm.addControl('surname', this.surname);
      this.needHelpForm.addControl('phoneNumber', this.phoneNumber);
      this.needHelpForm.addControl('email', this.email);
    }
    this.optionalMessage = new FormControl('', []);
    this.needHelpForm.addControl('optionalMessage', this.optionalMessage);

  }

  initializeIcons(imageService: ImageService) {
    this.closeIcon = imageService.getImage('CLOSE-ICON');
  }

  /*  preFill() {
      if (this.personalInfo) {
        this.firstName.setValue(this.personalInfo.personal.firstName);
        this.surname.setValue(this.personalInfo.personal.lastName);
        this.phoneNumber.setValue(this.personalInfo.contact.mobileNumber);
        this.email.setValue(this.personalInfo.contact.email);
      } else if (this.user) {
        this.firstName.setValue(this.user.firstName);
        this.surname.setValue(this.user.lastName);
        this.phoneNumber.setValue(this.user.cellNumber);
        this.email.setValue(this.user.emailAddress);
      }
    }*/

  autoGrowText(id: string) {
    this.uiUtilsService.autoGrowTextElement(id);
  }

  get user() {
    return this.userService.getUser();
  }

  get personalInfo() {
    return this.personalService.getClientPersonalDetailModel();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.needHelpForm.invalid && !this.hasClicked) {
      this.hasClicked = true;
      /* const attributes: Map<number, string> = new Map<number, string>();
       this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_DO_YOU_NEED_HELP_SEND, attributes);*/
      const getAdviceModel: GetAdviceModel = new GetAdviceModel();
      getAdviceModel.adviceReason = this.optionalMessage.value;
      getAdviceModel.adviceStageCode = this.needHelpService.getCurrentPageStageCode();

      const clientIdentityModel: GetClientIdentityModel = new GetClientIdentityModel();
      clientIdentityModel.identityType = 'RSA_ID';
      clientIdentityModel.identityValue = this.user.idNumber;
      getAdviceModel.clientIdentity = clientIdentityModel;
      if (this.askForData) {
        const adviceDataModel = new GetAdviceDataModel();
        adviceDataModel.contactNumber = this.phoneNumber.value;
        adviceDataModel.emailAddress = this.email.value;
        adviceDataModel.firstName = this.firstName.value;
        adviceDataModel.lastName = this.surname.value;
        getAdviceModel.adviceData = adviceDataModel;
      } else {
        getAdviceModel.adviceData = null;
      }
      this.dialogRef.close();
      // this.hasClicked = false;
      this.routingService.route('app-need-help-thankyou');
      this.needHelpService.getAdvice(getAdviceModel).subscribe((response) => {
      });
    }
  }

}
