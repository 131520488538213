import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/index';
import {MSTIConfiguration} from '../msti.config';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';

@Injectable()
export class LoaderService {

  loaderChanged = new Subject<any>();
  loaderWithMessageChanged = new Subject<any>();
  loadScreenCallTimes: number;
  loadScreenWithMsgCallTimes: number;
  dialogInfo: any = {};

  constructor() {
    this.loadScreenCallTimes = 0;
    this.loadScreenWithMsgCallTimes = 0;
  }

  public show() {
    this.loadScreenCallTimes++;
    if (this.loadScreenCallTimes > 0) {
      this.loaderChanged.next(true);
    }

   // Logger.log(Level.LOG, 'Loader show called', this.loadScreenCallTimes);
  }

  public hide() {
    if (this.loadScreenCallTimes > 0) {
      this.loadScreenCallTimes--;

      if (this.loadScreenCallTimes === 0) {
        setTimeout(() => {
          this.doLoaderHide();
        }, MSTIConfiguration.constants.loaderTimeout);
      }
    }

   // Logger.log(Level.LOG, 'Loader hide called', this.loadScreenCallTimes);
  }

  public showCustomLoader(dialogInfo: any) {
    this.dialogInfo = dialogInfo;
    this.dialogInfo.show = true;
    this.loadScreenWithMsgCallTimes++;
    if (this.loadScreenWithMsgCallTimes > 0) {
      this.loaderWithMessageChanged.next(this.dialogInfo);
    }
  }

  public hideCustomLoader() {
    if (this.loadScreenWithMsgCallTimes > 0) {
      this.loadScreenWithMsgCallTimes--;
      if (this.loadScreenWithMsgCallTimes === 0) {
        setTimeout(() => {
          this.doCustomLoaderHide();
        }, MSTIConfiguration.constants.customLoaderTimeout);
      }
    }
  }

  public hideAllLoaders() {
    if (this.loadScreenCallTimes > 0) {
      this.loadScreenCallTimes = 0;
      this.doLoaderHide();
    }

    if (this.loadScreenWithMsgCallTimes > 0) {
      this.loadScreenWithMsgCallTimes = 0;
      this.doCustomLoaderHide();
    }
  }

  private doLoaderHide() {
    this.loaderChanged.next(false);
  }

  private doCustomLoaderHide() {
    this.dialogInfo = {};
    this.dialogInfo.show = false;
    this.loaderWithMessageChanged.next(this.dialogInfo);
  }

  init() {
    this.loaderChanged.next(false);
  }
}
