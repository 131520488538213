import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MstiErrorComponent} from './msti-error-components/msti-error.component';


const appRoutes: Routes = [
    {path: 'app-msti-error', component: MstiErrorComponent}
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class ErrorHandlerRoutingModule {
}
