import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PremiumConfig} from './premium.config';
import {PremiumPersonalInfoModel} from '../model/premium-personal-info.model';
import {PremiumService} from '../service/premium.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsElementModel} from '../../shared/digital-analytics/model/digital-analytics-element.model';
import {UrlService} from '../../shared/services/url.service';
import {ImageService} from '../../shared/services/image.service';
import {LogoutService} from '../../shared/services/logout.service';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {QuoteService} from '../../quote/service/quote.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {Logger} from '../../shared/utilities/logger';
import {Level} from '../../shared/utilities/logger-level';
import {RoutingService} from '../../shared/services/routing.service';
import {SmoothScroll} from '../../shared/utilities/smooth-scroll';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {BrokerCommissionModel} from '../../quote/model/cif/brokerCommission.model';
import {CifQuoteSummaryResponseModel} from '../../quote/model/cif/cif-quote-summary-response.model';
import {MSTIService} from '../../shared/services/msti.service';
import {QuoteUrlService} from '../../quote/service/quote-url.service';
import {BrokerCommissionDisplayModel, PremiumDisplayModel, TotalPremiumDisplayModel} from './models/premium-display.model';
import {MatAccordion, MatDialog} from '@angular/material';
import {MSTITooltipComponent} from '../../shared/pop-up-dialogs/msti-tooltip/msti-tooltip.component';
import {timer} from 'rxjs';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit {

  premiumPageHeading = PremiumConfig.HEADING;
  premiumPageSubHeading = PremiumConfig.SUB_HEADING;

  premiumPersonalInfoModel: PremiumPersonalInfoModel;

  isQuoteSubmitted = false;
  isTermsAcceptanceChecked = false;

  termsPdfUrl: string;
  quoteId: string;
  quoteDisplayNumber: string;
  hasVehiclePremium = false;
  brokerCommission: BrokerCommissionModel;
  showCustomLoader = false;
  hasClickedBefore = false;
  hints = [];
  ///

  @ViewChild('accordion', {static: true}) accordion: MatAccordion;
  premiumDisplayModel = new PremiumDisplayModel();

  infoIcon: string;
  fullName: string;
  rsaId: string;
  emailAddress: string;
  cellNumber: string;
  count: number;

  ///

  constructor(private premiumService: PremiumService, private elementRef: ElementRef, private digitalAnalyticsService: DigitalAnalyticsService,
              private urlService: UrlService, private imageService: ImageService, private logoutService: LogoutService,
              private userService: BrokerDetailsService, private loaderService: LoaderService, private mstiErrorService: MstiErrorService,
              private quoteService: QuoteService, private needHelpService: NeedHelpService, private routingService: RoutingService,
              private googleAnalyticsService: GoogleAnalyticsService, private mstiService: MSTIService, private quoteUrlService: QuoteUrlService,
              public dialog: MatDialog) {
    this.infoIcon = imageService.getImage('INFO-ICON');
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.loaderService.show();
    this.SetGA();
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM);
    this.termsPdfUrl = this.urlService.getUnprefixedUrl(UrlService.KEY_PREMIUM_TERMS_PDF);
    this.premiumPersonalInfoModel = this.premiumService.getPersonalInfo();
    this.loaderService.hide();
    this.showCustomLoader = true;
    this.quoteId = this.quoteService.quoteId;
    this.quoteDisplayNumber = this.quoteService.quoteDisplayNumber;
    this.count = 0;
    this.fetchPremiumSummaryDetails();
    this.hints.push('momentum assist', 'sasria', 'excess');
  }

  private SetGA() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_PREMIUM_PAGE_ID, attributes);
  }


  private fetchPremiumSummaryDetails() {
    if (this.quoteId) {
      this.mstiService.getWithAbAuth(this.quoteUrlService.getGetPremiumSummaryURL(this.quoteId)).subscribe(response => {
        // Null = premium summary service initialized,
        // New = premium summary service waiting in queue,
        // Processing = premium summary service processing
        if (response === null || response === 'null' || response === 'NEW' || response === 'PROCESSING') {
          timer(1000).toPromise().then(res => {
            if (this.count < 49) {
              this.count++;
              this.fetchPremiumSummaryDetails();
            } else {
              this.loaderService.hideCustomLoader();
              this.showCustomLoader = false;
              this.mstiErrorService.handleError('');
            }

          });
        } else {
          const existingQuote = Object.assign(new CifQuoteSummaryResponseModel(), response);

          this.hasVehiclePremium = existingQuote.premiumSectionList.some(riskPremium => riskPremium.sectionCode === PremiumConfig.VEHICLE_COVER_TYPE_CODE);

          Logger.log(Level.LOG, 'Quote summary retrieve response:', existingQuote);
          if (existingQuote.premiumSectionList && existingQuote.premiumSectionList.length > 0) {
            this.displayPremiumSummary(existingQuote);
            this.accordion.openAll();
          } else {
            this.mstiErrorService.handleError('');
          }

          this.showCustomLoader = false;
        }
      }, (error) => {
        Logger.log(Level.ERROR, `Error retrieving existing premium summary for quoteId: ${this.quoteId}`, error);
        this.loaderService.hideCustomLoader();
        this.showCustomLoader = false;
        this.mstiErrorService.handleError(error);
      });
    }

  }

  displayPremiumSummary(premiumCalcDisplayData: any) {
    this.displayPersonalInfo();
    this.premiumDisplayModel.brokerCommission = Object.assign(new BrokerCommissionDisplayModel(), premiumCalcDisplayData.brokerCommission);
    this.premiumDisplayModel.totalPremium = Object.assign(new TotalPremiumDisplayModel(), premiumCalcDisplayData.totalPremium);
    if (premiumCalcDisplayData.premiumSectionList) {
      this.replaceCommasWithSpace(premiumCalcDisplayData);
    }
    this.premiumDisplayModel.sections = premiumCalcDisplayData.premiumSectionList;
  }

  // This code replaces the commas in the address/risk description with spaces
  private replaceCommasWithSpace(premiumCalcDisplayData: any) {
    for (const sections of premiumCalcDisplayData.premiumSectionList) {
      for (const risk of sections.premiumRiskList) {
        risk.riskDescription = risk.riskDescription.split(',').join(' ');
      }
    }
  }

  private displayPersonalInfo() {
    this.fullName = this.premiumPersonalInfoModel.fullName;
    this.rsaId = this.premiumPersonalInfoModel.identityNumber;
    this.emailAddress = this.premiumPersonalInfoModel.emailAddress;
    this.cellNumber = this.premiumPersonalInfoModel.cellNumber;
  }


  termsAndConditionsClicked() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireElementTag(
      DigitalAnalyticsElementModel.ELEMENT_PREMIUM_TERMS_AND_CONDITIONS,
      attributes
    );
    this.googleAnalyticsService.fireGoogleAnalytics(
      GoogleAnalyticsActionModel.ACTION_YES,
      GoogleAnalyticsEventModel.EVENT_VIEW_TERMS_AND_CONDITIONS
    );
  }

  onBack() {
    this.routingService.route('app-admin-fee');
  }

  onSubmit() {
    if (!this.hasClickedBefore) {
      this.hasClickedBefore = true;
      this.googleAnalyticsService.fireGoogleAnalytics(GoogleAnalyticsActionModel.ACTION_SUBMITTED, GoogleAnalyticsEventModel.EVENT_SUBMIT_QUOTE
      );
      Logger.log(Level.LOG, 'Abx', this.userService.getUser());

      this.submitCallBackForAcceptance();
      this.isQuoteSubmitted = true;
      this.saveTndCs();
      this.finishQuote();
      this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION);
      SmoothScroll.smoothScrollToTop();

    }

  }

  private saveTndCs() {
    this.premiumService.saveTermsAndConditionsAgreement(this.userService.getUser()).subscribe((response: any) => {
      this.loaderService.show();
      Logger.log(Level.LOG, 'Save Terms and Conditions', response);
      if (response) { // No response will come if there is an error
        this.loaderService.hide();
      }
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }


  private submitCallBackForAcceptance() {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES);
    const getAdviceModel = this.needHelpService.getAdviceDataForQuoteAccept('I LIKE PREMIUM', null);
    this.needHelpService.getAdvice(getAdviceModel).subscribe(response => {
      // Fire and forget
      this.hasClickedBefore = false;
    });
  }

  private finishQuote() {
    const activeTab = this.elementRef.nativeElement.querySelectorAll('li.active');

    if (activeTab.length > 0) {
      activeTab[0].classList.remove('active');
    }
  }

  openToolTipDialog(description) {
    let hint: string;
    if (description.toLowerCase() === this.hints[0]) { // momentum assist
      hint = 'Help for emergencies on the road, at your home, office or even on the medical or legal front.';
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_MOMENTUM_ASSIST
      );
    } else if (description.toLowerCase() === this.hints[1]) { // sasria
      hint = 'A state-owned short-term insurer that provides cover to all the people and businesses that have assets ' +
        'in South Africa, against special risks such as civil commotion, public disorder, strikes, riots and terrorism.';
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_SASRIA
      );
    } else if (description.toLowerCase() === this.hints[2]) { // excess
      hint = 'A contribution you are required to pay towards a claim you make on your vehicle insurance cover.';
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_VIEW_INFORMATION_REGARDING_EXCESS
      );
    }

    return this.dialog.open(MSTITooltipComponent, {
      width: '52%',
      data: {message: hint, title: ''},
      panelClass: 'msti-dialog'
    });
  }

  onDownloadQuoteDocClick() {
    this.loaderService.show();
    const quoteId = this.quoteService.quoteId;
    const url = this.quoteUrlService.getQuoteDocURL('client', quoteId);
    this.mstiService.getPdf(url).subscribe(resp => {
      const link = document.createElement('a') as HTMLAnchorElement;
      link.href = URL.createObjectURL(resp);
      link.download = 'Quotation_schedule_' + this.quoteService.quoteDisplayNumber + '.pdf';
      link.click();
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.dialog.open(MSTITooltipComponent, {
        width: '52%',
        data: {message: 'The quote document is not currently available for download, due to technical difficulties. Please try again later.', title: 'Sorry..'},
        panelClass: 'msti-dialog'
      });
    });
  }


  toCurrency(amount: string) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ZAR',
    });
    const zarvalue = formatter.format(+amount);
    return zarvalue.replace('ZAR', 'R');
  }
}
