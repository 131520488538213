export class PifAddressDetailsModel {

  public unitNo: string;
  public sectSchemeName: string;
  public estateCode: string;
  public estateName: string;
  public streetNo: string;
  public streetCode: string;
  public streetDesc: string;
  public streetTypeCode: string;
  public streetTypeDesc: string;
  public suburbCode: string;
  public suburbDesc: string;
  public municipalityCode: string;
  public municipalityName: string;
  public provinceCode: string;
  public provinceDesc: string;
  public postalCode: string;
  public poBoxNo: string;

}
