export class CifVehicleDetailsModel {


  makeDesc: string;
  modelDesc: string;
  registrationYear: number;
  registrationNumber: string;
  engineNumber: string;
  colourCode: string; // Mandatory
  conditionCode: string; // Mandatory
  typeCode: string; // Mandatory
  makeModel: PIFMakeModel = new PIFMakeModel();
}

export class PIFMakeModel {
  makeCode: string; // Mandatory
  yearModel: number;
  modelCode: string; // Mandatory
  mmCode: string; // Mandatory
}
