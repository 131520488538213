import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, retry} from 'rxjs/operators';
import {BrokerDetailsService} from '../broker-common/broker-details.service';
import {MstiErrorModel} from '../msti-error-handler/models/msti-error.model';
import {AbcryptResponseModel} from '../abcrypt/abcrypt-response.model';
import {AbCryptService} from './abcrypt.service';
import {AbCryptRequestModel} from '../abcrypt/abcrypt-request.model';

@Injectable()
export class MSTIService {

  public static readonly MAX_RETRIES = 3;

  constructor(
    private http: HttpClient,
    private userService: BrokerDetailsService,
    private abCryptService: AbCryptService
  ) {
  }

  getBrokerContext() {
    return this.abCryptService.encrpytObject(this.userService.getBrokerContext());
  }

  public post(endpoint: string, model: any) {
    return this.http.post(endpoint, model).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public postWithAuth(endpoint: string, model: any) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-broker-context': this.getBrokerContext()
    });
    return this.http.post(endpoint, model, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          if (response && response.responseStatus) {
            throw new MstiErrorModel(response.responseStatus);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public get(endpoint: string) { // This is for lookups

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      // TODO - Show the loader overlay when retrying...
      retry(MSTIService.MAX_RETRIES)
    );
  }

  public getWebClient(endpoint: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set(
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Headers, Origin,Accept,' +
      ' X-Requested-With, Content-Type, Access-Control-Request-Method,' +
      ' Access-Control-Request-Headers, Access-Control-Allow-Credentials');
    httpHeaders.set('Access-Control-Allow-Credentials', 'true');

    return this.http.get(endpoint, {headers: httpHeaders, withCredentials: true});
  }

  /*public getText(endpoint: string) { // This one is primarily used to get the party key
    const httpHeaders = new HttpHeaders({'x-access-token': this.userService.getUser().cifToken});
    return this.http.get(endpoint, {headers: httpHeaders, responseType: 'text'});
  }*/

  public getWithAuth(endpoint: string) {
    const httpHeaders = new HttpHeaders({
      'x-access-token': this.userService.getUser().cifToken,
      // 'x-broker-context': this.getBrokerContext(),
      'Content-Type': 'application/json'
    });
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          if (response && response.responseStatus) {
            throw new MstiErrorModel(response.responseStatus);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public putWithAuth(endpoint: string, model: any) {
    const httpHeaders = new HttpHeaders({
      'x-access-token': this.userService.getUser().cifToken,
      'x-broker-context': this.getBrokerContext()
    });
    return this.http.put(endpoint, model, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          if (response && response.responseStatus) {
            throw new MstiErrorModel(response.responseStatus);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public deleteWithAuth(endpoint: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.userService.getUser().cifToken,
      'x-broker-context': this.getBrokerContext()
    });
    return this.http.delete(endpoint, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }


  public getBrokerToken(endpoint: string) {

    const httpHeaders = new HttpHeaders({
      'userName': 'MstiOnlineQuoteSTS'
    });
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      // TODO - Show the loader overlay when retrying...
      retry(MSTIService.MAX_RETRIES)
    );
  }

  public getConfig(endpoint: string) {
    const httpHeaders = new HttpHeaders({
      'mstiContext': '{"sourceChannelName": "BRQUOTEWEB","channelUserKey": "BRQUOTEWEB"}'
    });
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      // TODO - Show the loader overlay when retrying...
      retry(MSTIService.MAX_RETRIES)
    );
  }


  public getWithAbAuth(endpoint: string) {
    const httpHeaders = new HttpHeaders({
        'x-access-token': this.userService.getUser().cifToken,
        'Content-Type': 'application/json',
        'x-broker-context': this.getBrokerContext()
      })
    ;
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          const abResponse = Object.assign(new AbcryptResponseModel(), response);
          if (abResponse.data) {
            const data = this.abCryptService.decrpyt(abResponse.data);
            if (data === null || data === 'null' || data === 'NEW' || data === 'PROCESSING') {
              response = data;
            } else {
              response = JSON.parse(data);
            }

          }

          if (response && response.error) {
            throw new MstiErrorModel(response.error.message);
          } else if (response && response.errors) {
            throw new MstiErrorModel(response);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }


  public postWithAbAuth(endpoint: string, model: any) {
    const httpHeaders = new HttpHeaders({
      'x-access-token': this.userService.getUser().cifToken,
      'Content-Type': 'application/json',
      'x-broker-context': this.getBrokerContext()
    });
    model = new AbCryptRequestModel(this.abCryptService.encrpytObject(model));
    return this.http.post(endpoint, model, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          const abResponse = Object.assign(new AbcryptResponseModel(), response);
          if (abResponse.data) {
            const data = this.abCryptService.decrpyt(abResponse.data);
            response = JSON.parse(data);
          }

          if (response && response.error) {
            throw new MstiErrorModel(response.error.message);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public putWithAbAuth(endpoint: string, model: any) {
    const httpHeaders = new HttpHeaders({
      'x-access-token': this.userService.getUser().cifToken,
      'Content-Type': 'application/json',
      'x-broker-context': this.getBrokerContext()
    });
    model = new AbCryptRequestModel(this.abCryptService.encrpytObject(model));
    return this.http.put(endpoint, model, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          const abResponse = Object.assign(new AbcryptResponseModel(), response);
          if (abResponse.data) {
            const data = this.abCryptService.decrpyt(abResponse.data);
            response = JSON.parse(data);
          }

          if (response && response.error) {
            throw new MstiErrorModel(response.error.message);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public deleteWithAbAuth(endpoint: string) {
    const httpHeaders = new HttpHeaders({
      'x-access-token': this.userService.getUser().cifToken,
      'Content-Type': 'application/json',
      'x-broker-context': this.getBrokerContext()
    });
    return this.http.delete(endpoint, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          const abResponse = Object.assign(new AbcryptResponseModel(), response);
          if (abResponse.data) {
            const data = this.abCryptService.decrpyt(abResponse.data);
            response = JSON.parse(data);
          }

          if (response && response.error) {
            throw new MstiErrorModel(response.error.message);
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }


  public getPdf(endpoint: string) {
    const httpHeaders = new HttpHeaders({
        'x-access-token': this.userService.getUser().cifToken,
        'Content-Type': 'application/pdf',
        'x-broker-context': this.getBrokerContext()
      })
    ;
    return this.http.get(endpoint, {headers: httpHeaders, responseType: 'blob'}).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }

  public getWithAbAuthz(endpoint: string) {
    const httpHeaders = new HttpHeaders({
        'x-access-token': this.userService.getUser().cifToken,
        'Content-Type': 'application/json',
        'x-broker-context': this.getBrokerContext()
      })
    ;
    return this.http.get(endpoint, {headers: httpHeaders}).pipe(
      map(
        (response: any) => {
          if (response && response.error) {
            throw new MstiErrorModel(response.error.message);
          } else {
            const abResponse = Object.assign(new AbcryptResponseModel(), response);
            const data = this.abCryptService.decrpyt(abResponse.data);
            return data;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
    );
  }
}
