import {Injectable} from '@angular/core';
import {MstiErrorModel} from './models/msti-error.model';
import {NavigationExtras} from '@angular/router';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';
import {RoutingService} from '../services/routing.service';
import {LoaderService} from '../screen-loader/loader.service';

@Injectable()
export class MstiErrorService {

  constructor(
    private routingService: RoutingService,
    private loaderService: LoaderService
  ) {
  }

  public handleError(error) {
    this.loaderService.hideAllLoaders();

    if (error && (error.action || error.errors[0].errorCode)) {
    // if (error && error.action) {
      this.handleMstiError(error);
    } else {
      this.handleHttpError();
    }
  }

  private handleHttpError() {
    this.routeAsTechnicalError();
  }

  private routeAsTechnicalError() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        'actionDescription': 'There seems to be a system error. Please try again later.',
        'actionCode': '442',
        'errorMessage': 'We\'re sorry for the hassle',
        'subMessage': 'If this error persists, please contact the ETS helpdesk on 012 675 3099 for assistance.',
        'errorCode': 'Technical'
  }};
    this.routingService.routeWithExtras('/app-msti-error', navigationExtras);
  }

  private handleMstiError(error: MstiErrorModel) {
    Logger.log(Level.ERROR, error);
    try {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'actionDescription': error.action.actionDescription,
          'actionCode': error.action.actionTypeCode,
          'errorMessage': error.errors[0].message,
          'errorCode': error.errors[0].errorCode
        }
      };
      this.routingService.routeWithExtras('/app-msti-error', navigationExtras);
    } catch (e) {
      this.routeAsTechnicalError();
    }
  }

}
