import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MapsAPILoader, MouseEvent} from '@agm/core';
import {LightStoneAddressModel} from './model/lightstone.address.model';
import {AddressTypeModel} from './model/address-type.model';
import {LoaderService} from '..//screen-loader/loader.service';
import {MstiAgmService} from './msti-agm.service';
import {Level} from '../utilities/logger-level';
import {MatDialogRef} from '@angular/material';
import {MstiAgmPopupDialogComponent} from './msti-agm-popup-dialog/msti-agm-popup-dialog.component';
import {MstiErrorService} from '../msti-error-handler/msti-error.service';
import {Logger} from '../utilities/logger';
import {AutoUnsubscribe} from '../auto-unsubscribe';
import {CollectiveAddressTypeModel} from './model/collective-address-type.model';

@Component({
  selector: 'app-msti-agm',
  templateUrl: './msti-agm.component.html',
  styleUrls: ['./msti-agm.component.scss']
})
export class MstiAgmComponent extends AutoUnsubscribe implements OnInit {

  @Input() addressTypeModel: AddressTypeModel;
  @Input() collectiveAddressTypeModel: CollectiveAddressTypeModel;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  public formattedAddress = '';
  public zoom = 12;
  public latitude: number;
  public longitude: number;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private loaderService: LoaderService,
    private mstiAgmService: MstiAgmService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<MstiAgmPopupDialogComponent>,
    private mstiErrorService: MstiErrorService
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.show();
    this.mapsAPILoader.load().then(() => {
      this.mstiAgmService.initMapsComponents(this.searchElementRef.nativeElement);

      this.mstiAgmService.autoComplete.addListener('place_changed', () => {
        this.mstiAgmService.place = this.mstiAgmService.autoComplete.getPlace();
        if (this.mstiAgmService.place.geometry) {
          this.latitude = this.mstiAgmService.place.geometry.location.lat();
          this.longitude = this.mstiAgmService.place.geometry.location.lng();
          this.changeDetectorRef.detectChanges();
        }
      });

      this.loaderService.show();
      this.subscriptions.push(this.mstiAgmService.getCurrentPosition().subscribe(
        result => {
          if (result) {
            this.longitude = result.longitude;
            this.latitude = result.latitude;
            this.changeDetectorRef.detectChanges();
            this.getGoogleAddress();
          }
        },
        error => {
          Logger.log(Level.ERROR, 'Error getting current position from the maps service', error);
        },
        () => {
          Logger.log(Level.LOG, 'Completed getting current position from the maps service');
        }
      ));

      this.loaderService.hide();
    });
  }

  confirmAddress() {
    this.loaderService.show();
    this.subscriptions.push(this.mstiAgmService.getLightStoneAddress(this.latitude, this.longitude)
      .subscribe((collectiveLightStoneAddressFromService: LightStoneAddressModel[]) => {

        if (collectiveLightStoneAddressFromService) {

          this.collectiveAddressTypeModel.lightstoneAddressError = false;

          if (collectiveLightStoneAddressFromService.length === 1) {
            this.collectiveAddressTypeModel.multipleAddressExists = false;
          } else if (collectiveLightStoneAddressFromService.length > 0) {
            this.collectiveAddressTypeModel.multipleAddressExists = true;
          } else {
            this.collectiveAddressTypeModel.lightstoneAddressError = true;
          }

          const addressTypeModels: AddressTypeModel[] = [];
          collectiveLightStoneAddressFromService.forEach(ele => {
            addressTypeModels.push(this.collectiveAddressTypeFromLightStone(ele));

          });


          this.collectiveAddressTypeModel.addressTypeModels = addressTypeModels;
        } else {
          this.collectiveAddressTypeModel.lightstoneAddressError = true;
        }

        this.loaderService.hide();

        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }, (error) => {
        Logger.log(Level.ERROR, 'Cannot find your address', error);
        this.mstiErrorService.handleError(error);
        this.loaderService.hide();

        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }));
  }

  markerDragEnd($event: MouseEvent) {
    this.loaderService.show();
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getGoogleAddress();
  }

  private getGoogleAddress() {
    this.subscriptions.push(this.mstiAgmService.getGoogleAddress(this.latitude, this.longitude).subscribe(
      result => {
        if (result && result.formattedAddress) {
          this.formattedAddress = result.formattedAddress;
        }
      }, (error) => {
        Logger.log(Level.ERROR, 'Get google address error', error);
        this.loaderService.hide();
      }, () => {
        Logger.log(Level.LOG, 'Get google address completed');
        this.changeDetectorRef.detectChanges();
        this.loaderService.hide();
      }
    ));
  }

  private populateFromLightStone(lightStoneAddressModel: LightStoneAddressModel) {
    this.addressTypeModel.unitNumber = this.validateField(lightStoneAddressModel.unitNumber);
    this.addressTypeModel.sectionSchemeName = this.validateField(lightStoneAddressModel.schemeDescription);
    this.addressTypeModel.estateCode = this.validateField(lightStoneAddressModel.estateId);
    this.addressTypeModel.estateName = this.validateField(lightStoneAddressModel.estateDescription);
    this.addressTypeModel.streetNo = this.validateField(lightStoneAddressModel.streetNumber);
    this.addressTypeModel.streetCode = this.validateField(lightStoneAddressModel.streetId);
    this.addressTypeModel.streetDesc = this.validateField(lightStoneAddressModel.streetDescription);
    this.addressTypeModel.streetTypeDesc = this.validateField(lightStoneAddressModel.streetType);
    this.addressTypeModel.suburbCode = this.validateField(lightStoneAddressModel.suburbId);
    this.addressTypeModel.suburbDesc = this.validateField(lightStoneAddressModel.suburbDescription);
    this.addressTypeModel.municipalityCode = this.validateField(lightStoneAddressModel.municipalityId);
    this.addressTypeModel.municipalityName = this.validateField(lightStoneAddressModel.municipalityDescription);
    this.addressTypeModel.provinceCode = this.validateField(lightStoneAddressModel.provinceId);
    this.addressTypeModel.provinceDesc = this.validateField(lightStoneAddressModel.provinceDescription);
    this.addressTypeModel.postalCode = this.validateField(lightStoneAddressModel.postalCode);

    Logger.log(Level.LOG, 'Light Stone Address Model:', lightStoneAddressModel);
  }

  private collectiveAddressTypeFromLightStone(lightStoneAddressModel: LightStoneAddressModel): AddressTypeModel {
    const addressTypeModel = new AddressTypeModel(this.addressTypeModel.addressType);
    addressTypeModel.unitNumber = this.validateField(lightStoneAddressModel.unitNumber);
    addressTypeModel.sectionSchemeName = this.validateField(lightStoneAddressModel.schemeDescription);
    addressTypeModel.estateCode = this.validateField(lightStoneAddressModel.estateId);
    addressTypeModel.estateName = this.validateField(lightStoneAddressModel.estateDescription);
    addressTypeModel.streetNo = this.validateField(lightStoneAddressModel.streetNumber);
    addressTypeModel.streetCode = this.validateField(lightStoneAddressModel.streetId);
    addressTypeModel.streetDesc = this.validateField(lightStoneAddressModel.streetDescription);
    addressTypeModel.streetTypeDesc = this.validateField(lightStoneAddressModel.streetType);
    addressTypeModel.suburbCode = this.validateField(lightStoneAddressModel.suburbId);
    addressTypeModel.suburbDesc = this.validateField(lightStoneAddressModel.suburbDescription);
    addressTypeModel.municipalityCode = this.validateField(lightStoneAddressModel.municipalityId);
    addressTypeModel.municipalityName = this.validateField(lightStoneAddressModel.municipalityDescription);
    addressTypeModel.provinceCode = this.validateField(lightStoneAddressModel.provinceId);
    addressTypeModel.provinceDesc = this.validateField(lightStoneAddressModel.provinceDescription);
    addressTypeModel.postalCode = this.validateField(lightStoneAddressModel.postalCode);
    addressTypeModel.formatAddressLine();
    return addressTypeModel;
  }

  private validateField(lightStoneField: any) {
    if (!lightStoneField) {
      return '';
    }
    return lightStoneField;
  }

}
