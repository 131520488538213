import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {TextboxNumericDynamicFormControlComponent} from './textbox-numeric-dynamic-form-control.component';

export class TextboxNumericDynamicControlModel extends BaseDynamicControlModel<string> {

    constructor(controlModel?: Partial<TextboxNumericDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return TextboxNumericDynamicFormControlComponent;
    }

}
