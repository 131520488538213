import {AbstractControl} from '@angular/forms';

export function driverAgeValidator(driverDob: string) {
  return (control: AbstractControl) => {
    const licenceIssuedDate = control.value;

    if (driverDob && licenceIssuedDate) {
      const eventStartTime = new Date(driverDob);
      const eventEndTime = new Date(licenceIssuedDate);
      const diff = eventEndTime.getFullYear() - eventStartTime.getFullYear();
      const yr = new Date(diff).getFullYear();
      if (licenceIssuedDate) {
        if (diff > 17) {
          return null;
        } else {
          return {
            validateObj: {
              valid: false,
              msg: 'Driver under 18'
            }
          };
        }

      }
    }


  };

}







