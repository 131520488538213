import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {MSTIConfiguration} from '../msti.config';

@Injectable()
export class AbCryptService { // AES broker crypt service

  salt: string;
  key: any;
  iv: any;
  encodingType: any;

  public init() {
    this.salt = MSTIConfiguration.constants.abSalt;
    this.key = CryptoJS.enc.Utf8.parse(this.salt);
    this.iv = CryptoJS.enc.Utf8.parse(this.salt);
    this.encodingType = CryptoJS.enc.Utf8;
  }

  private getCryptOptions() {
    const options = {
      keySize: 16,
      iv: this.iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    };

    return options;
  }

  encrpyt(message: string): string {

    const encrypted = CryptoJS.AES.encrypt(message, this.key, this.getCryptOptions());

    return encrypted.toString();
  }

  decrpyt(message: string): string {
    const decrypted = CryptoJS.AES.decrypt(message, this.key, this.getCryptOptions());

    return decrypted.toString(this.encodingType);
  }

  decrpytObject(message: any): string {
    const decrypted = CryptoJS.AES.decrypt(JSON.stringify(message), this.key, this.getCryptOptions());

    return decrypted.toString(this.encodingType);
  }

  encrpytObject(message: any): string {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(message), this.key, this.getCryptOptions());
    return encrypted.toString();
  }


}
