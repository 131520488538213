import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MSTIConfiguration} from './shared/msti.config';
import {LocationStrategy} from '@angular/common';
import {Level} from './shared/utilities/logger-level';
import {Logger} from './shared/utilities/logger';
import {RoutingService} from './shared/services/routing.service';
import {NeedHelpPageTags} from './footer/need-help/need-help-page-tags';
import {LoaderService} from './shared/screen-loader/loader.service';
import {GoogleAnalyticsService} from './shared/google-analytics/google-analytics.service';
import {AppInitializerService} from './mstiappmanager/app-initialiser-service.service';
import {AbCryptService} from './shared/services/abcrypt.service';
import {AppConfigModel} from './mstiappmanager/models/app-config-model.model';
import {AppMangerService} from './mstiappmanager/service/app_manager.service';
import {environment} from '../environments/environment';
import {PremiumService} from './premium/service/premium.service';

declare var _gaq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: []
})
export class AppComponent implements OnInit, OnDestroy {

  readonly env: string;
  animateBackward;
  animateForward;
  appConfig: AppConfigModel;
  private version: string = environment.VERSION;

  constructor(elm: ElementRef, private router: Router, private locationStrategy: LocationStrategy, private routingService: RoutingService,
              private loaderService: LoaderService, private googleAnalyticsService: GoogleAnalyticsService,
              private appInitializerService: AppInitializerService, private abCryptService: AbCryptService,
              private appMangerService: AppMangerService, private premiumService: PremiumService) {

    this.loaderService.show();
    // initially set the view port settings for the app
    //  this.getScreenViewport().setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=3, user-scalable=0');
    this.env = environment.env;
    MSTIConfiguration.constants.init(this.env);
    this.LogEnvironmentDetails();
    const urlString = location.href; // Get the url here
    this.abCryptService.init();
    NeedHelpPageTags.initialise();
    this.appMangerService.getConfig().subscribe((response) => {
      this.appMangerService.setValidationsAndConfigData(response);
      this.appConfig = this.appMangerService.appValidations.appconfig;
      if (this.appConfig) { // If we get a config back then only allow to proceed
        this.loaderService.hide();
        if (this.appConfig.enablePostClients) {// Check if in maintanence
          this.checkIfAllowed(urlString);

          this.appInitializerService.initialiseApp(this.appConfig);
        } else {
          this.showDownTimeScreen();
        }

      } else {
        this.loaderService.hide();
        this.showDownTimeScreen();
      }

    }, (error) => {
      // TODO nav maintenance screen
      this.loaderService.hide();
      this.showDownTimeScreen();
    });


  }

  private LogEnvironmentDetails() {
    if (this.env !== 'local' && this.env !== 'pre') {
      console.log('IronMan_', this.version);
    } else {
      console.log('Batman__', this.version);
      Logger.log(Level.LOG, 'Environment', this.env);
    }

  }

  ngOnInit(): void {

  }

  /* onComponentActivate() {
     // check if device is safari and is mobile
     if (this.isMobileSafari()) {
       this.preventZoomOnSafari();
     }
   }*/

  /* private isMobileSafari(): boolean {
     const userAgent = navigator.userAgent;
     if (userAgent.match(/(iPhone|iPad)/) && userAgent.match(/Safari/) && userAgent.match(/AppleWebKit/)) {
       return true;
     } else {
       return false;
     }
   }*/

  /*  private preventZoomOnSafari() {
      const el = document.querySelectorAll('input, select, mat-select, textArea');
      for (let i = 0; i < el.length; i++) {
        ['click', 'blur'].forEach(evt =>
          el[i].addEventListener(evt, () => {
            console.log('event type ', event.type);
            this.changeViewport(event);
          }, false)
        );
      }

    }*/

  private getScreenViewport() {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === 'viewport') {
        return metas[i];
      }
    }
  }

  /*  private changeViewport(event) {
      if (event.type === 'blur') {
        const allowZoom = 'maximum-scale=3';
        this.getScreenViewport().setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=0, ' + allowZoom);
        Logger.log(Level.LOG, 'viewport allow ', this.getScreenViewport());
      } else {
        const disableZoom = 'maximum-scale=1';
        this.getScreenViewport().setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=0, ' + disableZoom);
        Logger.log(Level.LOG, 'viewport disable', this.getScreenViewport());
      }
      return;
    }*/

  private initializeGoogleAnalytics(userKey) {
    this.googleAnalyticsService.setUserKey(userKey);
    if (this.env === 'prod') {
      _gaq.push(['_setAccount', 'UA-9598333-1']);
      _gaq.push(['_setDomainName', 'momentum.co.za']);
      _gaq.push(['_setCustomVar', 1, 'UserID', userKey, 1]);
    } else {
      _gaq.push(['_setAccount', 'UA-127658307-1']);
      _gaq.push(['_setDomainName', 'momentum.co.za']);
      _gaq.push(['_setCustomVar', 1, 'UserID', userKey, 1]);
    }

    _gaq.push(['_trackPageview']);

    Logger.log(Level.LOG, 'GA setup', _gaq);
    Logger.log(Level.LOG, 'GA setup: userKey', userKey);
  }

  ngOnDestroy() {
    // this.userKeySubscription.unsubscribe();
  }

  private showDownTimeScreen() {
    this.routingService.route('/app-manager-downtime');
  }


  private checkIfAllowed(url: string): AppConfigModel {
    const factorR = this.abCryptService.decrpyt(this.getParam(url, 'r')); // saltFactor r
    this.initializeGoogleAnalytics(factorR);
    const factorF = this.getParam(url, 'f');
    if (this.isNotNull(factorR) && this.isNotNull(factorF)) {
      this.appConfig.isAllowed = true;
      this.appConfig.rsaId = factorR;
      this.appConfig.brokerReference = factorF;
      this.premiumService.setBrokerReference(this.appConfig.brokerReference);
    } else {
      this.appConfig.isAllowed = false;
    }

    return this.appConfig;


  }


  private isNotNull(value: string): boolean {
    if (value != null && value !== '') {
      return true;
    }
    return false;
  }


  private getParam(url: string, key: string) {
    const param = url.replace(new RegExp('^(?:.*[&\\?]' + key.replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1');
    return decodeURIComponent(param);

  }
}
