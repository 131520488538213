import {Injectable} from '@angular/core';
import {PersonalService} from '../../personal/service/personal.service';
import {PremiumPersonalInfoModel} from '../model/premium-personal-info.model';
import {PersonalInfoTypeModel} from '../../personal/model/personal-info-type.model';
import {PremiumUrlService} from './premium-url.service';
import {MSTIService} from '../../shared/services/msti.service';
import {PremiumTermsAndConditionsModel} from '../model/premium-terms-and-conditions.model';
import {BrokerDetailsModel} from '../../shared/broker-common/broker-details.model';
import {PremiumTermsAndConditionsPartyModel} from '../model/premium-terms-and-conditions-party-model';
import {FormatCurrencyPipe} from '../../shared/pipes/format-currency.pipe';
import {PremiumConfig} from '../component/premium.config';
import {PremiumQuoteSummaryItemModel} from '../model/premium-quote-summary-item.model';
import {QuoteRiskPremium} from '../../quote/model/cif/helper/quote-risk-premium';

@Injectable()
export class PremiumService {

  constructor(private personalService: PersonalService,
              private premiumUrlService: PremiumUrlService,
              private mstiService: MSTIService,
              private formatCurrencyPipe: FormatCurrencyPipe) {
  }

  private brokerReference: string;

  getPersonalInfo(): PremiumPersonalInfoModel {
    const clientPersonalDetailModel = this.personalService.getClientPersonalDetailModel();

    return new PremiumPersonalInfoModel(
      this.getFullName(clientPersonalDetailModel.personal),
      clientPersonalDetailModel.personal.idDetails.identityValue,
      clientPersonalDetailModel.contact.email,
      clientPersonalDetailModel.contact.mobileNumber
    );
  }

  private getFullName(personalInfoTypeModel: PersonalInfoTypeModel): string {
    return (this.getStringValue(personalInfoTypeModel.titleDescription) +
      this.getStringValue(personalInfoTypeModel.firstName) +
      this.getStringValue(personalInfoTypeModel.lastName)).trim();
  }

  private getStringValue(stringValue: string): string {
    return stringValue ? stringValue + ' ' : '';
  }

  private createTermsAndConditionsPartyInfoModel(user: BrokerDetailsModel): PremiumTermsAndConditionsPartyModel {
    const premiumTermsAndConditionsModelParty: PremiumTermsAndConditionsPartyModel = new PremiumTermsAndConditionsPartyModel;
    premiumTermsAndConditionsModelParty.cellNumber = user.cellNumber;
    premiumTermsAndConditionsModelParty.firstName = user.firstName;
    premiumTermsAndConditionsModelParty.surname = user.lastName;
    premiumTermsAndConditionsModelParty.idNumber = user.idNumber;
    premiumTermsAndConditionsModelParty.guid = user.ldapGuid;
    return premiumTermsAndConditionsModelParty;
  }

  private createTermsAndConditionsModel(user: BrokerDetailsModel): PremiumTermsAndConditionsModel {
    const premiumTermsAndConditionsModel: PremiumTermsAndConditionsModel = new PremiumTermsAndConditionsModel;
    premiumTermsAndConditionsModel.party = this.createTermsAndConditionsPartyInfoModel(user);
    premiumTermsAndConditionsModel.distributionChannel = 'DIRECT';
    premiumTermsAndConditionsModel.physicalChannel = 'WEB';
    premiumTermsAndConditionsModel.segment = 'MOMENTUM';
    premiumTermsAndConditionsModel.productLegalContentGuid = 'c64cf6e7-0eb5-44ce-8f87-849e77574a3c';
    return premiumTermsAndConditionsModel;
  }

  saveTermsAndConditionsAgreement(user: BrokerDetailsModel) {
    let url: string;
    url = this.premiumUrlService.getBffSaveTermsAndConditionsURL(this.brokerReference);
    return this.mstiService.postWithAbAuth(url, null);


  }

  //#region Helper Methods

  formatCurrencyValue(value, allowStringValues) {
    if (value) {
      if (allowStringValues) {
        return (value === PremiumConfig.EMPTY_DISPLAY_VALUE) ?
          this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE) :
          this.isNumeric(value) ?
            this.formatCurrencyPipe.transform(parseFloat(value)) :
            this.formatCurrencyPipe.transform(0, value);
      } else {
        if (value === PremiumConfig.EMPTY_DISPLAY_VALUE) {
          return this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE);
        } else if (this.isNumeric(value)) {
          return this.formatCurrencyPipe.transform(parseFloat(value));
        }
      }
    }

    return this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE);
  }

  isNumeric(value) {
    return !isNaN(value);
  }

  updateAccessories(sectionCode, accessories, covers) {
    accessories = this.reOrderAccessories(sectionCode, accessories);
    accessories = this.updateComprehensivePremiumInAccessories(sectionCode, accessories, covers);
    return accessories;
  }

  updateCovers(covers) {
    return this.removeComprehensiveOptionInCovers(covers);
  }

  updateRiskPremiums(riskPremiums: QuoteRiskPremium[]) {
    const nonPersonalBelongingsPremiums = riskPremiums.filter(
      riskPremium =>
        riskPremium.sectionCode !==
        PremiumConfig.ALL_RISK_COVER_TYPE_CODE
    );
    const unspecifiedPersonalBelongingsPremiums = riskPremiums.filter(
      riskPremium =>
        riskPremium.sectionCode ===
        PremiumConfig.ALL_RISK_COVER_TYPE_CODE &&
        riskPremium.subjectMatterInsuredList.some(accessory =>
          accessory.smiDescription
            .toLowerCase()
            .includes('unspecified all risk')
        )
    );
    const specifiedPersonalBelongingsPremiums = riskPremiums.filter(
      riskPremium =>
        riskPremium.sectionCode ===
        PremiumConfig.ALL_RISK_COVER_TYPE_CODE &&
        riskPremium.subjectMatterInsuredList.some(
          accessory =>
            !accessory.smiDescription
              .toLowerCase()
              .includes('unspecified all risk')
        )
    );
    const newUnspecifiedPersonalBelongingsPremiums = this.updatePersonalBelongingsPremiums(unspecifiedPersonalBelongingsPremiums);
    const newSpecifiedPersonalBelongingsPremiums = this.updatePersonalBelongingsPremiums(specifiedPersonalBelongingsPremiums);
    const newRiskPremiums = new Array();

    if (nonPersonalBelongingsPremiums) {
      newRiskPremiums.push(...nonPersonalBelongingsPremiums);
    }

    if (newUnspecifiedPersonalBelongingsPremiums) {
      newRiskPremiums.push(newUnspecifiedPersonalBelongingsPremiums);
    }

    if (newSpecifiedPersonalBelongingsPremiums) {
      newRiskPremiums.push(newSpecifiedPersonalBelongingsPremiums);
    }

    const sortedRiskPremiums = this.sortRiskPremiums(newRiskPremiums);

    return sortedRiskPremiums;
  }

  private sortRiskPremiums(riskPremiums) {
    return riskPremiums.sort(function (riskPremiumA, riskPremiumB) {

      if (riskPremiumA.riskDescription > riskPremiumB.riskDescription) {
        return -1;
      }
      if (riskPremiumA.riskDescription < riskPremiumB.riskDescription) {
        return 1;
      }

      if (riskPremiumA.sectionCode > riskPremiumB.sectionCode) {
        return 1;
      }
      if (riskPremiumA.sectionCode < riskPremiumB.sectionCode) {
        return -1;
      }

    });
  }

  private updatePersonalBelongingsPremiums(personalBelongingsPremiums) {
    if (personalBelongingsPremiums.length > 0) {
      let sasria = 0;
      const newPersonalBelongingsAccessories = personalBelongingsPremiums.map(personalBelongingsPremium => {
        if (personalBelongingsPremium.coverList.some(cover => cover.coverDescription.toLowerCase() === 'sasria')) {
          sasria += Number(
            personalBelongingsPremium.coverList.filter(
              cover =>
                cover.coverDescription.toLowerCase() ===
                'sasria'
            )[0].premium.premiumValue
          );
        }
        // Below is a fix for the sasria being added tot total premium
        personalBelongingsPremium.totalRiskPremium.premiumValue = personalBelongingsPremium.coverList[0].premium.premiumValue;
        return {
          ...personalBelongingsPremium.subjectMatterInsuredList[0],
          ['premium']: personalBelongingsPremium.totalRiskPremium.premiumValue
        };
      });

      const newPersonalBelongingsSasriaCovers = personalBelongingsPremiums[0].coverList.filter(
        cover =>
          cover.coverDescription.toLowerCase() ===
          'sasria'
      );

      const newPersonalBelongingsCovers = {
        ...newPersonalBelongingsSasriaCovers[0],
        premium: {
          ...newPersonalBelongingsSasriaCovers.premium,
          ['premiumValue']: sasria
        }
      };

      const newPersonalBelongingsPremiums = {
        ...personalBelongingsPremiums[0],
        ['accessories']: newPersonalBelongingsAccessories,
        ['covers']: [newPersonalBelongingsCovers]
      };
      return newPersonalBelongingsPremiums;
    }

    return null;
  }


  private reOrderAccessories(sectionCode, accessories) {
    const coverType = this.getCoverType(sectionCode);
    const riskIndex = accessories.findIndex(item => item.coverType === coverType
      && item.sumInsured.toLowerCase() === 'retail');
    accessories.push(...accessories.splice(0, riskIndex));
    return accessories;
  }

  private updateComprehensivePremiumInAccessories(sectionCode, accessories, covers) {
    const coverType = this.getCoverType(sectionCode);
    const riskIndex = accessories.findIndex(item => item.coverType === coverType && item.isRiskDescriptionRow === true);
    const coverWithComprehensivePremium = covers.filter(cover => {
      return cover.description.toLowerCase() === 'comprehensive' || cover.sumInsured.toLowerCase() === 'retail';
    });

    if (coverWithComprehensivePremium.length === 1) {
      const newAccessories = [
        ...accessories.slice(0, riskIndex),
        {
          ...accessories[riskIndex],
          ['premium']: coverWithComprehensivePremium[0].premium
        },
        ...accessories.slice(riskIndex + 1)
      ];

      return newAccessories;
    }

    return accessories;
  }

  private removeComprehensiveOptionInCovers(covers) {
    return covers.filter(cover =>
      cover.description.toLowerCase() !== 'comprehensive');
  }

  getHeading(sectionCode, riskAddress) {
    const coverType = this.getCoverType(sectionCode);
    return [
      new PremiumQuoteSummaryItemModel(
        coverType,
        coverType,
        coverType === PremiumConfig.COVER_TYPE_TITLE.VEHICLE ? 'A contribution you are required to pay towards a claim you make on your vehicle insurance cover.' : '',
        this.formatCurrencyPipe.transform(0, 'Sum Currently Insured'),
        this.formatCurrencyPipe.transform(0, 'Excess'),
        this.formatCurrencyPipe.transform(0, 'Premium'),
        true,
        false,
        riskAddress
      )
    ];
  }

  updateHeading(heading, premiumQuoteSummaryCoverSections, coverType, riskDescription) {
    if (coverType === this.getCoverType(PremiumConfig.ALL_RISK_COVER_TYPE_CODE)) {
      if (
        premiumQuoteSummaryCoverSections.some(
          premiumQuoteSummaryCoverSection =>
            premiumQuoteSummaryCoverSection.some(
              section =>
                section.coverType ===
                this.getCoverType(PremiumConfig.ALL_RISK_COVER_TYPE_CODE) &&
                section.riskAddress ===
                riskDescription
            )
        )
      ) {
        return [];
      } else {
        return [...heading,
          new PremiumQuoteSummaryItemModel(
            this.getCoverType(PremiumConfig.ALL_RISK_COVER_TYPE_CODE),
            `Address: ${riskDescription.replace(/,/g, ', ')}`,
            '',
            this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE),
            this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE),
            this.formatCurrencyPipe.transform(0, PremiumConfig.EMPTY_DISPLAY_VALUE),
            false,
            false,
            riskDescription
          )];
      }
    }

    return heading;
  }

  getCoverType(sectionCode) {
    const coverTypeSwitchConfig = {
      '1001': PremiumConfig.COVER_TYPE_TITLE.VEHICLE,
      '3002': PremiumConfig.COVER_TYPE_TITLE.CONTENTS,
      '3001': PremiumConfig.COVER_TYPE_TITLE.BUILDING,
      '5002': PremiumConfig.COVER_TYPE_TITLE.ALL_RISK
    };

    return this.switcher(sectionCode, coverTypeSwitchConfig, PremiumConfig.EMPTY_STRING);
  }

  getFormattedRiskDescription(sectionCode, riskDescription, accessoryDescription) {
    const formattedRiskDescriptionSwitchConfig = {
      '1001': accessoryDescription.toLowerCase() === 'vehicle' ?
        riskDescription.replace(/,/g, ', ') : accessoryDescription,
      '3002': accessoryDescription.toLowerCase() === 'contents' ?
        `Address: ${riskDescription.replace(/,/g, ', ')}` : accessoryDescription,
      '3001': accessoryDescription.toLowerCase() === 'building' ?
        `Address: ${riskDescription.replace(/,/g, ', ')}` : accessoryDescription,
      '5002': accessoryDescription.toLowerCase() === 'all-risk' ?
        `Address: ${riskDescription.replace(/,/g, ', ')}` : accessoryDescription
    };

    return this.switcher(sectionCode, formattedRiskDescriptionSwitchConfig, PremiumConfig.EMPTY_STRING);
  }

  getFormattedRiskPremium(sectionCode, accessoryDescription, riskPremium, accessoryPremium) {
    const formattedRiskPremiumSwitchConfig = {
      '1001': accessoryDescription.toLowerCase() === 'vehicle' ? riskPremium : accessoryPremium,
      '3002': accessoryDescription.toLowerCase() === 'contents' ? riskPremium : accessoryPremium,
      '3001': accessoryDescription.toLowerCase() === 'building' ? riskPremium : accessoryPremium,
      '5002': accessoryDescription.toLowerCase() === 'all-risk' ? riskPremium : accessoryPremium
    };

    return this.switcher(sectionCode, formattedRiskPremiumSwitchConfig, PremiumConfig.EMPTY_STRING);
  }

  isRiskDescriptionRow(sectionCode, accessoryDescription) {
    const isRiskDescriptionRowSwitchConfig = {
      '1001': accessoryDescription.toLowerCase() === 'vehicle',
      '3002': accessoryDescription.toLowerCase() === 'contents',
      '3001': accessoryDescription.toLowerCase() === 'building',
      '5002': accessoryDescription.toLowerCase() === 'all-risk'

    };

    return this.switcher(sectionCode, isRiskDescriptionRowSwitchConfig, false);
  }

  getHintDescription(option) {
    const hintDescriptionSwitchConfig = {
      'momentum assist': 'Help for emergencies on the road, at your home, office or even on the medical or legal front.',
      'sasria': 'A state-owned short-term insurer that provides cover to all the people and businesses that have assets ' +
        'in South Africa, against special risks such as civil commotion, public disorder, strikes, riots and terrorism.'
    };

    return this.switcher(option.toLowerCase(), hintDescriptionSwitchConfig, PremiumConfig.EMPTY_STRING);
  }

  private switcher(option, config, defaultValue) {
    const Switch = (key) => (config)[key];
    return Switch(option) || defaultValue;
  }

  //#endregion Helper Methods

  setBrokerReference(value: string) {
    this.brokerReference = value;
  }
}
