import {EventEmitter, Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {LookupFilteredDropDownModel} from '../../../../shared/lookup/lookup.filtered.drop.down.model';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {VehicleService} from './vehicle.service';
import {NumberFilteredDropDownModel} from '../../../../shared/filtered-dropdown/number-filtered-drop-down.model';
import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {ValidateRsaId} from '../../../../shared/validators/rsaId.validator';
import {DefaultLookupModel} from '../../../../shared/lookup/default-lookup.model';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {RadioButtonYesNoDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-yesno/radiobutton-yesno-dynamic-control.model';
import {IdNumberDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/id-number/idnumber-dynamic-control.model';
import {AutocompleteDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/autocomplete/autocomplete-dynamic-control.model';
import {TextboxDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/textbox/textbox-dynamic-control.model';
import {AddressDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/address/address-dynamic-control.model';
import {DatePickerDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/datepicker/datepicker-dynamic-control.model';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {TitleDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-control.model';
import {TitleDynamicFormControlComponent} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-form-control.component';
import {driverAgeValidator} from '../../../../shared/validators/driverAge.validator';
import {BrokerDetailsService} from '../../../../shared/broker-common/broker-details.service';
import {DatePipe} from '@angular/common';

@Injectable()
export class VehicleDriverDetailsService extends BaseRiskTabService {

  vehicleRegularDriverTitles: LookupFilteredDropDownModel;
  vehicleRegularDriverGenders: LookupFilteredDropDownModel;
  vehicleRegularDriverMaritalStatuses: LookupFilteredDropDownModel;
  vehicleRegularDriverOccupations: LookupFilteredDropDownModel;
  vehicleRegularDriverRelatedInsurers: LookupFilteredDropDownModel;
  vehicleRegularDriverLicenseTypes: LookupFilteredDropDownModel;
  vehicleRegularDriverLicenseLimitations: LookupFilteredDropDownModel;
  vehicleYears: NumberFilteredDropDownModel;

  isRegularDriverNotSelectedSubject: BehaviorSubject<boolean>;
  regularDriverHintClickedEvent: EventEmitter<any>;
  drivingConvictionsHintClickedEvent: EventEmitter<any>;

  vehicleRegularDriverGenderUpdateSubject: Subject<DefaultLookupModel>;
  clearVehicleRegularDriverGenderObservable: Subject<boolean>;
  isVehicleRegularDriverGenderPreselectedSubject: Subject<boolean>;
  driversDob: string;

  constructor(private loaderService: LoaderService, private vehicleService: VehicleService, private brokerDetailsService: BrokerDetailsService,
              private datePipe: DatePipe) {
    super();
    this.vehicleRegularDriverTitles = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverGenders = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverMaritalStatuses = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverOccupations = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverRelatedInsurers = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverLicenseTypes = new LookupFilteredDropDownModel([]);
    this.vehicleRegularDriverLicenseLimitations = new LookupFilteredDropDownModel([]);
    this.vehicleYears = new NumberFilteredDropDownModel();
    this.vehicleRegularDriverGenderUpdateSubject = new Subject<DefaultLookupModel>();
    this.clearVehicleRegularDriverGenderObservable = new Subject<boolean>();
    this.isVehicleRegularDriverGenderPreselectedSubject = new Subject<boolean>();


  }


  init(): void {
    this.vehicleYears.setValues(this.vehicleService.getYears());
    this.isRegularDriverNotSelectedSubject = new BehaviorSubject<boolean>(true);
    this.regularDriverHintClickedEvent = new EventEmitter();
    this.drivingConvictionsHintClickedEvent = new EventEmitter();

    this.loaderService.show();
    this.vehicleService.getTitles().then(data => {
      this.vehicleRegularDriverTitles.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getGenders().then(data => {
      this.vehicleRegularDriverGenders.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getMaritalStatus().then(data => {
      this.vehicleRegularDriverMaritalStatuses.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getOccupations().then(data => {
      this.vehicleRegularDriverOccupations.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getRegularDriverRelatedInsurers().then(data => {
      this.vehicleRegularDriverRelatedInsurers.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getLicenseTypes().then(data => {
      this.vehicleRegularDriverLicenseTypes.setValues(data);
    }).then(() => this.loaderService.hide());

    this.loaderService.show();
    this.vehicleService.getLicenseLimitations().then(data => {
      this.vehicleRegularDriverLicenseLimitations.setValues(data);
    }).then(() => this.loaderService.hide());

  }

  isRegularDriverChanged(selectedValue) {
    this.isRegularDriverNotSelectedSubject.next(this.isRadioOptionYes(selectedValue));
  }


  isRadioOptionYes(radioOption: string) {
    return !radioOption || radioOption === '1';
  }

  getRsaIdForInsurer(): string {
    return this.brokerDetailsService.getUser().idNumber;
  }

  getDynamicFormControls(): BaseDynamicControlModel<any>[] {
    const dynamicFormControls: BaseDynamicControlModel<any>[] = [
      new TitleDynamicControlModel(
        {
          key: 'Regular driver details',
          label: 'Regular driver details',
          value: '',
          // hidden: this.personalBelongingsService.showAddressAlreadyExistControls.asObservable(),
          style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
          pullRight: false,
          center: true,
          validation: [],
          order: 1,
        }
      ),
      new RadioButtonYesNoDynamicControlModel(
        {
          key: 'vehicleIsRegularDriver',
          label: 'Is your client the regular driver of the vehicle?',
          hint: 'A regular driver is a person who drives the vehicle most often.',
          hintClickedEvent: this.regularDriverHintClickedEvent,
          value: '',
          validation: [Validators.required],
          order: 2
        }
      ),
      new IdNumberDynamicControlModel(
        {
          key: 'vehicleRegularDriverIDNumber',
          label: 'South African ID number',
          hint: '',
          value: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required, ValidateRsaId],
          order: 3,
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverTitle',
          label: 'Title of the regular driver',
          hint: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 4,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverTitles.filteredValues).asObservable(),
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new TextboxDynamicControlModel(
        {
          key: 'vehicleRegularDriverFullName',
          label: 'Full names of the regular driver',
          hint: '',
          value: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 5,
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new TextboxDynamicControlModel(
        {
          key: 'vehicleRegularDriverSurname',
          label: 'Surname of the regular driver?',
          hint: '',
          value: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 6,
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverGender',
          label: 'Gender of the regular driver',
          hint: '',
          updateValueObservable: this.vehicleRegularDriverGenderUpdateSubject.asObservable(),
          clearValueObservable: merge(
            this.clearVehicleRegularDriverGenderObservable,
            this.isRegularDriverNotSelectedSubject
          ),
          validation: [Validators.required],
          order: 7,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverGenders.filteredValues).asObservable(),
          hidden: this.isRegularDriverNotSelectedSubject.asObservable(),
          readonly: this.isVehicleRegularDriverGenderPreselectedSubject.asObservable()

        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverMaritalStatus',
          label: 'Marital status of the regular driver',
          hint: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 8,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverMaritalStatuses.filteredValues).asObservable(),
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverOccupation',
          label: 'Occupation of the regular driver',
          hint: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 9,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverOccupations.filteredValues).asObservable(),
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverRelationshipToInsured',
          label: 'Relationship of the regular driver to the insured person',
          hint: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 10,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverRelatedInsurers.filteredValues).asObservable(),
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new TextboxDynamicControlModel(
        {
          key: 'vehicleRegularDriverContactNumber',
          label: 'Cell phone number for the regular driver',
          hint: '',
          value: '',
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [Validators.required],
          order: 11,
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AddressDynamicControlModel(
        {
          key: 'vehicleRegularDriverAddress',
          label: 'Address of the regular driver ',
          hint: '',
          value: new AddressTypeModel('HOME'),
          clearValueObservable: this.isRegularDriverNotSelectedSubject.asObservable(),
          validation: [],
          order: 12,
          hidden: this.isRegularDriverNotSelectedSubject.asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverLicenseType',
          label: 'Licence type of the regular driver',
          hint: '',
          validation: [Validators.required],
          order: 13,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverLicenseTypes.filteredValues).asObservable()
        }
      ),
      new AutocompleteDynamicControlModel(
        {
          key: 'vehicleRegularDriverLicenseLimitations',
          label: 'Licence limitations that apply to the regular driver',
          hint: '',
          validation: [Validators.required],
          order: 14,
          availableOptionsObservable:
            new BehaviorSubject<LookupModel[]>(this.vehicleRegularDriverLicenseLimitations.filteredValues).asObservable()
        }
      ),
      new DatePickerDynamicControlModel(
        {
          key: 'vehicleRegularDriverLicenseDateIssued',
          label: 'When was the regular driver\'s licence issued?',
          hint: '',
          value: '',
          validation: [Validators.required, driverAgeValidator(this.driversDob)],
          order: 15
        }
      ),
      new RadioButtonYesNoDynamicControlModel(
        {
          key: 'vehicleRegularDriverHasDrivingConvictions',
          label: 'Does the regular driver have any driving convictions?',
          hint: 'Driving convictions is when the court of law finds the driver guilty of driving violations.',
          hintClickedEvent: this.drivingConvictionsHintClickedEvent,
          value: '',
          validation: [Validators.required],
          order: 16
        }
      )
    ];

    return dynamicFormControls.sort((a, b) => a.order - b.order);
  }

  updateVehicleRegularDriverGender(vehicleRegularDriverTitle) {

    this.isVehicleRegularDriverGenderPreselectedSubject.next(this.hasPreselectedGender(vehicleRegularDriverTitle));

    if (this.hasPreselectedGender(vehicleRegularDriverTitle) === true) {
      const currentGenderLookup = VehicleService.MALE_GENDER_TITLES.includes(vehicleRegularDriverTitle.getDescription()) ?
        this.vehicleRegularDriverGenders.filteredValues.find((element) => {
          return element.description.toLowerCase() === VehicleService.MALE_GENDER.toLowerCase();
        })
        :
        this.vehicleRegularDriverGenders.filteredValues.find((element) => {
          return element.description.toLowerCase() === VehicleService.FEMALE_GENDER.toLowerCase();
        })
      ;
      this.vehicleRegularDriverGenderUpdateSubject.next(currentGenderLookup);
    } else {
      this.clearVehicleRegularDriverGenderObservable.next(true);
    }
  }

  private hasPreselectedGender(vehicleRegularDriverTitle: LookupModel) {
    if (vehicleRegularDriverTitle && vehicleRegularDriverTitle.getCode) {
      return VehicleService.FEMALE_GENDER_TITLES.concat(VehicleService.MALE_GENDER_TITLES)
        .includes(vehicleRegularDriverTitle.getDescription());
    } else {
      return false;
    }
  }


  getDobFromRsaId(driverRsaId) {
    let ageFromRsaId = driverRsaId.substr(0, 6);
    ageFromRsaId = ageFromRsaId.substr(0, 2) + '/' + ageFromRsaId.substr(2, 2) + '/' + ageFromRsaId.substr(4, 2);

    return this.datePipe.transform(ageFromRsaId, 'yyyy-MM-dd');
  }


}
