import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LookupFilteredDropDownModel} from '../../shared/lookup/lookup.filtered.drop.down.model';
import {map, startWith} from 'rxjs/operators';
import {DefaultLookupModel} from '../../shared/lookup/default-lookup.model';
import {Observable} from 'rxjs';
import {DisclosureService, DisclosureTags} from '../services/disclosure.service';
import {MatAutocompleteSelectedEvent, MatRadioChange, MatSelectChange} from '@angular/material';
import {RoutingService} from '../../shared/services/routing.service';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {SmoothScroll} from '../../shared/utilities/smooth-scroll';
import {CifDisclosureQuestionsModel} from '../models/CifDisclosureQuestions.model';
import {Logger} from '../../shared/utilities/logger';
import {Level} from '../../shared/utilities/logger-level';

@Component({
  selector: 'app-disclosure-questions',
  templateUrl: './disclosure-questions.component.html',
  animations: []
})
export class DisclosureQuestionsComponent implements OnInit, AfterViewChecked {
  public disclosureForm: FormGroup;
  q1FormControl: FormControl;
  q2FormControl: FormControl;
  q3FormControl: FormControl;
  q4FormControl: FormControl;
  q5FormControl: FormControl;
  q6FormControl: FormControl;
  filteredQ2Options: Observable<DefaultLookupModel[]>;
  q2options: LookupFilteredDropDownModel;
  q3options: LookupFilteredDropDownModel;
  q4options: LookupFilteredDropDownModel;
  q5options: LookupFilteredDropDownModel;
  submitted = false;
  showQ2 = false;
  showQ4 = false;
  showIdsClaims = false; // this is a trigger jus tto redraw the view.

  constructor(private formBuilder: FormBuilder, private disclosureService: DisclosureService,
              private routingService: RoutingService, private loaderService: LoaderService, private cdRef: ChangeDetectorRef,
              private digitalAnalyticsService: DigitalAnalyticsService, private mstiErrorService: MstiErrorService) {
    this.disclosureForm = this.formBuilder.group({});
    this.setControls();

  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.initializeLookUpData();
    this.getSavedDisclosure();

  }

  setControls() {
    this.q1FormControl = new FormControl('', [Validators.required]);
    this.q2FormControl = new FormControl('', []); // initially this must not have any validator
    this.q3FormControl = new FormControl('', []); // initially this must not have any validator
    this.q4FormControl = new FormControl('', []); // initially this must not have any validator
    this.q5FormControl = new FormControl('', [Validators.required]);
    this.q6FormControl = new FormControl('', [Validators.required]);
    this.disclosureForm.addControl('q1FormControl', this.q1FormControl);
    this.disclosureForm.addControl('q2FormControl', this.q2FormControl);
    this.disclosureForm.addControl('q3FormControl', this.q3FormControl);
    this.disclosureForm.addControl('q4FormControl', this.q4FormControl);
    this.disclosureForm.addControl('q5FormControl', this.q5FormControl);
    this.disclosureForm.addControl('q6FormControl', this.q6FormControl);
  }


  private initializeLookUpData() {
    this.q2options = this.disclosureService.insuranceCompanyOptions;
    this.q3options = this.disclosureService.periodWithoutCoverOptions;
    this.q4options = this.disclosureService.periodWithoutCoverOptions;
    this.q5options = this.disclosureService.previousCancellationOptions;
    this.filteredQ2Options = this.q2FormControl.valueChanges
      .pipe(
        startWith<string | DefaultLookupModel>(''),
        map(value => typeof value === 'string' ? value : value.description),
        map(name => name ? this._filter(name, this.q2options) : this.q2options.values.slice())
      );
  }

  private _filter(value: string, options: LookupFilteredDropDownModel): DefaultLookupModel[] {
    const filterValue = value.toLowerCase();
    return options.values.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  displayFn(option: DefaultLookupModel): DefaultLookupModel | string {
    // This method helps to display on the description part of the DefaultLookupModel
    return option ? option.description : option;
  }

  validateInput(formControlName: FormControl, input: string, value: any, options: LookupFilteredDropDownModel) {
    if (value && options) {
      const dataList = options.values;
      if (!dataList.find(x => x.description === formControlName.value.description)) {
        formControlName.setErrors({'incorrect': true});
        formControlName.errors.errMsg = 'Enter valid option provided in the list';

      } else {
        formControlName.setErrors(null);
      }
    }
  }

  clearField(input, questionNumber: number) {
    input.target.value = '';
    input.target.setAttribute('ng-reflect-disclosureModel', '');
    input.target.dispatchEvent(new Event('input'));
    if (questionNumber === 2) {
      this.filteredQ2Options = this.q2FormControl.valueChanges
        .pipe(
          startWith<string | DefaultLookupModel>(''),
          map(value => typeof value === 'string' ? value : value.description),
          map(name => name ? this._filter(name, this.q2options) : this.q2options.values.slice())
        );
    }

  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.q2FormControl.setValue(event.option.value);
  }


  onQ3optionSelected(event: MatSelectChange) {
    if (event.value.description === 'Never been covered') {
      this.showQ4 = false;
      this.q4FormControl.setValidators(null);
    } else { // q4
      this.showQ4 = true;
      this.q4FormControl.setValidators([Validators.required]);
    }
    this.q4FormControl.updateValueAndValidity();
  }


  onSubmit() {
    this.submitted = true;
    if (this.q6FormControl.value === 'Yes') {
      this.touchAllElements(this.disclosureForm);
    }

    if (this.q1FormControl.value === 'Yes') {// additional validation  to make sure than any non premia value goes to premia
      const dataList = this.q2options.values;
      if (!dataList.find(x => x.description === this.q2FormControl.value.description)) {
        this.q2FormControl.setErrors({'incorrect': true});
        this.q2FormControl.errors.errMsg = 'Enter valid option provided in the list';    //  formControlName.setValue('');
      }
    } else {
      this.q2FormControl.setValue(null);
    }
    if (!this.disclosureForm.invalid) {
      this.loaderService.show();
      this.getDisclosureModelFromForm();
      this.disclosureService.saveOrUpdateDisclosure(this.disclosureService.disclosureQuestionsModel).subscribe(response => {
        // TODO do we need the below code
        /* const saveOrUpdateResponse = Object.assign(new CifDisclosureQuestionsModel(), response);
       this.loaderService.show();
        this.disclosureService.convertCifModelToDisclosureQuestion(saveOrUpdateResponse).then(disclosureQuestionsModel => {
          this.disclosureService.disclosureQuestionsModel = disclosureQuestionsModel;
          this.loaderService.hide();
        });*/
        this.routingService.route('/quote');
        this.loaderService.hide();
      }, (error) => {
        this.loaderService.hide();
        this.mstiErrorService.handleError(error);
      });
    } else {
      SmoothScroll.smoothScrollToTop('.mat-form-field-invalid');
    }
  }


  getDisclosureModelFromForm() {
    this.disclosureService.getDisclosureQuestionsModel().currentlyInsured = this.q1FormControl.value;
    this.disclosureService.getDisclosureQuestionsModel().currentInsurerInfo = this.q2FormControl.value;
    this.disclosureService.getDisclosureQuestionsModel().uninsuredPeriodInfo = this.q3FormControl.value;
    this.disclosureService.getDisclosureQuestionsModel().insuredPeriodInfo = this.q4FormControl.value;
    this.disclosureService.getDisclosureQuestionsModel().cancellationReasonInfo = this.q5FormControl.value;
    this.disclosureService.getDisclosureQuestionsModel().hasPreviousClaim = this.q6FormControl.value;
    if (this.q6FormControl.value === 'No') {
      // If selection is no then make prevclaim = []
      for (let i = 0; i < this.disclosureService.getDisclosureQuestionsModel().previousClaims.length; i++) {
        this.disclosureService.getDisclosureQuestionsModel().previousClaims[i].event = DisclosureTags.DELETE;
      }
      Object.keys(this.disclosureForm['controls']).forEach(element => {
        if (this.disclosureForm.get(element) instanceof FormGroup) {
          this.disclosureForm.removeControl(element);
        }
      });
    }
  }

  isFormControlInValid(formControl: FormControl) {
    if (this.submitted && formControl.errors && formControl.errors.required) {
      formControl.errors.errMsg = 'This field is required';
      return true;
    } else {
      return false;
    }
  }

  isFormControlValueInValid(formControl: FormControl) {
    if (formControl.errors && formControl.errors.incorrect) {
      formControl.errors.errMsg = 'Enter valid option provided in the list';
      return true;
    } else {
      return false;
    }
  }

  q1OnChange(event: MatRadioChange) {
    console.log('ABX in q1OnChange', event.value);
    console.log('abx this.q3FormControl.value.description = ', this.q3FormControl.value.description);
    if (event.value === 'Yes') {
      this.q2FormControl.setValidators([Validators.required]);
      this.q4FormControl.setValidators([Validators.required]);
      this.q3FormControl.setValidators([]);
      this.showQ2 = true;
    } else { // No
      if (this.q3FormControl.value.description === undefined || (this.q3FormControl.value && this.q3FormControl.value.description === 'Never been covered')) {
        this.showQ4 = false;
        this.q4FormControl.setValidators([]);
      } else {
        this.showQ4 = true;
        this.q4FormControl.setValidators([Validators.required]);
      }
      this.q2FormControl.setValidators([]);
      this.q4FormControl.setValidators([]);
      this.q3FormControl.setValidators([Validators.required]);
      this.showQ2 = false;
    }
    this.q2FormControl.updateValueAndValidity();
    this.q3FormControl.updateValueAndValidity();
    this.q4FormControl.updateValueAndValidity();

  }

  getSavedDisclosure() {
    this.loaderService.show();
    this.disclosureService.getDisclosure().subscribe(response => {
      Logger.log(Level.LOG, 'Get disclosure response', response);
      if (response && response.data !== null) {
        const cifDisclosureQuestionsModel = Object.assign(new CifDisclosureQuestionsModel(), response);

        this.disclosureService.convertCifModelToDisclosureQuestion(cifDisclosureQuestionsModel).then((model) => {
          this.disclosureService.disclosureQuestionsModel = model;
          this.prePopulate();
        });
      } else {
        this.showIdsClaims = true;
      }
      this.loaderService.hide();
    }, (error) => {
      this.showIdsClaims = true;
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
      Logger.log(Level.LOG, 'Get disclosure response error' + error);
    });
  }

  prePopulate() {
    const model = this.disclosureService.getDisclosureQuestionsModel();
    if (model) {
      this.q1FormControl.setValue(model.currentlyInsured === 'No' ? 'No' : 'Yes');
      if (this.q1FormControl.value === 'Yes') {
        this.q2FormControl.setValue(model.currentInsurerInfo); // Q2
        this.q4FormControl.setValue(model.insuredPeriodInfo); // Q4
        this.q4FormControl.setValidators([Validators.required]);
        this.showQ4 = true;
      } else {
        this.q3FormControl.setValue(model.uninsuredPeriodInfo); // q3
        console.log('abx this.q3FormControl.value.description = ', this.q3FormControl.value.description);
        if (this.q3FormControl.value.description === undefined || (this.q3FormControl.value && this.q3FormControl.value.description === 'Never been covered')) {
          console.log('abx this.q3FormControl.value.description = 1');
          this.showQ4 = false;
          this.q4FormControl.setValidators(null);
        } else {
          console.log('abx this.q3FormControl.value.description = 2');
          this.showQ4 = true;
          this.q4FormControl.setValidators([Validators.required]);
          this.q4FormControl.setValue(model.insuredPeriodInfo);
        }
      }
      this.q4FormControl.updateValueAndValidity();


      this.q5FormControl.setValue(model.cancellationReasonInfo); // Always ask this question
      this.q6FormControl.setValue(model.hasPreviousClaim === 'No' ? 'No' : 'Yes');
      if (this.q6FormControl.value === 'Yes') {
        this.disclosureService.getDisclosureQuestionsModel().previousClaims = model.previousClaims;
        console.log('abx', this.disclosureService.getDisclosureQuestionsModel().previousClaims);
        for (let i = 0; i < this.disclosureService.getDisclosureQuestionsModel().previousClaims.length; i++) {
          this.disclosureService.getDisclosureQuestionsModel().previousClaims[i].claimId = i;
        }
      }
    }

    this.showIdsClaims = true;
  }

  onBack() {
    this.routingService.route('/app-personal');
  }

  touchAllElements(formGroup: FormGroup) {
    Object.keys(formGroup['controls']).forEach(element => {
      if (formGroup.get(element) instanceof FormGroup) {
        const claimCntl = formGroup.get(element);
        Object.keys(claimCntl['controls']).forEach(cntl => {
          claimCntl.get(cntl).markAsTouched();

        });
      }


    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
