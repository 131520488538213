import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {CardModule} from '../../card/card.module';
import {PersonalBelongingsModule} from '../personal-belongings/personal-belongings.module';
import {ContentsComponent} from './component/contents.component';
import {ContentsService} from './service/contents.service';
import {ContentsUrlService} from './service/contents-url.service';
import {ContentsDetailsComponent} from './component/contents-details/contents-details.component';
import {StructureDetailsComponent} from './component/structure-details/structure-details.component';

@NgModule({
    declarations: [
        ContentsComponent,
        ContentsDetailsComponent,
        StructureDetailsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CardModule,
        PersonalBelongingsModule
    ],
    exports: [ContentsComponent],
    providers: [ContentsService, ContentsUrlService],
    entryComponents: [ContentsDetailsComponent, StructureDetailsComponent]
})
export class ContentsModule {
}
