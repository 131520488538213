import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {LookupModel} from '../../../../../lookup/lookup.model';
import {AutocompleteMultiSelectDynamicFormControlComponent} from './autocomplete-multiselect-dynamic-form-control.component';

export class AutocompleteMultiSelectDynamicControlModel extends BaseDynamicControlModel<string> {

    options: LookupModel[];

    constructor(controlModel?: Partial<AutocompleteMultiSelectDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return AutocompleteMultiSelectDynamicFormControlComponent;
    }

}
