import {Injectable} from '@angular/core';
import {BaseRiskTabService} from '../../base/service/base-risk-tab.service';
import {BaseDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/base-dynamic-control.model';
import {Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/index';
import {RadioButtonGroupDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-group/radiobutton-group-dynamic-control.model';
import {RadioGroupOption} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/radiobutton-group/radio-group-option.model';
import {PersonalBelongingsSpecifiedDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/risk-specific/personal-belongings-specified/personal-belongings-specified-dynamic-control.model';
import {TitleDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-control.model';
import {TitleDynamicFormControlComponent} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/title/title-dynamic-form-control.component';
import {CurrencyDynamicControlModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/generic/currency/currency-dynamic-control.model';
import {GoogleAnalyticsService} from '../../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../../shared/google-analytics/model/google-analytics-action.model';
import {PersonalBelongingsService} from './personal-belongings.service';
import {PersonalBelongingsSpecifiedModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/risk-specific/personal-belongings-specified/model/personal-belongings-specified.model';
import {AppMangerService} from '../../../../mstiappmanager/service/app_manager.service';

@Injectable()
export class PersonalBelongingsDetailsService extends BaseRiskTabService {

    private showUnspecifiedBelongingsAmountControls: BehaviorSubject<boolean>;
    private showSpecifiedBelongingsAmountControls: BehaviorSubject<boolean>;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService, private appManagerService: AppMangerService,
        private personalBelongingsService: PersonalBelongingsService
    ) {
        super();
    }

    init(): void {
        this.showUnspecifiedBelongingsAmountControls = new BehaviorSubject<boolean>(true);
        this.showSpecifiedBelongingsAmountControls = new BehaviorSubject<boolean>(true);
    }

    private displaySpecifiedCoverCard() {
        this.showUnspecifiedBelongingsAmountControls.next(true);
        this.showSpecifiedBelongingsAmountControls.next(false);
    }

    private displayUnSpecifiedCoverCard() {
        this.showUnspecifiedBelongingsAmountControls.next(false);
        this.showSpecifiedBelongingsAmountControls.next(true);
    }

    private displayBothCoverCards() {
        this.showUnspecifiedBelongingsAmountControls.next(false);
        this.showSpecifiedBelongingsAmountControls.next(false);
    }

    private displayNoCard() {
        this.showUnspecifiedBelongingsAmountControls.next(true);
        this.showSpecifiedBelongingsAmountControls.next(true);
    }

    selectedPersonalBelongingsCoverOption(selectedPersonalBelongingsCoverOption) {
        switch (selectedPersonalBelongingsCoverOption) {
            case 'Specified Personal Belongings':
                this.displaySpecifiedCoverCard();
                break;
            case 'Unspecified Personal Belongings':
                this.displayUnSpecifiedCoverCard();
                break;
            case 'Unspecified and Specified Personal Belongings':
                this.displayBothCoverCards();
                break;
            case 'None':
                this.displayNoCard();
                break;
        }
    }

    setGoogleAnalyticsForPersonalBelongingsCoverOption(selectedValue: string): void {
        switch (selectedValue) {
            case 'Specified Personal Belongings':
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_SELECT_SPECIFIED_PERSONAL_BELONGINGS
                );
                break;
            case 'Unspecified Personal Belongings':
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_SELECT_UNSPECIFIED_PERSONAL_BELONGINGS
                );
                break;
            case 'Unspecified and Specified Personal Belongings':
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_SELECT_UNSPECIFIED_AND_SPECIFIED_PERSONAL_BELONGINGS
                );
                break;
            case 'None':
                this.googleAnalyticsService.fireGoogleAnalytics(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_SELECT_NO_THANKS_I_DONT_NEED_ANY_PERSONAL_BELONGINGS_COVER
                );
                break;
            default:
                break;
        }
    }

    getDynamicFormControls(): BaseDynamicControlModel<any>[] {
        // Get the min max values
        const sectionValidation = this.appManagerService.getValidation('5002');
        let minVal = 1;
        if (sectionValidation) {
            minVal = sectionValidation.field.minimumValue;
        }
        const dynamicFormControls: BaseDynamicControlModel<any>[] = [
            new TitleDynamicControlModel(
                {
                    key: 'All Risk with same Address',
                    label: 'You have already captured your client\'s Personal Belongings details under their Home Content Insurance application. ',
                    value: '',
                    hidden: this.personalBelongingsService.showAddressAlreadyExistControls.asObservable(),
                    style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
                    pullRight: false,
                    validation: [],
                    order: 1,
                }
            ),
            new RadioButtonGroupDynamicControlModel({
                key: 'selectedPersonalBelongingsOption',
                label: 'Please select the type of Personal Belongings cover that your client needs.',
                hint: '',
                value: '',
                hidden: this.personalBelongingsService.showPersonalBelongingsControls.asObservable(),
                options: [
                    new RadioGroupOption(
                        'Unspecified Personal Belongings',
                        'Unspecified Personal Belongings',
                        'These are items that your client wear or carry with them regularly, like their gym bag or handbag and its contents. They can choose the overall cover amount they need for all the items combined. Each individual item’s value must be less than R3 500.',
                        false
                    ),
                    new RadioGroupOption(
                        'Specified Personal Belongings',
                        'Specified Personal Belongings',
                        'These are individual items that are valued at R3 500 or more. Your client must specify their value and description, like sunglasses, baby’s car seat and engagement ring. Certain items must always be specified, or you cannot claim for it, like bicycles, cell phones, portable computers and wearable devices such as smart watches.',
                        false
                    ),
                    new RadioGroupOption(
                        'Unspecified and Specified Personal Belongings',
                        'Unspecified and Specified Personal Belongings',
                        'Your client can combine both options to protect their Specified and Unspecified Personal Belongings.',
                        false
                    ),
                    new RadioGroupOption(
                        'None',
                        'No, your client does not need any Personal Belongings cover',
                        '',
                        false
                    )
                ],
                validation: [Validators.required],
                order: 1
            }),
            new TitleDynamicControlModel(
                {
                    key: 'unspecifiedBelongingsTitle',
                    label: 'Unspecified Personal Belongings',
                    value: '',
                    hidden: this.showUnspecifiedBelongingsAmountControls.asObservable(),
                    style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
                    pullRight: false,
                    validation: [],
                    order: 2,
                }
            ),
            new CurrencyDynamicControlModel(
                {
                    key: 'unspecifiedBelongingsAmount',
                    label: 'For what amount would your client like to insure their item(s)?',
                    value: 0.00,
                    validation: [Validators.required, Validators.min(minVal)],
                    hidden: this.showUnspecifiedBelongingsAmountControls.asObservable(),
                    order: 3
                }
            ),
            new TitleDynamicControlModel(
                {
                    key: 'specifiedBelongingsTitle',
                    label: 'Specified Personal Belongings',
                    value: '',
                    hidden: this.showSpecifiedBelongingsAmountControls.asObservable(),
                    style: TitleDynamicFormControlComponent.STYLE_TITLE_BOLD,
                    pullRight: false,
                    validation: [],
                    order: 4,
                }
            ),
            new PersonalBelongingsSpecifiedDynamicControlModel({
                key: 'specifiedPersonalBelongings',
                label: 'Please select the type of Personal Belongings cover that your client needs.',
                hint: '',
                value: new Array<PersonalBelongingsSpecifiedModel>(),
                validation: [Validators.required],
                hidden: this.showSpecifiedBelongingsAmountControls.asObservable(),
                order: 5
            })
        ];

        return dynamicFormControls.sort((a, b) => a.order - b.order);
    }

}
