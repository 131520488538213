import {AfterViewChecked, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {PersonalBelongingsSpecifiedDynamicControlModel} from './personal-belongings-specified-dynamic-control.model';
import {PersonalBelongingsSpecifiedModel} from './model/personal-belongings-specified.model';
import {PersonalBelongingsSpecifiedPopupDialogComponent} from '../personal-belongings-specified/personal-belongings-specified-popup-dialog/personal-belongings-specified-popup-dialog.component';
import {PremiumService} from '../../../../../../premium/service/premium.service';
import {ImageService} from '../../../../../../shared/services/image.service';
import {GoogleAnalyticsService} from '../../../../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../../../../shared/google-analytics/model/google-analytics-action.model';
import {Logger} from '../../../../../utilities/logger';
import {Level} from '../../../../../utilities/logger-level';
import {LoaderService} from '../../../../../screen-loader/loader.service';
import {MSTIService} from '../../../../../services/msti.service';
import {PersonalBelongingsUrlService} from '../../../../../../quote/sections/personal-belongings/service/personal-belongings-url.service';
import {MstiErrorService} from '../../../../../msti-error-handler/msti-error.service';

@Component({
    selector: 'app-personal-belongings-specified-dynamic-form-control',
    templateUrl: './personal-belongings-specified-dynamic-form-control.component.html',
    styleUrls: ['./personal-belongings-specified-dynamic.component.scss']
})
export class PersonalBelongingsSpecifiedDynamicFormControlComponent extends BaseDynamicFormControl<string> implements AfterViewChecked {

    @ViewChild('specifiedPersonalBelongingsInput', { static: false }) specifiedPersonalBelongingsInput: ElementRef;

    closeIcon: string;
    editIcon: string;
    options: PersonalBelongingsSpecifiedModel[];
    private afterViewCheckedComplete: boolean;

    constructor(
        injector: Injector, imageService: ImageService,
        private premiumService: PremiumService, private googleAnalyticsService: GoogleAnalyticsService,
        private loaderService: LoaderService, private mstiService: MSTIService,
        private  personalBelongingsUrlService: PersonalBelongingsUrlService,
        private mstiErrorService: MstiErrorService
    ) {
        super(injector);
        this.closeIcon = imageService.getImage('CARD-CLOSE-ICON');
        this.editIcon = imageService.getImage('CARD-EDIT-ICON');
        this.afterViewCheckedComplete = false;
    }

    doInit() {
        this.setCapturedPersonalBelongings((this.dynamicControlModel as unknown as PersonalBelongingsSpecifiedDynamicControlModel).value);
    }

    updateFormControlValue(inputValue: any) {
        this.setCapturedPersonalBelongings(inputValue);
        this.syncCapturedPersonalBelongings();
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

    ngAfterViewChecked() {
        if (!this.afterViewCheckedComplete && this.specifiedPersonalBelongingsInput) {
            // NOTE: This is required for when we patch values
            if (
                this.form.get(this.dynamicControlModel.key).value &&
                this.form.get(this.dynamicControlModel.key).value !== this.dynamicControlModel.value
            ) {
                this.setCapturedPersonalBelongings(this.form.get(this.dynamicControlModel.key).value);
                this.syncCapturedPersonalBelongings();
            }

            this.afterViewCheckedComplete = true;
            this.cdRef.detectChanges();
        }

        this.validate();
    }

    showEditor(model: PersonalBelongingsSpecifiedModel, isNewItem = false) {
        const dialogRef = this.dialog.open(PersonalBelongingsSpecifiedPopupDialogComponent, {
            width: '52%',
            panelClass: 'personal-belongings-specified-popup-dialog',
            data: {optionModel: model}
        });

        this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
            if (isNewItem && this.isModelValid(model)) {
                this.options.push(model);
            }
            this.syncCapturedPersonalBelongings();
        }));
    }

    private setCapturedPersonalBelongings(options) {
        this.options = options;
    }

    private isModelValid(model: PersonalBelongingsSpecifiedModel) {
        return model.categoryCode && model.description && model.value > 0;
    }

    private syncCapturedPersonalBelongings() {
        (this.dynamicControlModel as unknown as PersonalBelongingsSpecifiedDynamicControlModel).value = this.options;
        this.form.controls[this.dynamicControlModel.key].setValue(this.options);
        this.validate();
    }

    private validate() {
        if (this.specifiedPersonalBelongingsInput) {
            const formReference = this.form;
            const formControlReference = formReference.controls[this.dynamicControlModel.key];

            const options = (this.dynamicControlModel as unknown as PersonalBelongingsSpecifiedDynamicControlModel).value;
            if (options.length === 0) {
                this.specifiedPersonalBelongingsInput.nativeElement.value = '';
                formControlReference.setErrors({'specifiedPersonalBelongingsRequired': true});
            } else {
                this.specifiedPersonalBelongingsInput.nativeElement.value = options.length > 0 ? options.length : '';
                formControlReference.setErrors(null);
            }
        }
    }

    addNewItem() {
        const defaultModel = new PersonalBelongingsSpecifiedModel('', '', '', '', 0);
        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_YES,
            GoogleAnalyticsEventModel.EVENT_ADD_SPECIFIED_PERSONAL_BELONGINGS
        );
        this.showEditor(defaultModel, true);
    }

    removeItem(model: PersonalBelongingsSpecifiedModel) {
        this.options = this.options.filter(item => item.id !== model.id);
        // call delete here
        if (model.id) {
            this.deleteRisk(model.quoteId, model.contentRiskId, model.id);
        } else {
            this.syncCapturedPersonalBelongings();
        }


    }

    formatCurrency(value) {
        return this.premiumService.formatCurrencyValue(value, false);
    }

    private deleteRisk(quoteId: string, contentId: string, riskId: string) {
        this.loaderService.show();
        this.mstiService.deleteWithAbAuth(this.personalBelongingsUrlService.getDeleteMovablesURL(quoteId, contentId, riskId)
        ).subscribe((response: any) => {
            this.syncCapturedPersonalBelongings();
            this.loaderService.hide();
            Logger.log(Level.LOG, 'Delete movable response:', response);
        }, (error) => {
            Logger.log(Level.ERROR, 'Error while deleting movable', error);
            this.loaderService.hide();
            this.mstiErrorService.handleError(error);
        });
    }
}
