import {FormControl} from '@angular/forms';

export function phoneNumberValidator(control: FormControl) {
    const phoneNumber = control.value;
    if (phoneNumber) {
        const pattern = /^\d{3}\d{3}\d{4}$/;
        if (!pattern.test(phoneNumber)) {
            return {
                validateObj: {
                    valid: false,
                    msg: 'Invalid cell phone number'
                }
            };
        }
    } else {
        return {
            validateObj: {
                valid: false,
                msg: 'This field is required'
            }
        };
    }

}




