import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {MSTINeedHelpComponent} from '../need-help.component';

@Injectable()
export class NeedHelpDialogService {

    constructor(
        private dialog: MatDialog
    ) { }

    public showDialog() {
        return this.dialog.open(MSTINeedHelpComponent, {
            panelClass: 'need-help-dialog',
            width: '52%'
        });
    }

}
