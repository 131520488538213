import {AfterViewChecked, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';
import {AddressTypeModel} from '../../../../../msti-agm/model/address-type.model';
import {ImageService} from '../../../../../services/image.service';
import {MstiAgmPopupDialogComponent} from '../../../../../msti-agm/msti-agm-popup-dialog/msti-agm-popup-dialog.component';
import {CollectiveAddressTypeModel} from '../../../../../msti-agm/model/collective-address-type.model';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-address-dynamic-form-control',
  templateUrl: './address-dynamic-form-control.component.html',
  styleUrls: ['./address-dynamic-control.component.scss']
})
export class AddressDynamicFormControlComponent extends BaseDynamicFormControl<AddressTypeModel> implements AfterViewChecked {

  @ViewChild('addressInput', { static: false }) addressInput: ElementRef;
  collectiveAddressTypeModel = new CollectiveAddressTypeModel();
  mapIcon: string;

  private imageService: ImageService;
  private afterViewCheckedComplete: boolean;
  multipleAddressExists = false;
  streetNumber: FormControl;
  streetDesc: FormControl;
  lightStoneAddressError = false;

  constructor(
    injector: Injector
  ) {
    super(injector);
    this.imageService = injector.get(ImageService);
    this.afterViewCheckedComplete = false;


  }

  doInit() {
    this.mapIcon = this.imageService.getImage('MAP-ICON');
    if (this.dynamicControlModel.hidden) {
      this.streetNumber = new FormControl('', []);
      this.streetDesc = new FormControl('', []);
    } else {
      this.streetNumber = new FormControl('', [Validators.required]);
      this.streetDesc = new FormControl('', [Validators.required]);
    }

  }

  updateFormControlValue(inputValue: any) {
    // TODO - Implement me
  }

  clearValue() {
    this.dynamicControlModel.value = new AddressTypeModel(this.dynamicControlModel.value.addressType);

    if (this.form.controls['streetNumber' + this.dynamicControlModel.value.addressType]) {
      this.form.controls['streetNumber' + this.dynamicControlModel.value.addressType].reset();
      this.form.controls['streetNumber' + this.dynamicControlModel.value.addressType].setErrors(null);
    }

    if (this.form.controls['streetDesc' + this.dynamicControlModel.value.addressType]) {
      this.form.controls['streetDesc' + this.dynamicControlModel.value.addressType].reset();
      this.form.controls['streetDesc' + this.dynamicControlModel.value.addressType].setErrors(null);
    }

  }

  // NOTE: For some reason ngAfterViewInit doesn't work here
  ngAfterViewChecked() {

    if (!this.afterViewCheckedComplete && this.addressInput) {
      // NOTE: This is required for when we patch values
      this.form.addControl('streetNumber' + this.dynamicControlModel.value.addressType, this.streetNumber);
      this.form.addControl('streetDesc' + this.dynamicControlModel.value.addressType, this.streetDesc);
      if (
        this.form.get(this.dynamicControlModel.key).value &&
        this.form.get(this.dynamicControlModel.key).value !== this.dynamicControlModel.value
      ) {
        this.dynamicControlModel.value = this.form.get(this.dynamicControlModel.key).value;
      }

      this.afterViewCheckedComplete = true;
      this.cdRef.detectChanges();
    }

    if (this.addressInput) {
      const formControlReference = this.form.controls[this.dynamicControlModel.key];

      if (!this.dynamicControlModel.value.formattedAddressLine) {
        this.addressInput.nativeElement.value = '';
        formControlReference.setErrors({'addressRequired': true});
      } else {
        this.addressInput.nativeElement.value = this.dynamicControlModel.value.formattedAddressLine;
        this.streetNumber.setValue(this.dynamicControlModel.value.streetNo);
        this.streetDesc.setValue(this.dynamicControlModel.value.streetDesc);
        formControlReference.setErrors(null);
      }

    }
  }

  showMapPopup(model) {
    const dialogRef = this.dialog.open(MstiAgmPopupDialogComponent, {
      width: '52%',
      panelClass: 'agm-popup-dialog',
      data: {addressModel: model, collectiveAddressTypeModel: this.collectiveAddressTypeModel}
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
      // TODO - Confirm why this is required only in certain circumstances, or at all for that matter
      this.form.controls[this.dynamicControlModel.key].setValue(this.dynamicControlModel.value);
      // TODO-Ab - Do we need this here????
      this.setAddressDetails();

    }));

    dialogRef.afterClosed().subscribe(data => {
      this.setAddressDetails();
      this.multipleAddressExists = this.collectiveAddressTypeModel.multipleAddressExists;
    });

  }

  setAddressDetails() {
    if (this.collectiveAddressTypeModel.lightstoneAddressError) {
      this.dynamicControlModel.value.formattedAddressLine = null;
      this.lightStoneAddressError = true;
    } else {
      this.lightStoneAddressError = false;
      this.updateAddress(this.collectiveAddressTypeModel.addressTypeModels[0]);
      this.multipleAddressExists = this.collectiveAddressTypeModel.multipleAddressExists;
    }
  }

  updateAddress(address) {
    this.dynamicControlModel.value.estateName = address.estateName;
    this.dynamicControlModel.value.formattedAddressLine = address.formattedAddressLine;
    this.dynamicControlModel.value.municipalityCode = address.municipalityCode;
    this.dynamicControlModel.value.municipalityName = address.municipalityName;
    this.dynamicControlModel.value.postalCode = address.postalCode;
    this.dynamicControlModel.value.provinceCode = address.provinceCode;
    this.dynamicControlModel.value.provinceDesc = address.provinceDesc;
    this.dynamicControlModel.value.sectionSchemeName = address.sectionSchemeName;
    this.dynamicControlModel.value.streetCode = address.streetCode;
    this.dynamicControlModel.value.streetDesc = address.streetDesc;
    this.dynamicControlModel.value.streetNo = address.streetNo;
    this.dynamicControlModel.value.streetTypeDesc = address.streetTypeDesc;
    this.dynamicControlModel.value.suburbCode = address.suburbCode;
    this.dynamicControlModel.value.suburbDesc = address.suburbDesc;
    this.dynamicControlModel.value.unitNumber = address.unitNo;
    this.streetNumber.setValue(this.dynamicControlModel.value.streetNo);
    this.streetDesc.setValue(this.dynamicControlModel.value.streetDesc);
  }


  modelChanged4streetNo(newValue) {
    this.dynamicControlModel.value.streetNo = newValue;
  }
  modelChanged4streetDesc(newValue) {
    this.dynamicControlModel.value.streetDesc = newValue;
  }
}
