import {TotalPremium} from './helper/premium';
import {QuoteRiskPremium} from './helper/quote-risk-premium';
import {BrokerCommissionModel} from './brokerCommission.model';

export class CifQuoteSummaryResponseModel {
  totalPremium: TotalPremium;
  premiumRiskList: QuoteRiskPremium[];
  brokerCommission: BrokerCommissionModel;
}





