export class PifAgreementInfoModel {

  agreementProductType = new AgreementProductType(100);
}

export class AgreementProductType {
  productCode: number;

  constructor(productCode: number) {
    this.productCode = productCode;

  }

}
