import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifAddressInfoModel} from '../../../../personal/model/SaveClientModels/cif-address-info.model';
import {CifRegularDriverModel} from './cif/cif-regular-driver.model';

export class VehicleDriverDetailsModel {

  public vehicleIsRegularDriver: string;
  public vehicleRegularDriverIDNumber: string;
  public vehicleRegularDriverTitle: LookupModel;
  public vehicleRegularDriverFullName: string;
  public vehicleRegularDriverSurname: string;
  public vehicleRegularDriverGender: LookupModel;
  public vehicleRegularDriverMaritalStatus: LookupModel;
  public vehicleRegularDriverOccupation: LookupModel;
  public vehicleRegularDriverRelationshipToInsured: LookupModel;
  public vehicleRegularDriverContactNumber: string;
  public vehicleRegularDriverAddress: AddressTypeModel;
  public vehicleRegularDriverLicenseType: LookupModel;
  public vehicleRegularDriverLicenseLimitations: LookupModel;
  public vehicleRegularDriverLicenseDateIssued: string;
  public vehicleRegularDriverHasDrivingConvictions: string;

  constructor() {
  }

  populateFromFormData(formData) {
    this.vehicleIsRegularDriver = formData.vehicleIsRegularDriver;
    this.vehicleRegularDriverIDNumber = formData.vehicleRegularDriverIDNumber;
    this.vehicleRegularDriverTitle = formData.vehicleRegularDriverTitle;
    this.vehicleRegularDriverFullName = formData.vehicleRegularDriverFullName;
    this.vehicleRegularDriverSurname = formData.vehicleRegularDriverSurname;
    this.vehicleRegularDriverGender = formData.vehicleRegularDriverGender;
    this.vehicleRegularDriverMaritalStatus = formData.vehicleRegularDriverMaritalStatus;
    this.vehicleRegularDriverOccupation = formData.vehicleRegularDriverOccupation;
    this.vehicleRegularDriverRelationshipToInsured = formData.vehicleRegularDriverRelationshipToInsured;
    this.vehicleRegularDriverContactNumber = formData.vehicleRegularDriverContactNumber;
    this.vehicleRegularDriverAddress = formData.vehicleRegularDriverAddress;
    this.vehicleRegularDriverLicenseType = formData.vehicleRegularDriverLicenseType;
    this.vehicleRegularDriverLicenseLimitations = formData.vehicleRegularDriverLicenseLimitations;
    this.vehicleRegularDriverLicenseDateIssued = formData.vehicleRegularDriverLicenseDateIssued;
    this.vehicleRegularDriverHasDrivingConvictions = formData.vehicleRegularDriverHasDrivingConvictions;
  }

  async populateVehicleDriverFromCifModel(cifModel: CifRegularDriverModel, lookupServiceInstance: LookupService) {

    if (cifModel && cifModel.isPolicyHolder !== undefined) {
      if (cifModel.isPolicyHolder) {
        this.vehicleIsRegularDriver = '1';
      } else {
        this.vehicleIsRegularDriver = '0';
        this.vehicleRegularDriverIDNumber = cifModel.personal.personIdentity.identityValue;

        this.vehicleRegularDriverTitle = await lookupServiceInstance.getLookUpObjFromCode(
          cifModel.personal.titleCode,
          LookupService.GROUP_TITLE
        );

        this.vehicleRegularDriverFullName = cifModel.personal.firstName;
        this.vehicleRegularDriverSurname = cifModel.personal.lastName;

        this.vehicleRegularDriverGender = await lookupServiceInstance.getLookUpObjFromCode(
          cifModel.personal.genderCode,
          LookupService.GROUP_GENDER
        );

        this.vehicleRegularDriverMaritalStatus = await lookupServiceInstance.getLookUpObjFromCode(
          cifModel.personal.maritalStatusCode,
          LookupService.GROUP_MARITAL_STATUS
        );

        this.vehicleRegularDriverOccupation = await lookupServiceInstance.getLookUpObjFromCode(
          cifModel.personal.occupationCode,
          LookupService.GROUP_OCCUPATION
        );

        this.vehicleRegularDriverContactNumber = cifModel.contact.mobileNumber;

        this.vehicleRegularDriverAddress = this.readCifAddressModel(cifModel.addressList[0]);

        this.vehicleRegularDriverRelationshipToInsured = await lookupServiceInstance.getLookUpObjFromCode(
          cifModel.relationshipToInsuredCode,
          LookupService.GROUP_REGULAR_DRIVER_RELATED_INSURER
        );
      }

      this.vehicleRegularDriverLicenseType = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.driversLicense.driversLicenseCode,
        LookupService.GROUP_LICENSE_TYPE
      );

      // TODO - Shouldn't this be an array?
      this.vehicleRegularDriverLicenseLimitations = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.driversLicense.limitations[0],
        LookupService.GROUP_LICENSE_LIMITATIONS
      );


      // NOTE: the date format used is yyyy-mm-dd
      this.vehicleRegularDriverLicenseDateIssued = cifModel.driversLicense.issueDate;

      this.vehicleRegularDriverHasDrivingConvictions = cifModel.hasDrivingConviction ? '1' : '0';
    }
  }

  // TODO - This function is duplicated in the VehicleDriverDetailsModel class
  private readCifAddressModel(cifAddressInfoModel: CifAddressInfoModel): AddressTypeModel {
    const addressTypeModel = new AddressTypeModel(cifAddressInfoModel.addressType);
    addressTypeModel.unitNumber = cifAddressInfoModel.addressDetails.unitNo || '';
    addressTypeModel.sectionSchemeName = cifAddressInfoModel.addressDetails.sectSchemeName || '';
    addressTypeModel.estateCode = cifAddressInfoModel.addressDetails.estateCode || '';
    addressTypeModel.estateName = cifAddressInfoModel.addressDetails.estateName || '';
    addressTypeModel.streetCode = cifAddressInfoModel.addressDetails.streetCode || '';
    addressTypeModel.streetDesc = cifAddressInfoModel.addressDetails.streetDesc || '';
    addressTypeModel.streetTypeCode = cifAddressInfoModel.addressDetails.streetTypeCode || '';
    addressTypeModel.streetTypeDesc = cifAddressInfoModel.addressDetails.streetTypeDesc || '';
    addressTypeModel.streetNo = cifAddressInfoModel.addressDetails.streetNo || '';
    addressTypeModel.suburbCode = cifAddressInfoModel.addressDetails.suburbCode || '';
    addressTypeModel.suburbDesc = cifAddressInfoModel.addressDetails.suburbDesc || '';
    addressTypeModel.municipalityCode = cifAddressInfoModel.addressDetails.municipalityCode || '';
    addressTypeModel.municipalityName = cifAddressInfoModel.addressDetails.municipalityName || '';
    addressTypeModel.provinceCode = cifAddressInfoModel.addressDetails.provinceCode || '';
    addressTypeModel.provinceDesc = cifAddressInfoModel.addressDetails.provinceDesc || '';
    addressTypeModel.postalCode = cifAddressInfoModel.addressDetails.postalCode || '';
    addressTypeModel.poBoxNo = cifAddressInfoModel.addressDetails.poBoxNo || '';
    addressTypeModel.formatAddressLine();
    return addressTypeModel;
  }

  private pifToAddressTypeModel(pifAddress: any): AddressTypeModel {
    return Object.assign(new AddressTypeModel(''), pifAddress);

  }
}
