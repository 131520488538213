import {Component, Input, OnInit} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatDatepickerInputEvent} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/index';
import {map, startWith} from 'rxjs/internal/operators';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Moment} from 'moment';
import {LookupService} from '../../../shared/lookup/lookup.service';
import {DefaultLookupModel} from '../../../shared/lookup/default-lookup.model';
import {DisclosureService, DisclosureTags} from '../../services/disclosure.service';
import {PreviousClaimsModel} from '../../models/previous-claims.model';
import {LookupFilteredDropDownModel} from '../../../shared/lookup/lookup.filtered.drop.down.model';

@Component({
  selector: 'app-claim-details',
  templateUrl: './claim-details.component.html'
})
export class ClaimDetailsComponent implements OnInit {
  componentReference: any;
  @Input() claim: PreviousClaimsModel;
  @Input() calendarIcon: string;
  @Input() formGroup;
  riskTypeOptions: LookupFilteredDropDownModel;
  claimTypeOptions: LookupFilteredDropDownModel;
  claimTypeControl: FormControl;
  riskTypeControl: FormControl;
  dateOfClaimControl: FormControl;
  claimAmountControl: FormControl;

  claimFilteredOptions: Observable<DefaultLookupModel[]>;
  riskFilteredOptions: Observable<DefaultLookupModel[]>;
  isClaimInvalid = false;
  isRiskInvalid = false;
  errorMsg: string;
  maxDate = new Date();

  constructor(private disclosureService: DisclosureService, private lookupService: LookupService,
              private breakpointObserver: BreakpointObserver) {
  }

  async ngOnInit() {
    this.setFormControls();
    this.initializeLookUpData();
    await this.preFill();
  }

  // Check if device is touch device (phone or tablet)
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 800px)');
  }

  private setFormControls() {
    this.riskTypeControl = this.getFormControlName('riskTypeControl');
    this.claimTypeControl = this.getFormControlName('claimTypeControl');
    this.dateOfClaimControl = this.getFormControlName('dateOfClaimControl');
    this.claimAmountControl = this.getFormControlName('claimAmountControl');
  }

  private initializeLookUpData() {
    this.claimTypeOptions = this.disclosureService.claimTypeOptions;
    this.riskTypeOptions = this.disclosureService.riskTypeOptions;
    this.claimFilteredOptions = this.claimTypeControl.valueChanges
      .pipe(
        startWith<string | DefaultLookupModel>(''),
        map(value => typeof value === 'string' ? value : value.description),
        map(name => name ? this._filterClaim(name) : this.claimTypeOptions.values.slice())
      );
    this.riskFilteredOptions = this.riskTypeControl.valueChanges
      .pipe(
        startWith<string | DefaultLookupModel>(''),
        map(value => typeof value === 'string' ? value : value.description),
        map(name => name ? this._filterRisk(name) : this.riskTypeOptions.values.slice())
      );

  }

  private _filterClaim(value: string): DefaultLookupModel[] {
    const filterValue = value.toLowerCase();
    return this.claimTypeOptions.values.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  private _filterRisk(value: string): DefaultLookupModel[] {
    const filterValue = value.toLowerCase();
    return this.riskTypeOptions.values.filter(option => option.description.toLowerCase().includes(filterValue));
  }

  displayFn(option: DefaultLookupModel): DefaultLookupModel | string {
    // This method helps to display on the description part of the DefaultLookupModel
    return option ? option.description : option;
  }

  validateInput(input: string, value: any, options: LookupFilteredDropDownModel): boolean {
    if (value && options) {
      const dataList = options.values;
      if (!dataList.find(x => x.description.toLowerCase() === value.toLowerCase())) {
        return true; // Its invalid
      }
    }
    return false;
  }

  validateClaim(input: string, value: any) {
    this.isClaimInvalid = this.validateInput(input, value, this.claimTypeOptions);
    if (this.isClaimInvalid) {
      this.formGroup.controls[this.getClaimGroupName()].get('claimTypeControl').setErrors({'inValidOption': true});
    } else {
      this.formGroup.controls[this.getClaimGroupName()].get('claimTypeControl').setErrors(null);

    }
  }

  validateRisk(input: string, value: any) {
    this.isRiskInvalid = this.validateInput(input, value, this.riskTypeOptions);
    if (this.isRiskInvalid) {
      this.formGroup.controls[this.getClaimGroupName()].get('riskTypeControl').setErrors({'inValidOption': true});
    } else {
      this.formGroup.controls[this.getClaimGroupName()].get('riskTypeControl').setErrors(null);

    }
  }

  clearField(input) {
    input.target.value = '';
    input.target.setAttribute('ng-reflect-disclosureModel', '');
    input.target.dispatchEvent(new Event('input'));
    this.initializeLookUpData();
  }

  onRiskSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.claim.riskCode = event.option.value.code;
    this.isRiskInvalid = false;
  }

  onClaimSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.claim.typeOfClaimCode = event.option.value.code;
    this.isClaimInvalid = false;
  }

  dateChanged(event: MatDatepickerInputEvent<Moment>) {
    this.claim.dateOfClaim = event.value.format('YYYY-MM-DD').toString();
  }

  onClaimAmountChange() {
    this.claim.claimAmount = this.getFormControlName('claimAmountControl').value;
  }

  removeObject() {
    // NOTE: If claim history exists then change event to DELETE and remove from list
    if (this.claim.previousClaimHistoryId) {
      this.claim.event = DisclosureTags.DELETE;
    } else {
      for (let i = 0; i < this.disclosureService.getDisclosureQuestionsModel().previousClaims.length; i++) {
        if (this.disclosureService.getDisclosureQuestionsModel().previousClaims[i].claimId === this.claim.claimId) {
          this.disclosureService.getDisclosureQuestionsModel().previousClaims.splice(i, 1);
        }
      }
    }
    this.formGroup.removeControl('claimGroup' + this.claim.claimId); //  Remove the form from the form control
    this.validateAllFormFields(this.formGroup);
    this.componentReference.destroy();
  }

  async preFill() {
    if (this.claim.riskCode) {
      this.getFormControlName('riskTypeControl').setValue(await this.lookupService.getLookUpObjFromCode(this.claim.riskCode, LookupService.GROUP_RISK_TYPE));
    }

    if (this.claim.typeOfClaimCode) {
      this.getFormControlName('claimTypeControl').setValue(await this.lookupService.getLookUpObjFromCode(this.claim.typeOfClaimCode, LookupService.GROUP_CLAIM_TYPE));
    }

    this.getFormControlName('claimAmountControl').setValue(this.claim.claimAmount ? this.claim.claimAmount : '');
    this.getFormControlName('dateOfClaimControl').setValue(this.claim.dateOfClaim ? this.claim.dateOfClaim : '');

  }


  getFormControlName(controlName: string): FormControl {
    return this.formGroup.controls['claimGroup' + this.claim.claimId].get(controlName);
  }

  isFieldValid(field: string) {
    if (this.claim.claimId) {
      const fieldObj = this.formGroup.controls[this.getClaimGroupName()].get(field);
      if (fieldObj && fieldObj.errors) {
        if (fieldObj.touched === true && fieldObj.value === '') {
          this.errorMsg = 'This field is required';
          return true;
        } else {
          return false;
        }
      }
    }
  }

  getClaimGroupName() {
    return 'claimGroup' + this.claim.claimId;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
