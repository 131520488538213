import {CifContentStructureModel} from './cif-content-structure.model';
import {BaseCifRiskModel} from '../../../base/model/base-cif-risk.model';
import {PifRiskAddressModel} from '../../../../model/pif/pif-risk-address.model';

export class CifContentsModel extends BaseCifRiskModel {
// TODO Ab- do we need to exend basecif risk here???
  sectionCode: string;
  riskResourceRef: string;
  riskId: string;
  industryTypeCode: string;
  sumInsured: string;
  riskAddress: PifRiskAddressModel = new PifRiskAddressModel();
  isHolidayResidence: boolean;
  previousBurglaries: boolean;
  neighbourTypeCode: string;
  occupancyTypeCode: string;
  occupantTypeCode: string;
  structure: CifContentStructureModel;

  constructor() {
    super();
  }

}
