import {Component, Inject, OnInit} from '@angular/core';
import {ImageService} from '../../services/image.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import {AddressTypeModel} from '../model/address-type.model';
import {CollectiveAddressTypeModel} from '../model/collective-address-type.model';

@Component({
  selector: 'app-msti-agm-popup-dialog',
  templateUrl: './msti-agm-popup-dialog.component.html',
  styleUrls: ['./msti-agm-popup-dialog.component.scss']
})
export class MstiAgmPopupDialogComponent implements OnInit {

  addressModel: AddressTypeModel;
  collectiveAddressTypeModel: CollectiveAddressTypeModel;
  closeIcon: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private imageService: ImageService) {
    this.closeIcon = imageService.getImage('CLOSE-ICON');
    this.addressModel = data.addressModel;
    this.collectiveAddressTypeModel = data.collectiveAddressTypeModel;
  }

  ngOnInit() {
  }

}
