import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {RadioButtonGroupDynamicFormControlComponent} from './radiobutton-group-dynamic-form-control.component';
import {RadioGroupOption} from './radio-group-option.model';

export class RadioButtonGroupDynamicControlModel extends BaseDynamicControlModel<string> {

    options: RadioGroupOption[];

    constructor(controlModel?: Partial<RadioButtonGroupDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return RadioButtonGroupDynamicFormControlComponent;
    }

}
