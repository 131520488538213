import {LookupModel} from '../lookup/lookup.model';

export abstract class FilteredDropDownModel<T extends LookupModel> {

    public values: Array<T>;
    public filteredValues: Array<T>;

    public setValues(values: Array<T>): void {
        this.values = values;
        this.filteredValues = values;
    }

    filter(val: any) {
        if (this.values) {
            const filterValue = val.toString().toLowerCase();
            this.doFilter(filterValue);
        }
    }

    abstract doFilter(filterValue: string);

}
