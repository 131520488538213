import {AddressDetailsModel} from '../../../../../personal/model/SaveClientModels/address-details.model';

export class PifVehicleRegularDriverAddressDetailsModel {

  public unitNo: string;
  public streetNo: string;
  public streetCode: string;
  public streetDesc: string;
  public streetTypeDesc: string;
  public suburbCode: string;
  public municipalityName: string;
  public municipalityCode: string;
  public provinceCode: string;
  public provinceDesc: string;
  public suburbDesc: string;
  public estateName: string;
  public poBoxNo: string;
  public postalCode: string;

  static cifToPif(addressDetailsModel: AddressDetailsModel): PifVehicleRegularDriverAddressDetailsModel {
    const pifVehicleAddressDetailsModel = new PifVehicleRegularDriverAddressDetailsModel();
    pifVehicleAddressDetailsModel.unitNo = addressDetailsModel.unitNo;
    pifVehicleAddressDetailsModel.streetNo = addressDetailsModel.streetNo;
    pifVehicleAddressDetailsModel.streetCode = addressDetailsModel.streetCode;
    pifVehicleAddressDetailsModel.streetDesc = addressDetailsModel.streetDesc;
    pifVehicleAddressDetailsModel.streetTypeDesc = addressDetailsModel.streetTypeDesc;
    pifVehicleAddressDetailsModel.suburbCode = '' + addressDetailsModel.suburbCode;
    pifVehicleAddressDetailsModel.municipalityName = addressDetailsModel.municipalityName;
    pifVehicleAddressDetailsModel.municipalityCode = '' + addressDetailsModel.municipalityCode;
    pifVehicleAddressDetailsModel.provinceCode = '' +  addressDetailsModel.provinceCode;
    pifVehicleAddressDetailsModel.provinceDesc = addressDetailsModel.provinceDesc;
    pifVehicleAddressDetailsModel.suburbDesc = addressDetailsModel.streetDesc;
    pifVehicleAddressDetailsModel.estateName = addressDetailsModel.estateName;
    pifVehicleAddressDetailsModel.poBoxNo = addressDetailsModel.poBoxNo;
    pifVehicleAddressDetailsModel.postalCode = addressDetailsModel.postalCode;
    return pifVehicleAddressDetailsModel;
  }

}
