import {Component, Injector} from '@angular/core';
import {VehicleDetailsService} from '../../service/vehicle-details.service';
import {BaseRiskTabComponent} from '../../../base/component/base-risk-tab.component';
import {BaseRiskTabService} from '../../../base/service/base-risk-tab.service';
import {DigitalAnalyticsPageModel} from '../../../../../shared/digital-analytics/model/digital-analytics-page.model';
import {GoogleAnalyticsEventModel} from '../../../../../shared/google-analytics/model/google-analytics-event.model';

@Component({
    selector: 'app-vehicle-details',
    templateUrl: './vehicle-details.component.html',
    styleUrls: ['./vehicle-details.component.scss'],
    providers: [VehicleDetailsService]
})
export class VehicleDetailsComponent extends BaseRiskTabComponent {

    constructor(
        injector: Injector,
        private vehicleDetailsService: VehicleDetailsService
    ) {
        super(injector);
    }

    getRiskTabService(): BaseRiskTabService {
        return this.vehicleDetailsService;
    }

    initAnalytics(): void {
        this.subscriptions.push(
            this.tabLoadedEventEmitter.subscribe(() => {
                const attributes: Map<number, string> = new Map<number, string>();
                this.digitalAnalyticsService.constructAndFirePageViewTag(
                    DigitalAnalyticsPageModel.MSTI_VEHICLE_DETAILS_PAGE_ID,
                    attributes
                );
            })
        );
    }

    doComponentInit(): void {
        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('physicalAddressIsNightAddress').valueChanges.subscribe(
                selectedValue => {
                    this.vehicleDetailsService.isPhysicalSameAsNightAddressChanged(selectedValue);
                }
            )
        );

        this.subscriptions.push(
            this.cardTabContainerComponent.dynamicFormComponent.form.get('physicalAddressIsDayAddress').valueChanges.subscribe(
                selectedValue => {
                    this.vehicleDetailsService.isPhysicalSameAsDayAddressChanged(selectedValue);
                }
            )
        );
    }

    propagatePostLoadUpdates(): void { }

    getGoogleAnalyticsEventLabel(): string {
        return GoogleAnalyticsEventModel.EVENT_COMPLETE_VEHICLE_DETAILS_ON_VEHICLE_COVER;
    }

}
