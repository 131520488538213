import {LookupModel} from '../../../../shared/lookup/lookup.model';
import {LookupService} from '../../../../shared/lookup/lookup.service';
import {CifContentsModel} from './cif/cif-contents.model';
import {AddressTypeModel} from '../../../../shared/msti-agm/model/address-type.model';
import {CifRiskAddressModel} from '../../../model/cif/cif-risk-address.model';
import {AddressLookupModel} from '../../../../shared/lookup/address-lookup.model';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

export class ContentsDetailsModel {

  constructor() {
  }

  sectionCode: string;
  industryTypeCode: string;
  contentsAmount: string;
  contentsBurglaries: string;
  contentsAreaType: LookupModel;
  contentsNeighbourhood: LookupModel;
  contentsPropertyOccupation: LookupModel;
  contentsPropertyOccupants: LookupModel;
  contentsAddressSameAsPhysical: string;
  addressSelection: AddressLookupModel;
  contentsAddress: AddressTypeModel;

  populateFromFormData(formData) {
    this.industryTypeCode = null;
    this.contentsAmount = formData.contentsAmount;
    this.contentsBurglaries = formData.contentsBurglaries;
    this.contentsAreaType = formData.contentsAreaType;
    this.contentsNeighbourhood = formData.contentsNeighbourhood;
    this.contentsPropertyOccupants = formData.contentsPropertyOccupants;
    this.contentsPropertyOccupation = formData.contentsPropertyOccupation;
    this.contentsAddressSameAsPhysical = formData.contentsAddressSameAsPhysical;

    // TODO - This logic is duplicated in the buildingRisks details model
    if (this.contentsAddressSameAsPhysical === '0') {
      if (
        formData.contentsAddress &&
        formData.contentsAddress.formattedAddressLine
      ) {
        this.contentsAddress = formData.contentsAddress;
      } else if (
        formData.addressSelection &&
        formData.addressSelection.value &&
        formData.addressSelection.value.formattedAddressLine !== 'Add new address'
      ) {
        this.contentsAddress = formData.addressSelection.value;
      } else {
        // TODO - We should never get here, but what if we do?
      }
    } else {
      this.contentsAddress = null;
    }
  }

  async populateContentFromCifModel(cifModel: CifContentsModel, lookupServiceInstance: LookupService) {
    if (cifModel) {
      this.contentsAmount = cifModel.sumInsured;
      this.contentsAreaType = await lookupServiceInstance.getLookUpObjFromCode(cifModel.riskAddress.areaTypeCode,
        LookupService.GROUP_CONTENTS_AREA_TYPE
      );
      this.contentsNeighbourhood = await lookupServiceInstance.getLookUpObjFromCode(cifModel.neighbourTypeCode,
        LookupService.GROUP_CONTENTS_NEIGHBOUR
      );
      this.contentsPropertyOccupation = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.occupancyTypeCode, LookupService.GROUP_CONTENTS_OCCUPANCY
      );
      this.contentsPropertyOccupants = await lookupServiceInstance.getLookUpObjFromCode(
        cifModel.occupantTypeCode, LookupService.GROUP_CONTENTS_OCCUPIED_BY
      );
      this.contentsBurglaries = cifModel.previousBurglaries ? '1' : '0';
      this.contentsAddressSameAsPhysical = cifModel.riskAddress.defaultToPhysicalAddress ? '1' : '0';
      this.contentsAddress = PifRiskAddressModel.piftoAddressTypeModel(cifModel.riskAddress);

    }
  }

  // TODO - This code is duplicated in the other risks
  private readCifAddressModel(cifRiskAddressModel: CifRiskAddressModel): AddressTypeModel {
    const addressTypeModel = new AddressTypeModel(cifRiskAddressModel.addressType);
    addressTypeModel.unitNumber = cifRiskAddressModel.unitNumber || '';
    addressTypeModel.sectionSchemeName = cifRiskAddressModel.sectionSchemeName || '';
    addressTypeModel.estateCode = cifRiskAddressModel.estateCode || '';
    addressTypeModel.estateName = cifRiskAddressModel.estateName || '';
    addressTypeModel.streetCode = cifRiskAddressModel.streetCode || '';
    addressTypeModel.streetDesc = cifRiskAddressModel.streetDesc || '';
    addressTypeModel.streetTypeCode = cifRiskAddressModel.streetTypeCode || '';
    addressTypeModel.streetTypeDesc = cifRiskAddressModel.streetTypeDesc || '';
    addressTypeModel.streetNo = cifRiskAddressModel.streetNumber || '';
    addressTypeModel.suburbCode = cifRiskAddressModel.suburbCode || '';
    addressTypeModel.suburbDesc = cifRiskAddressModel.suburbDesc || '';
    addressTypeModel.municipalityCode = cifRiskAddressModel.municipalityCode || '';
    addressTypeModel.municipalityName = cifRiskAddressModel.municipalityDesc || '';
    addressTypeModel.provinceCode = cifRiskAddressModel.provinceCode || '';
    addressTypeModel.provinceDesc = cifRiskAddressModel.provinceDesc || '';
    addressTypeModel.postalCode = cifRiskAddressModel.postalCode || '';
    addressTypeModel.poBoxNo = cifRiskAddressModel.poBoxNumber || '';
    addressTypeModel.formatAddressLine();
    return addressTypeModel;
  }

}
