import {Logger} from './logger';
import {Level} from './logger-level';
import {Injectable} from '@angular/core';
import {MSTIConfiguration} from '../msti.config';

// declare var $: any;

@Injectable()
export class UiUtilsService {

    constructor() {
        window.addEventListener('resize', (event) => {
            this.addClassToLongLabels();
        });
    }

    // Cover Type Title config
    readonly DEVICE_SCREEN_SIZE = {
        xxs: 320,
        xs: 480,
        sm: 600,
        md: 800,
        lg: 1200,
        xl: 1440
    };

    // ----- autoGrowTextElement ----- //
    autoGrowTextElement(textAreaID: string) {
        if (MSTIConfiguration.constants.enableAutoGrowText === true) {
            const textArea = document.getElementById(textAreaID);
            textArea.style.overflow = 'hidden';
            textArea.style.height = '0px';
            textArea.style.height = textArea.scrollHeight + 'px';
            Logger.log(Level.LOG, 'Auto Grow Text Element:', textAreaID);
        }
    }

    // ----- drag marketing Carousel down once 'Return to Portfolio' button sits under the momentum Logo ----- //
    giveAppWrapperMargin() {
        const navbar = document.getElementById('navbar');
        const mstiApp = document.getElementById('msti-app-wrapper');
        if (navbar.clientHeight > 100) {
            mstiApp.style.marginTop = '78px';
        } else {
            mstiApp.style.marginTop = '0px';
        }
    }

    // ----- getElementParent ----- //
    getElementParent(elem): HTMLElement {
        if (elem.parentNode && elem !== document.body) {
            elem = elem.parentNode;
            Logger.log(Level.LOG, 'Get Parent Element:', elem);
            return elem;
        }
    }

    // ----- showElements ----- //
    showElements(selectors): void {
        const elements = document.querySelectorAll(selectors);
        if (elements && elements.length > 0) {
            elements.forEach(function (element) {
                element.style.display = 'block';
                Logger.log(Level.LOG, 'Show Elements:', element);

            });
        }
    }

    // ----- hideElements ----- //
    hideElements(selectors): void {
        const elements = document.querySelectorAll(selectors);
        if (elements && elements.length > 0) {
            elements.forEach(function (element) {
                element.style.display = 'none';
                Logger.log(Level.LOG, 'Hide Elements:', element);
            });
        }
    }

    // ----- addClass ----- //
    addClass(selector, className) {
        const element = document.querySelector(selector);
        if (element) {
            element.classList.add(className);
        }
    }

    // ----- removeClass ----- //
    removeClass(selector, className) {
        const element = document.querySelector(selector);
        if (element) {
            element.classList.remove(className);
        }
    }

    // ----- add class names for Dynamic controls with labels containing lengthy text ----- //
    addClassToLongLabels() {
        if (MSTIConfiguration.constants.enableAutoGrowText === true) {
            const selector = '.dynamic-form-control-container .mat-form-field-label-wrapper label, .mat-dialog-content .mat-form-field-label-wrapper label';
            $(selector).each(function () {
                // toggle class to label
                $(this).toggleClass('label-1-line', $(this).height() === 20);
                // add margin top to input
                if ($(this).height() === 20) {
                    $(this).parent().parent().find('.mat-input-element').css('margin-top', '0px');
                }

                // toggle class to label
                $(this).toggleClass('label-2-line', $(this).height() === 40);
                // add margin top to input
                if ($(this).height() === 40) {
                    $(this).parent().parent().find('.mat-input-element').css('margin-top', '25px');
                }

                // toggle class to label
                $(this).toggleClass('label-3-line', $(this).height() === 60);
                // add margin top to input
                if ($(this).height() === 60) {
                    $(this).parent().parent().find('.mat-input-element').css('margin-top', '45px');
                }

                // toggle class to label
                $(this).toggleClass('label-4-line', $(this).height() === 80);
                // add margin top to input
                if ($(this).height() === 80) {
                    $(this).parent().parent().find('.mat-input-element').css('margin-top', '60px');
                }
            });
        }
    }

    private getElementOffset(element) {
        const de = document.documentElement;
        const box = element.getBoundingClientRect();
        const top = box.top + window.pageYOffset - de.clientTop;
        const left = box.left + window.pageXOffset - de.clientLeft;
        const offsetHeight = box.height;

        return { top: top, left: left, offsetHeight: offsetHeight };
    }

    parallaxEffectOnScroll() {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
        const scrollBarPosition = supportPageOffset ?
            window.pageYOffset : isCSS1Compat ?
                document.documentElement.scrollTop : document.body.scrollTop;
        const parallaxDiv = document.getElementsByClassName('parallax');
        for (let i = 0; i < parallaxDiv.length; ++i) {
            const singleParallaxDiv = parallaxDiv[i];
            const parallaxImageSelector = singleParallaxDiv.querySelector('img');
            const scrollSpeed = this.getElementOffset(singleParallaxDiv).top / 3;

            parallaxImageSelector.style.top = (scrollBarPosition / 3) - scrollSpeed + 'px';
        }

        const parallaxDiv2 = document.getElementsByClassName('parallax2');
        for (let i = 0; i < parallaxDiv2.length; ++i) {
            const singleParallaxDiv = parallaxDiv2[i];
            const parallaxImageSelector = singleParallaxDiv.querySelector('img');
            const scrollSpeed = this.getElementOffset(singleParallaxDiv).top / 3;
            parallaxImageSelector.style.top = (scrollBarPosition / 3) - scrollSpeed + 'px';
        }

        const parallaxDiv3 = document.getElementsByClassName('parallax3') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < parallaxDiv3.length; ++i) {
            const singleParallaxDiv = parallaxDiv3[i];
const scrolledHeight = window.pageYOffset;
            const limit = this.getElementOffset(singleParallaxDiv).top + this.getElementOffset(singleParallaxDiv).offsetHeight;

            if (scrolledHeight > this.getElementOffset(singleParallaxDiv).top && scrolledHeight <= limit) {
                singleParallaxDiv.style.backgroundPositionY =
                    (scrolledHeight - this.getElementOffset(singleParallaxDiv).top) / 1.5 + 'px';
            } else {
                singleParallaxDiv.style.backgroundPositionY = '0';
            }

        }

    }

}
