import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {CardComponent} from '../card/card.component';
import {CardTabContainerComponent} from './card-tab-container/card-tab-container.component';

@NgModule({
    declarations: [
        CardComponent,
        CardTabContainerComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        CardComponent,
        CardTabContainerComponent
    ],
    providers: []
})
export class CardModule {
}
