import {Injectable, OnDestroy} from '@angular/core';
import {MSTIService} from '../../shared/services/msti.service';
import {Logger} from '../../shared/utilities/logger';
import {Level} from '../../shared/utilities/logger-level';
import {CifQuoteSaveResponseModel} from '../model/cif/cif-quote-save-response.model';
import {CifQuoteModel} from '../model/cif/cif-quote.model';
import {QuoteUrlService} from './quote-url.service';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {CifQuoteSummaryResponseModel} from '../model/cif/cif-quote-summary-response.model';

@Injectable()
export class QuoteService implements OnDestroy {

  public quoteId = '';
  public quoteDisplayNumber = '';
  public previousQuoteLoaded = false;

  private quoteSaveResponseModel: CifQuoteSaveResponseModel;
  private quoteSummaryResponseModel: CifQuoteSummaryResponseModel;

  constructor(
    private mstiService: MSTIService,
    private quoteUrlService: QuoteUrlService,
    private loaderService: LoaderService,
    private mstiErrorService: MstiErrorService
  ) {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public destroy() {
    this.quoteId = '';
    this.quoteDisplayNumber = '';
    this.previousQuoteLoaded = false;
    this.quoteSaveResponseModel = null;
  }

  public async saveQuote(quoteModel: CifQuoteModel): Promise<CifQuoteSaveResponseModel> {
    this.loaderService.show();

    await this.mstiService.postWithAbAuth(this.quoteUrlService.getAddQuoteURL(), quoteModel).toPromise().then((response: any) => {
      this.quoteSaveResponseModel = Object.assign(new CifQuoteSaveResponseModel(), response);
      // this.quoteId = this.quoteSaveResponseModel.quoteInfo.quoteId;
      // this.quoteId = response[0].agreementInformation.agreementResourceRef;
      // this.quoteDisplayNumber = response[0].agreementInformation.agreementNumber;
      this.quoteId = this.quoteSaveResponseModel.agreementInformation.agreementResourceRef;
      this.quoteDisplayNumber = this.quoteSaveResponseModel.agreementInformation.agreementNumber;
      Logger.log(Level.LOG, 'Save quote response:', this.quoteSaveResponseModel);
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while saving quote', error);
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });

    return this.quoteSaveResponseModel;
  }

  async getQuote(): Promise<CifQuoteSaveResponseModel> {
    if (!this.quoteSaveResponseModel && this.quoteId) {
      this.loaderService.show();

      await this.mstiService.getWithAbAuth(this.quoteUrlService.getGetQuoteURL(this.quoteId)).toPromise().then(response => {
        if (response.agreementInformation) {
          this.quoteSaveResponseModel = Object.assign(new CifQuoteSaveResponseModel(), response);
          this.quoteDisplayNumber = this.quoteSaveResponseModel.agreementInformation.agreementNumber;
        }

        Logger.log(Level.LOG, 'Quote retrieve response:', this.quoteSaveResponseModel);
      }, (error) => {
        this.loaderService.hide();
        Logger.log(Level.ERROR, `Error retrieving existing quote for quoteId: ${this.quoteId}`, error);
        this.mstiErrorService.handleError(error);
      }).then(() => {
        this.loaderService.hide();
      });
    }

    this.previousQuoteLoaded = true;

    return this.quoteSaveResponseModel;
  }

  async getPremiumSummary(): Promise<CifQuoteSummaryResponseModel> {
    // Note: The loader service is not used within this function as the only calling function already uses a custom loader
    if (this.quoteId) {
      await this.mstiService.getWithAbAuth(this.quoteUrlService.getGetPremiumSummaryURL(this.quoteId)).toPromise().then(response => {
        this.quoteSummaryResponseModel = Object.assign(new CifQuoteSummaryResponseModel(), response);
        Logger.log(Level.LOG, 'Quote summary retrieve response:', this.quoteSummaryResponseModel);
      }, (error) => {
        Logger.log(Level.ERROR, `Error retrieving existing premium summary for quoteId: ${this.quoteId}`, error);
        this.mstiErrorService.handleError(error);
      });
    }

    return this.quoteSummaryResponseModel;
  }

  getQuoteSummaryResponseModel(): CifQuoteSummaryResponseModel {
    return this.quoteSummaryResponseModel;
  }

}
