import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatRadioChange} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PreviousClaimsModel} from '../models/previous-claims.model';
import {ImageService} from '../../shared/services/image.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {ClaimDetailsComponent} from './claim-details/claim-details.component';
import {DisclosureService, DisclosureTags} from '../services/disclosure.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {Level} from '../../shared/utilities/logger-level';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {Logger} from '../../shared/utilities/logger';


@Component({
  selector: 'app-disclosure-capture-claim',
  templateUrl: './disclosure-capture-claim.component.html'
})
export class DisclosureCaptureClaimComponent implements OnInit, OnDestroy {

  @ViewChild('container', {read: ViewContainerRef, static: true}) container: ViewContainerRef;
  @Input() parentFormGroup;
  calendarIcon: any;

  displayAdditionClaims: string;

  previousClaimsModel: PreviousClaimsModel[];
  riskTypeControl;
  claimTypeControl;
  dateOfClaimControl;
  claimAmountControl;
  claimChildForm;

  constructor(
    imageService: ImageService,
    private disclosureService: DisclosureService,
    private fb: FormBuilder, private digitalAnalyticsService: DigitalAnalyticsService,
    private _cfr: ComponentFactoryResolver,
    private needHelpService: NeedHelpService
  ) {
    this.calendarIcon = imageService.getImage('CALENDAR-ICON');
    this.previousClaimsModel = this.disclosureService.getDisclosureQuestionsModel().previousClaims;
  }

  ngOnInit() {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CAPTURE_CLAIMS_DETAILS);
    this.setAnalytics();
    if (this.previousClaimsModel.length === 0) {
      this.createNewClaimComponent();
    } else {

      for (let i = 0; i < this.previousClaimsModel.length; i++) {
        if (this.previousClaimsModel[i].origin === 'User Entered Claim') {
          this.displayAdditionClaims = 'Yes';
          const claim = this.previousClaimsModel[i];
          if (claim.event !== DisclosureTags.DELETE) {
            const comp = this._cfr.resolveComponentFactory(ClaimDetailsComponent);
            const expComponent = this.container.createComponent(comp);
            expComponent.instance.componentReference = expComponent;
            expComponent.instance.calendarIcon = this.calendarIcon;
            claim.claimId = i;
            expComponent.instance.claim = claim;
            expComponent.instance.formGroup = this.setFormControls(claim.claimId);
            if (claim.previousClaimHistoryId) {
              claim.event = DisclosureTags.UPDATE;
            }

          }
        } else {
          this.previousClaimsModel[i].event = DisclosureTags.UPDATE;
        }

      }
    }
  }

  private setAnalytics() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_PREVIOUS_CLAIM_DETAILS_PAGE_ID, attributes);
  }

  addComponent() {
    Logger.log(Level.LOG, 'claimForm:', this.parentFormGroup);
    this.createNewClaimComponent();
  }

  createNewClaimComponent() {
    const comp = this._cfr.resolveComponentFactory(ClaimDetailsComponent);
    const expComponent = this.container.createComponent(comp);
    const claim = new PreviousClaimsModel(this.previousClaimsModel.length, 'CREATE', 'User Entered Claim');
    this.previousClaimsModel.push(claim);
    expComponent.instance.claim = this.previousClaimsModel[claim.claimId];
    expComponent.instance.componentReference = expComponent;
    expComponent.instance.calendarIcon = this.calendarIcon;
    expComponent.instance.formGroup = this.setFormControls(claim.claimId);
  }

  setFormControls(id: number): FormGroup {
    this.riskTypeControl = new FormControl('', Validators.required);
    this.claimTypeControl = new FormControl('', Validators.required);
    this.dateOfClaimControl = new FormControl('', Validators.required);
    this.claimAmountControl = new FormControl('', Validators.required);
    this.claimChildForm = new FormGroup({});
    this.claimChildForm.addControl('claimTypeControl', this.claimTypeControl);
    this.claimChildForm.addControl('riskTypeControl', this.riskTypeControl);
    this.claimChildForm.addControl('dateOfClaimControl', this.dateOfClaimControl);
    this.claimChildForm.addControl('claimAmountControl', this.claimAmountControl);
    this.parentFormGroup.addControl('claimGroup' + id, this.claimChildForm);
    return this.parentFormGroup;
  }


  ngOnDestroy() {
    if (this.displayAdditionClaims === 'Yes') {
      this.riskTypeControl.setValidators(null);
      this.riskTypeControl.updateValueAndValidity();
      this.claimTypeControl.setValidators(null);
      this.claimTypeControl.updateValueAndValidity();
      this.dateOfClaimControl.setValidators(null);
      this.dateOfClaimControl.updateValueAndValidity();
      this.claimAmountControl.setValidators(null);
      this.claimAmountControl.updateValueAndValidity();

    }

  }

}
