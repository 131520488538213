import {GetAdviceDataModel} from './GetAdviceDataModel';
import {GetClientIdentityModel} from './GetClientIdentityModel';

export class GetAdviceModel {

    public adviceReason: string;
    public adviceStageCode: string;
    public contactForAdvice: Boolean; // this can be null , true or false
    public adviceData: GetAdviceDataModel;
    public clientIdentity: GetClientIdentityModel;
}
