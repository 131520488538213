import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {RiskOptionService} from '../../shared/risk/risk-option.service';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsConversionModel} from '../../shared/digital-analytics/model/digital-analytics-conversion.model';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {RoutingService} from '../../shared/services/routing.service';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {UiUtilsService} from '../../shared/utilities/ui-utils.service';

@Component({
    selector: 'app-marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MarketingComponent implements OnInit {

    @Input() isCallCentreQuotable: boolean;

    showError: boolean;
    enableGetAQuoteButton = false;

    private quoteConversionTagFired: boolean;

    constructor(
        private riskOptionService: RiskOptionService,
        private digitalAnalyticsService: DigitalAnalyticsService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private needHelpService: NeedHelpService,
        private routingService: RoutingService,
        private uiUtilsService: UiUtilsService
    ) { }

    ngOnInit() {
        this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_MARKETING);
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_MARKETING_PAGE_ID, attributes);

        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_START_QUOTE,
            GoogleAnalyticsEventModel.EVENT_START_WITH_THE_QUOTE_ON_THE_MARKETING_PAGE
        );

        this.quoteConversionTagFired = false;

        if (this.isCallCentreQuotable) {
            this.uiUtilsService.hideElements('#footerHelpLink');
        } else {
            this.uiUtilsService.showElements('#footerHelpLink');
        }
    }

    getQuote() {
        const attributes: Map<number, string> = new Map<number, string>();

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_PERSONAL_INFO,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_BEGIN,
            attributes);

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_DISCLOSURES,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_BEGIN,
            attributes);

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_COVER_DETAILS,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_BEGIN,
            attributes);

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_SUBMIT_QUOTE,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_BEGIN,
            attributes);

        attributes.set(1, this.riskOptionService.getSelectedRiskOptions().map((riskOption) => {
            return riskOption.quoteDescription;
        }).join(' | '));

        this.digitalAnalyticsService.constructAndFireConversionEventTag(
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_SELECT_COVER,
            DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE,
            attributes);

        if (this.riskOptionService.isSingleRisk()) {
            this.riskOptionService.selectOnlyAvailableRiskOption();
            this.routingService.route('/app-personal');
        } else {
            this.showError = this.riskOptionService.isNoRiskSelected();
            if (!this.showError) {
                this.routingService.route('/app-personal');
            }
        }
    }

    onRiskSelected() {
        if (!this.quoteConversionTagFired) {
            const attributes: Map<number, string> = new Map<number, string>();
            this.digitalAnalyticsService.constructAndFireConversionEventTag(
                DigitalAnalyticsConversionModel.PAGE_CONVERSION_SELECT_COVER,
                DigitalAnalyticsConversionModel.PAGE_CONVERSION_BEGIN,
                attributes);

            this.quoteConversionTagFired = true;
        }

        this.showError = this.riskOptionService.isNoRiskSelected();
        this.enableGetAQuoteButton = !this.showError;
    }

}
