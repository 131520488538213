import {Component, OnInit} from '@angular/core';
import {QuoteConfig} from '../quote.config';
import {RiskOptionService} from '../../shared/risk/risk-option.service';
import {RiskOptionModel} from '../../shared/risk/risk.option.model';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsConversionModel} from '../../shared/digital-analytics/model/digital-analytics-conversion.model';
import {QuoteService} from '../service/quote.service';
import {RiskOptionDataService} from '../../shared/risk/risk-options-data.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {RoutingService} from '../../shared/services/routing.service';
import {MSTIService} from '../../shared/services/msti.service';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  quotePageHeading = QuoteConfig.HEADING;
  quotePageSubHeading = QuoteConfig.SUB_HEADING;
  selectedRiskOptions: RiskOptionModel[];
  vehicleRiskIds: string[] = Array();
  contentsRiskIds: string[] = Array();
  buildingRiskIds: string[] = Array();
  isQuoteFetchComplete = false;

  private cardsStatusMap: Map<string, CardStatus> = new Map<string, CardStatus>();

  constructor(
    private riskOptionService: RiskOptionService,
    private digitalAnalyticsService: DigitalAnalyticsService,
    private quoteService: QuoteService,
    private needHelpService: NeedHelpService,
    private routingService: RoutingService,
    private mstiService: MSTIService,
    private userService: BrokerDetailsService,
    private mstiErrorService: MstiErrorService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  async ngOnInit() {
    /*  if (this.newUserService.isPreloginUser === true) {// refresh pif token as token expires in 30 mins
          this.mstiService.getBrokerToken(this.preloginUrlService.getPreloginTokenUrl()).subscribe((response: any) => {
              const token = response.token;
              this.userService.getUser().cifToken = token;
          }, (error) => {
              this.mstiErrorService.handleError(error);

          });
      }*/

    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_COVER_DETAILS_PAGE_ID, attributes);

    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_COVER_DETAILS);

    this.orderSelectedRiskOption();

    if (this.quoteService.previousQuoteLoaded === false) {
      await this.getExistingRiskIds();
    } else {
      this.isQuoteFetchComplete = true;
    }
  }

  orderSelectedRiskOption() {
    const context = this;
    context.riskOptionService.getSelectedRiskOptions().forEach(function (riskOption, index) {
      context.riskOptionService.setSpecificRiskOptionOrder(riskOption.name, index + 1);
    });
  }

  isRiskOptionSelected(riskName: string) {
    const filteredRiskOptions = this.riskOptionService.getSelectedRiskOptions().filter(riskOption => {
      return riskOption.name.toLowerCase() === riskName.toLowerCase();
    });

    return filteredRiskOptions.length > 0 && this.isQuoteFetchComplete;
  }

  getRiskOptionOrderIndex(riskName: string) {
    return this.riskOptionService.getSpecificRiskOptionOrder(riskName);
  }

  onCoverSelected() {
    // TODO - Implement based on future spec
  }

  onCoverDetailsBack() {
    this.routingService.route('app-disclosure');
  }

  onCoverDetailsNext() {
    if (this.areAllCardsComplete) {
      const attributes: Map<number, string> = new Map<number, string>();
      this.digitalAnalyticsService.constructAndFireConversionEventTag(
        DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE_COVER_DETAILS,
        DigitalAnalyticsConversionModel.PAGE_CONVERSION_COMPLETE,
        attributes);

      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_PROCEED_TO_PREMIUM_SUMMARY
      );

      this.routingService.route('/app-admin-fee');
    }
  }

  private async getExistingRiskIds() {
    const existingQuote = await this.quoteService.getQuote();

    // Note: the quote object object returned must have quote information in order to be processed
    if (existingQuote && existingQuote.riskReferences && existingQuote.riskReferences.length > 0) {
      for (const risk of existingQuote.riskReferences) {
        const sectionCode = risk.sectionCode;
        switch (sectionCode) {
          case '1001': // Vehicle
            this.riskOptionService.selectSpecificRiskOption(RiskOptionDataService.RISK_NAME_VEHICLE);
            this.vehicleRiskIds.push(risk.riskResourceRef);
            break;
          case '3002': // Content
            this.riskOptionService.selectSpecificRiskOption(RiskOptionDataService.RISK_NAME_CONTENTS);
            this.contentsRiskIds.push(risk.riskResourceRef);
            break;
          case '3001': // Building
            this.riskOptionService.selectSpecificRiskOption(RiskOptionDataService.RISK_NAME_BUILDING);
            this.buildingRiskIds.push(risk.riskResourceRef);
            break;
        }
      }
    } else if (this.riskOptionService.isNoRiskSelected()) {
      this.riskOptionService.selectAllAvailableRiskOptions();
    }
    this.isQuoteFetchComplete = true;
  }

  onAllCardsComplete(value) {
    this.cardsStatusMap.set(value.section, new CardStatus(value.areAllCardsComplete, value.numberOfCards));
  }

  isSelectNewCoverSectionEnabled() {
    return this.riskOptionService.availableRiskOptions.some(option => option.isSelected === false) && this.isQuoteFetchComplete;
  }

  get vehicleRiskName(): string {
    return RiskOptionDataService.RISK_NAME_VEHICLE;
  }

  get contentsRiskName(): string {
    return RiskOptionDataService.RISK_NAME_CONTENTS;
  }

  get buildingRiskName(): string {
    return RiskOptionDataService.RISK_NAME_BUILDING;
  }

  get areAllCardsComplete(): boolean {
    let areAllCardsComplete = true;
    let totalNumberOfCards = 0;

    Array.from(this.cardsStatusMap.keys()).forEach(section => {
      areAllCardsComplete = areAllCardsComplete && this.cardsStatusMap.get(section).areAllCardsComplete;
      totalNumberOfCards += this.cardsStatusMap.get(section).numberOfCards;
    });

    return areAllCardsComplete && totalNumberOfCards > 0;
  }

}

// TODO - This class should probably be defined in its own file
class CardStatus {

  constructor(
    public areAllCardsComplete: boolean,
    public numberOfCards: number
  ) {
  }

}
