import {Injector, OnDestroy} from '@angular/core';
import {MSTIService} from '../../../../shared/services/msti.service';
import {PersonalService} from '../../../../personal/service/personal.service';
import {Logger} from '../../../../shared/utilities/logger';
import {Level} from '../../../../shared/utilities/logger-level';
import {QuoteService} from '../../../service/quote.service';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {MstiErrorService} from '../../../../shared/msti-error-handler/msti-error.service';
import {CardModel} from '../../../card/model/card.model';
import {BaseCifRiskModel} from '../model/base-cif-risk.model';
import {BaseRiskModel} from '../model/base-risk.model';
import {SaveClientResponseModel} from '../../../../personal/model/SaveClientModels/save-client-response.model';
import {CifAddressInfoModel} from '../../../../personal/model/SaveClientModels/cif-address-info.model';
import {BrokerDetailsService} from '../../../../shared/broker-common/broker-details.service';
import {CifRiskDetailsModel} from '../../../model/cif/cif-risk-details.model';
import {DigitalAnalyticsService} from '../../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../../shared/digital-analytics/model/digital-analytics-element.model';
import {GoogleAnalyticsService} from '../../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../../shared/google-analytics/model/google-analytics-action.model';

export abstract class BaseRiskService implements OnDestroy {

  private riskCards: CardModel[] = new Array();
  protected cardModels = {};

  protected mstiService: MSTIService;
  protected personalService: PersonalService;
  protected quoteService: QuoteService;
  protected loaderService: LoaderService;
  protected mstiErrorService: MstiErrorService;
  protected userService: BrokerDetailsService;
  private digitalAnalyticsService: DigitalAnalyticsService;
  private googleAnalyticsService: GoogleAnalyticsService;

  constructor(
    injector: Injector
  ) {
    this.mstiService = injector.get(MSTIService);
    this.personalService = injector.get(PersonalService);
    this.quoteService = injector.get(QuoteService);
    this.loaderService = injector.get(LoaderService);
    this.mstiErrorService = injector.get(MstiErrorService);
    this.userService = injector.get(BrokerDetailsService);
    this.digitalAnalyticsService = injector.get(DigitalAnalyticsService);
    this.googleAnalyticsService = injector.get(GoogleAnalyticsService);
  }

  ngOnDestroy() {
    this.destroy();
  }

  public destroy() {
    this.cardModels = {};
    this.riskCards = new Array();
    this.doDestroy();
  }

  protected initCardModel(cardId: string) {
    if (!this.cardModels[cardId]) {
      this.cardModels[cardId] = {};
      this.cardModels[cardId].cifRiskModel = this.instantiateRiskModel(this.personalService.getClient());
    }
  }

  public getRiskCards(): CardModel[] {
    return this.riskCards;
  }

  public addRiskCard(riskCard: CardModel) {
    this.riskCards.push(riskCard);
  }

  public removeRiskCard(cardToRemove: CardModel) {
    this.removeCardModelData(cardToRemove.id);

    this.riskCards = this.riskCards.filter(
      riskCard => riskCard !== cardToRemove
    );
  }

  public getCompletedCardCount(): number {
    return this.riskCards.filter(card => card.status.toLowerCase() === 'completed!').length;
  }

  public persistFormData(data) {
    this.initCardModel(data.cardId);
    this.doFormDataPersist(data);
  }

  // TODO - Implement this for the updating of quotes as well
  protected processRiskIdsInQuoteSaveResponse(risks: CifRiskDetailsModel[], cardId: string) {
    for (const risk of risks) {
      // NOTE: When creating a brand new quote we can only save with one risk
      if (risks.length === 1) {
        this.cardModels[cardId].cifRiskModel.riskId = risk.riskResourceRef;
        this.cardModels[cardId].cifRiskModel.riskResourceRef = risk.riskResourceRef;
      }
    }
  }

  protected processRiskIdInSaveResponse(riskId: string, cardId: string) {
    if (riskId) {
      this.cardModels[cardId].cifRiskModel.riskId = riskId;
      this.cardModels[cardId].cifRiskModel.riskResourceRef = riskId;
    }
  }

  protected async saveRiskToQuote(quoteId: string, riskModel: BaseRiskModel): Promise<string> {
    this.loaderService.show();
    let riskId = null;
    await this.mstiService.postWithAbAuth(this.getAddRiskToQuoteURL(quoteId), riskModel)
      .toPromise().then((response: any) => {
        const cifRiskModel: BaseCifRiskModel = Object.assign(this.instantiateRiskModel(this.personalService.getClient()), response);
        riskId = cifRiskModel.riskResourceRef;
        Logger.log(Level.LOG, 'Add risk to existing quote response:', cifRiskModel);
      }, (error) => {
        Logger.log(Level.ERROR, 'Error while adding risk to existing quote', error);
        this.mstiErrorService.handleError(error);
      }).then(() => {
        this.loaderService.hide();
      });

    return riskId;
  }

  public async deleteRisk(quoteId: string, riskId: string): Promise<boolean> {
    this.loaderService.show();
    let deleteSucceeded = false;
    await this.mstiService.deleteWithAbAuth(
      this.getDeleteRiskURL(quoteId, riskId)).toPromise().then((response: any) => {
      deleteSucceeded = true;
      Logger.log(Level.LOG, 'Delete risk response:', response);
      this.loaderService.hide();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while deleting risk', error);
      this.mstiErrorService.handleError(error);
      this.loaderService.hide();
    });

    return deleteSucceeded;
  }

  protected removeCardModelData(cardId) {

    // For some reason the riskResourceRef is not always available,
    // when testing without debugging it was missing, when testing with debugger it was available.
    // This if statement is just a failsafe.
    let riskId;
    if (this.cardModels[cardId].cifRiskModel.riskResourceRef) {
      riskId = this.cardModels[cardId].cifRiskModel.riskResourceRef;
    } else {
      riskId = this.cardModels[cardId].cifRiskModel.riskId;
    }
    const quoteId = this.quoteService.quoteId;

    if (riskId && quoteId) {
      this.deleteRisk(quoteId, riskId).then(response => {
        if (response === true) {
          delete this.cardModels[cardId];
        }
      });
    } else {
      delete this.cardModels[cardId];
    }
  }

  protected getCardStatus() {
    let cardStatus = 'Not yet completed';

    if (this.cardModels) {
      if (Object.keys(this.cardModels).length > 0) {

        const firstCardModelElement = this.cardModels[0];
        const lastCardModelElement = this.cardModels[Object.keys(this.cardModels).length - 1];

        if (lastCardModelElement) {
          cardStatus = 'Completed';
        } else if (firstCardModelElement) {
          cardStatus = 'In progress';
        }
      }
    }

    return cardStatus;
  }

  public getClientHomeAddressDetails(): CifAddressInfoModel {
    let homeAddress;
    const client = this.personalService.getClient();

    if (client) {
      const homeAddresses = client.addressList.filter(address => address.addressType.toLowerCase() === 'home');

      if (homeAddresses.length > 0) {
        homeAddress = homeAddresses[0];
      }
    }

    return CifAddressInfoModel.getCifAddressInfoModel(homeAddress);
  }

  public fireAddCardAnalytics(cardConfigName) {
    const attributes: Map<number, string> = new Map<number, string>();
    switch (cardConfigName) {
      case 'Personal Motor': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_ADD_VEHICLE,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_ADD_ANOTHER_VEHICLE_ON_VEHICLE_COVER
        );
        break;
      }
      case 'Personal Contents': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_ADD_CONTENT,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_ADD_CONTENTS_INSURANCE_FOR_ANOTHER_PROPERTY
        );
        break;
      }
      case 'Personal Building': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_ADD_BUILDING,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_ADD_ANOTHER_BUILDING
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  public fireRemoveCardAnalytics(cardConfigName) {
    const attributes: Map<number, string> = new Map<number, string>();
    switch (cardConfigName) {
      case 'Personal Motor': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_REMOVE_VEHICLE,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_REMOVE_VEHICLE_COVER
        );
        break;
      }
      case 'Personal Contents': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_REMOVE_CONTENT,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_REMOVE_HOME_CONTENTS_AND_PERSONAL_BELONGINGS
        );
        break;
      }
      case 'Personal Building': {
        this.digitalAnalyticsService.constructAndFireElementTag(
          DigitalAnalyticsElementModel.ELEMENT_COVER_REMOVE_BUILDING,
          attributes
        );
        this.googleAnalyticsService.fireGoogleAnalytics(
          GoogleAnalyticsActionModel.ACTION_YES,
          GoogleAnalyticsEventModel.EVENT_REMOVE_BUILDING
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  abstract doDestroy(): void;

  abstract instantiateRiskModel(client: SaveClientResponseModel): BaseCifRiskModel;

  abstract doFormDataPersist(data: any);

  abstract getAddRiskToQuoteURL(quoteId): string;

  abstract getDeleteRiskURL(quoteId: string, riskId: string): string;

  abstract retrieveRiskDataFromCif(riskId: string, cardId: string): Promise<any>;

  abstract getExistingCardHeading(cardId: string): string;

}
