import {PersonalBelongingsSpecifiedModel} from '../../../../shared/dynamic-form/dynamic-form-control/control-types/risk-specific/personal-belongings-specified/model/personal-belongings-specified.model';
import {CifMovablesModel} from './cif/cif-movables.model';

export class PersonalBelongingsModel {

    constructor() {
    }

    unspecifiedBelongingId: string;
    selectedPersonalBelongingsOption = '';
    specifiedPersonalBelongings: PersonalBelongingsSpecifiedModel[];
    unspecifiedBelongingsAmount: string;

    populateFromFormData(formData) {
        this.selectedPersonalBelongingsOption = formData.selectedPersonalBelongingsOption;
        this.specifiedPersonalBelongings = formData.specifiedPersonalBelongings;
        this.unspecifiedBelongingsAmount = formData.unspecifiedBelongingsAmount;
    }

    populateContentFromCifModel(quoteId: string, contentsRiskId: string, cifMovablesModel: CifMovablesModel) {
        if (cifMovablesModel.smiCode === CifMovablesModel.SMI_CODE_UNSPECIFIED) {
            this.unspecifiedBelongingId = cifMovablesModel.riskResourceRef;
            this.unspecifiedBelongingsAmount = cifMovablesModel.sumInsured;
        } else {
            this.instantiateSpecifiedPersonalBelongingsArray();

            const personalBelongingsSpecifiedModel = new PersonalBelongingsSpecifiedModel(
                cifMovablesModel.riskResourceRef,
                cifMovablesModel.smiDescription,
                cifMovablesModel.smiCode,
                cifMovablesModel.makeModelDescription,
                Number(cifMovablesModel.sumInsured) || 0
            );
            personalBelongingsSpecifiedModel.contentRiskId = contentsRiskId;
            personalBelongingsSpecifiedModel.quoteId = quoteId;
            this.specifiedPersonalBelongings.push(personalBelongingsSpecifiedModel);
        }

        this.updateSelectedPersonalBelongingsOption();
    }

    private instantiateSpecifiedPersonalBelongingsArray() {
        if (!this.specifiedPersonalBelongings) {
            this.specifiedPersonalBelongings = new Array<PersonalBelongingsSpecifiedModel>();
        }
    }

    private updateSelectedPersonalBelongingsOption() {
        if (
            this.specifiedPersonalBelongings &&
            this.specifiedPersonalBelongings.length > 0 &&
            (
                !this.unspecifiedBelongingsAmount ||
                (Number(this.unspecifiedBelongingsAmount) || 0) === 0
            )
        ) {
            this.selectedPersonalBelongingsOption = 'Specified Personal Belongings';
        } else if (
            (
                !this.specifiedPersonalBelongings ||
                this.specifiedPersonalBelongings.length <= 0
            ) &&
            this.unspecifiedBelongingsAmount &&
            (Number(this.unspecifiedBelongingsAmount) || 0) > 0
        ) {
            this.selectedPersonalBelongingsOption = 'Unspecified Personal Belongings';
        } else if (
            this.specifiedPersonalBelongings &&
            this.specifiedPersonalBelongings.length > 0 &&
            this.unspecifiedBelongingsAmount &&
            (Number(this.unspecifiedBelongingsAmount) || 0) > 0
        ) {
            this.selectedPersonalBelongingsOption = 'Unspecified and Specified Personal Belongings';
        } else if (
            (
                !this.specifiedPersonalBelongings ||
                this.specifiedPersonalBelongings.length <= 0
            ) &&
            (
                !this.unspecifiedBelongingsAmount ||
                (Number(this.unspecifiedBelongingsAmount) || 0) === 0
            )
        ) {
            this.selectedPersonalBelongingsOption = 'None';
        }
    }

}

