export class CifLicenseDetailsModel {

    driversLicenseCode: string;
    issueDate: string;
    ageIssued: number;
    limitations: string[];

    constructor() {
        this.limitations = [];
    }

}
