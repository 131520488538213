import {Injectable} from '@angular/core';
import {UrlService} from '../../shared/services/url.service';
import {MSTIConfiguration} from '../../shared/msti.config';

@Injectable()
export class QuoteUrlService extends UrlService {

  constructor() {
    super();
  }

  public getAddQuoteURL(): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations`;
  }

  public getGetQuoteURL(quoteId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}`;
  }

  public getGetPremiumSummaryURL(quoteId: string): string {
    return `${MSTIConfiguration.constants.brokerBffUrlPrefix}${this.BROKERBFF_API}brokerClientsApi/personalQuotations/${quoteId}/premiumSummaryAsync`;
  }

  public getQuoteDocURL(clientRef: string, quoteId: string): string {
    return MSTIConfiguration.constants.brokerBffUrlPrefix + this.BROKERBFF_API + 'brokerDocumentsApi/broker/clients/' + clientRef + '/documents/quote/' + quoteId;
  }


}
