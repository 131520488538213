import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {IdNumberDynamicFormControlComponent} from './idnumber-dynamic-form-control.component';

export class IdNumberDynamicControlModel extends BaseDynamicControlModel<string> {

    constructor(controlModel?: Partial<IdNumberDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return IdNumberDynamicFormControlComponent;
    }

}
