import {CifSecurityFeatureModel} from '../../../vehicle/model/cif/cif-security-feature.model';

export class CifBuildingStructureModel {

    sizeOfDwelling: number;
    numberOfBedrooms: number;
    wallConstructionTypeCode: string;
    roofConstructionTypeCode: string;
    commune: boolean;
    constructionApproved: boolean;
    lightingConductor: boolean;
    thatchLapa: boolean;
    thatchTreated: boolean;
    thatchDistanceTypeCode: string;
    lapaOnPremises: boolean;
    roofPitchTypeCode: string;
    numberOfStoreysTypeCode: string;
    numberOfStoreysOccupiedCode: string;
    numberOfWaterHeatingSystems: number;
    swimmingPool: boolean;
    securityFeatures: CifSecurityFeatureModel[] = new Array<CifSecurityFeatureModel>();

}
