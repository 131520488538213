import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DisclosureThankyouComponent} from './disclosure-thankyou/disclosure-thankyou.component';
import {DisclosureItcComponent} from './disclosure-itc/disclosure-itc.component';
import {DisclosureEntryComponent} from './disclosure-entry.component';
import {DisclosureQuestionsComponent} from './disclosure-questions/disclosure-questions.component';
import {DisclosureCaptureClaimComponent} from './previous-claims/disclosure-capture-claim.component';


const appRoutes: Routes = [

    {
      path: 'app-disclosure',
      component: DisclosureEntryComponent,
      children: [
        {
          path: 'app-disclosure-questions',
          component: DisclosureQuestionsComponent,
          data: {routeIdx: 'app-disclosure-questions'}
        },
        {
          path: 'app-disclosure-thankyou',
          component: DisclosureThankyouComponent,
          data: {routeIdx: 'app-disclosure-thankyou'}
        },
        {
          path: 'app-disclosure-capture-claim',
          component: DisclosureCaptureClaimComponent,
          data: {routeIdx: '          path: \'app-disclosure-capture-claim\',\n'}
        },
        {
          path: 'app-disclosure-itc', component: DisclosureItcComponent,
          data: {routeIdx: 'app-disclosure-itc'}
        }]
    }

  ]
;

@NgModule({
  imports: [
    // RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
    RouterModule.forChild(appRoutes)
  ],
  exports: [RouterModule]
})

export class DisclosureRoutingModule {


}
