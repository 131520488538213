export class NeedHelpPageTags {

  public static readonly PAGE_LABEL_MARKETING = 'Marketing page';
  public static readonly PAGE_LABEL_PERSONAL_INFORMATION = 'Personal information page';
  public static readonly PAGE_LABEL_DISCLOSURES_ITC_CHECK = 'Disclosures: ITC check page';
  public static readonly PAGE_LABEL_DISCLOSURES_CURRENTLY_INSURED = 'Disclosures: Currently currentlyInsured page';
  public static readonly PAGE_LABEL_DISCLOSURES_COMPANY_INSURED_WITH = 'Disclosures: Company currentlyInsured with page';
  public static readonly PAGE_LABEL_DISCLOSURES_PERIOD_WITHOUT_COVER = 'Disclosures: Period without cover page';
  public static readonly PAGE_LABEL_DISCLOSURES_YEARS_OF_UNINTERRUPTED_COVER = 'Disclosures: Years of uninterrupted cover page';
  public static readonly PAGE_LABEL_DISCLOSURES_HAVE_YOU_CLAIMED = 'Disclosures: Have you claimed page';
  public static readonly PAGE_LABEL_DISCLOSURES_PARENT = 'Disclosure page';
  public static readonly PAGE_LABEL_DISCLOSURES_CAPTURE_CLAIMS_DETAILS = 'Disclosures: Capture claim details page';
  public static readonly PAGE_LABEL_DISCLOSURES_INSURANCE_CANCELLATION_REFUSAL = 'Disclosures: Insurance cancellation / refusal page';
  public static readonly PAGE_LABEL_DISCLOSURES_CONFIRMATION = 'Disclosures: Confirmation page';
  public static readonly PAGE_LABEL_COVER_DETAILS = 'Cover details page';
  public static readonly PAGE_LABEL_COVER_DETAILS_VEHICLE_NOT_FOUND = 'Cover details: Vehicle not found';
  public static readonly PAGE_LABEL_QUOTE_PREMIUM = 'Quote premium page';
  public static readonly PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES = 'Quote premium page: Client approves';
  public static readonly PAGE_LABEL_PERSONAL_INFORMATION_SUBURB_NOT_FOUND = 'Personal information: Suburb not found';
  public static readonly PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION = 'Quote thank you for your application page';
  public static readonly PAGE_LABEL_TECHNICAL_ERROR = 'Technical error page';
  public static readonly PAGE_ERROR = 'Premia error yes or no';
  public static readonly PAGE_EXISTING_CLIENT_CALL_BACK = 'Existing client Call Back';
  public static readonly PAGE_PRE_LOGIN = 'pre login page';
  public static readonly PAGE_MAINTENANCE = 'maintenance page';
  public static readonly PAGE_ADMIN_FEE = 'admin fee page';
  private static stageCodeMap: Map<string, string>;

  public static initialise() {
    this.stageCodeMap = new Map<string, string>();
    this.stageCodeMap.set(this.PAGE_LABEL_MARKETING, 'BO01');
    this.stageCodeMap.set(this.PAGE_LABEL_PERSONAL_INFORMATION, 'BO02');
    this.stageCodeMap.set(this.PAGE_LABEL_DISCLOSURES_PARENT, 'BO03');
    this.stageCodeMap.set(this.PAGE_LABEL_COVER_DETAILS, 'BO04');
    this.stageCodeMap.set(this.PAGE_LABEL_COVER_DETAILS_VEHICLE_NOT_FOUND, 'BO05');
    this.stageCodeMap.set(this.PAGE_ADMIN_FEE, 'BO06');
    this.stageCodeMap.set(this.PAGE_LABEL_QUOTE_PREMIUM, 'BO07');
    this.stageCodeMap.set(this.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES, 'BO08');
    this.stageCodeMap.set(this.PAGE_ERROR, 'BO09');
    this.stageCodeMap.set(this.PAGE_EXISTING_CLIENT_CALL_BACK, 'BO010');

    // pages that don't need do you need help, mark them as wildPage
    this.stageCodeMap.set(this.PAGE_LABEL_PERSONAL_INFORMATION_SUBURB_NOT_FOUND, 'wildPage');
    this.stageCodeMap.set(this.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION, 'wildPage');
    this.stageCodeMap.set(this.PAGE_LABEL_TECHNICAL_ERROR, 'wildPage');
    this.stageCodeMap.set(this.PAGE_PRE_LOGIN, 'wildPage');
    this.stageCodeMap.set(this.PAGE_MAINTENANCE, 'wildPage');
  }

  public static getStageCodeForPageLabel(pageLabel: string): string {
    return this.stageCodeMap.get(pageLabel);
  }

}
