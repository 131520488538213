import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {QuoteComponent} from './component/quote.component';
import {QuoteCoverPickerComponent} from './component/quote-cover-picker/quote-cover-picker.component';
import {CardModule} from './card/card.module';
import {VehicleModule} from './sections/vehicle/vehicle.module';
import {QuoteService} from './service/quote.service';
import {QuoteUrlService} from './service/quote-url.service';
import {ContentsModule} from './sections/contents/contents.module';
import {BuildingModule} from './sections/building/building.module';
import {PersonalBelongingsModule} from './sections/personal-belongings/personal-belongings.module';

@NgModule({
    declarations: [
        QuoteComponent,
        QuoteCoverPickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CardModule,
        VehicleModule,
        ContentsModule,
        PersonalBelongingsModule,
        BuildingModule
    ],
    exports: [],
    providers: [QuoteService, QuoteUrlService]
})
export class QuoteModule {
}
