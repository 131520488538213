import {Type} from '@angular/core';
import {BaseDynamicControlModel} from '../../../base-dynamic-control.model';
import {PersonalBelongingsSpecifiedDynamicFormControlComponent} from './personal-belongings-specified-dynamic-form-control.component';
import {PersonalBelongingsSpecifiedModel} from './model/personal-belongings-specified.model';

export class PersonalBelongingsSpecifiedDynamicControlModel extends BaseDynamicControlModel<PersonalBelongingsSpecifiedModel[]> {

    constructor(controlModel?: Partial<PersonalBelongingsSpecifiedDynamicControlModel>) {
        super(controlModel);
    }

    getControlClass(): Type<any> {
        return PersonalBelongingsSpecifiedDynamicFormControlComponent;
    }

}
