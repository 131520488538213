import {Component, Injector} from '@angular/core';
import {BaseDynamicFormControl} from '../../../base-dynamic-form-control';

@Component({
    selector: 'app-idnumber-dynamic-form-control',
    templateUrl: './idnumber-dynamic-form-control.component.html',
    styleUrls: ['./idnumber-dynamic-control.component.scss']

})
export class IdNumberDynamicFormControlComponent extends BaseDynamicFormControl<string> {

    // TODO - Confirm how this control is any different from an normal input control
    // if all that is unique is the value used (same for the email control)

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    doInit() { }

    updateFormControlValue(inputValue: any) {
        // TODO - Implement me
    }

    clearValue() {
        this.dynamicControlModel.value = null;
    }

}
