import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RiskOptionModel} from '../../../shared/risk/risk.option.model';
import {RiskOptionService} from '../../../shared/risk/risk-option.service';
import {ImageService} from '../../../shared/services/image.service';
import {QuoteConfig} from '../../quote.config';
import {QuoteCoverItemModel} from '../../model/quote-cover-item.model';
import {UiUtilsService} from '../../../shared/utilities/ui-utils.service';
import {GoogleAnalyticsService} from '../../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../../shared/google-analytics/model/google-analytics-action.model';

@Component({
    selector: 'app-quote-cover-picker',
    templateUrl: './quote-cover-picker.component.html',
    styleUrls: ['./quote-cover-picker.component.scss']
})
export class QuoteCoverPickerComponent implements OnInit {

    @Input() selectedRiskOptions: RiskOptionModel[];
    @Output() onCoverSelected = new EventEmitter();

    riskOptions: RiskOptionModel[] = [];
    quoteCoverItemModels: QuoteCoverItemModel[] = [];

    constructor(
        private imageService: ImageService,
        private riskOptionService: RiskOptionService,
        private uiUtilsService: UiUtilsService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit() {
        this.initQuoteCoverItemModels();
        this.hideCoverSection();
    }

    deSelectQuoteCoverItemModels() {
        this.quoteCoverItemModels.forEach(function (quoteCoverItemModel) {
            quoteCoverItemModel.isSelected = false;
        });
    }

    initQuoteCoverItemModels() {
        this.quoteCoverItemModels = new Array<QuoteCoverItemModel>();

        const coverTypeConfig = QuoteConfig.COVER_TYPE_CONFIG;
        const coverTypeDescriptionConfig = QuoteConfig.COVER_TYPE_DESCRIPTION_CONFIG;
        const coverTypeRiskNameConfig = QuoteConfig.COVER_TYPE_RISK_NAME_CONFIG;
        const coverTypeTagLineConfig = QuoteConfig.COVER_TYPE_TAGLINE_CONFIG;
        const coverTypeIconUrlConfig = QuoteConfig.COVER_TYPE_ICON_URL_CONFIG;
        const coverTypeActiveStatusConfig = QuoteConfig.COVER_TYPE_ACTIVE_STATUS_CONFIG;
        const coverTypeOrderConfig = QuoteConfig.COVER_TYPE_ORDER_CONFIG;
        const coverTypeMetaDataConfig = QuoteConfig.COVER_TYPE_METADATA_CONFIG;

        const isSelected = false;

        for (const [key, value] of Object.entries(coverTypeConfig)) {
            if (coverTypeActiveStatusConfig[key]) {
                if (this.isRiskOptionSelectable(coverTypeRiskNameConfig[key])) {
                    this.quoteCoverItemModels.push(
                        new QuoteCoverItemModel(
                            coverTypeConfig[key],
                            coverTypeDescriptionConfig[key],
                            coverTypeRiskNameConfig[key],
                            coverTypeTagLineConfig[key],
                            this.imageService.getImage(coverTypeIconUrlConfig[key]),
                            coverTypeActiveStatusConfig[key],
                            coverTypeOrderConfig[key],
                            isSelected,
                            coverTypeMetaDataConfig[key]
                        )
                    );
                }
            }
        }

        this.quoteCoverItemModels = this.quoteCoverItemModels.sort((a, b) => a.order - b.order);
    }

    isRiskOptionSelectable(riskName) {
        return this.riskOptionService.availableRiskOptions.some(option => option.name === riskName && option.isSelected === false);
    }

    toggleRiskOption(quoteCoverItem: QuoteCoverItemModel) {
        quoteCoverItem.isSelected = !quoteCoverItem.isSelected;
    }

    _onCoverSelected() {
        this.onCoverSelected.emit();
    }

    addCover() {
        const context = this;
        const preSelectedOptions = context.riskOptionService.getSelectedRiskOptions();
        const selectedOptions = context.quoteCoverItemModels.filter(option => option.isSelected === true);
        selectedOptions.forEach(function (quoteCoverItem, index) {
            const orderIndex = preSelectedOptions.length + index + 1;
            context.riskOptionService.selectSpecificRiskOption(quoteCoverItem.riskName);
            context.riskOptionService.setSpecificRiskOptionOrder(quoteCoverItem.riskName, orderIndex);
        });
        this._onCoverSelected();
        this.hideCoverSection();
        this.initQuoteCoverItemModels();
    }

    showCoverSection() {
        this.googleAnalyticsService.fireGoogleAnalytics(
            GoogleAnalyticsActionModel.ACTION_YES,
            GoogleAnalyticsEventModel.EVENT_SELECT_NEW_COVER
        );
        this.deSelectQuoteCoverItemModels();
        this.uiUtilsService.showElements('div.quote-cover-items-content, div.quote-cover-items-footer');
        this.uiUtilsService.hideElements('div.quote-cover-items-add-section');
    }

    hideCoverSection() {
        this.uiUtilsService.hideElements('div.quote-cover-items-content, div.quote-cover-items-footer');
        this.uiUtilsService.showElements('div.quote-cover-items-add-section');
    }

}
