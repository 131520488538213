import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {MarketingBottomConfig} from './marketing-bottom.config';
import {ImageService} from '../../../shared/services/image.service';
import {SmoothScroll} from '../../../shared/utilities/smooth-scroll';
import {DigitalAnalyticsService} from '../../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsElementModel} from '../../../shared/digital-analytics/model/digital-analytics-element.model';
import {BreakpointObserver} from '@angular/cdk/layout';
import {UiUtilsService} from '../../../shared/utilities/ui-utils.service';

@Component({
    selector: 'app-marketing-bottom',
    templateUrl: './marketing-bottom.component.html',
    styleUrls: ['./marketing-bottom.component.scss'],
})
export class MarketingBottomComponent implements AfterViewInit {

    @Input() isCallCentreQuotable: boolean;

    complimentarySectionHeading: string;
    instructionsSectionHeading: string;
    cashbackRewardsSectionHeading: string;
    callCentreSectionHeading: string;
    complimentaryMstiCashbackRewardImage: string;
    complimentaryMstiClientNeedsImage: string;
    complimentaryMstiMomentumAppImage: string;
    quoteStepsMstiMobileDesktopImage: string;
    cashbackRewardsMstiCashbackIcon: string;
    cashbackRewardsMstiLockIcon: string;
    cashbackRewardsMstiMultiplyIcon: string;
    cashbackRewardsMstiSafedayzIcon: string;
    cashbackRewardsPlusIcon: string;
    cashbackRewardsEqualsIcon: string;
    footerImage: string;
    quoteStepsMstiMobileDesktopImageBack: string;
    quoteStepsMstiMobileDesktopImageFront: string;
    complimentaryMstiCashbackRewardCoverImage: string;
    complimentaryMstiCashbackRewardCoverVerticalImage: string;
    quoteStepsBackground: string;

    constructor(
        private imageService: ImageService,
        private digitalAnalyticsService: DigitalAnalyticsService,
        private elementRef: ElementRef,
        private breakpointObserver: BreakpointObserver,
        private uiUtilsService: UiUtilsService
    ) {
        this.complimentarySectionHeading = MarketingBottomConfig.COMPLIMENTARY_SECTION_HEADING;
        this.instructionsSectionHeading = MarketingBottomConfig.INSTRUCTIONS_SECTION_HEADING;
        this.cashbackRewardsSectionHeading = MarketingBottomConfig.CASHBACK_REWARDS_SECTION_HEADING;
        this.callCentreSectionHeading = MarketingBottomConfig.CALL_CENTRE_SECTION_HEADING;

        this.complimentaryMstiCashbackRewardImage = this.imageService.getImage('MSTI-CASHBACK-REWARD-PRELOGIN');
        this.complimentaryMstiClientNeedsImage = this.imageService.getImage('MSTI-CLIENT-NEEDS-PRELOGIN');
        this.complimentaryMstiMomentumAppImage = this.imageService.getImage('MSTI-MOMENTUM-APP-PRELOGIN');
        this.quoteStepsMstiMobileDesktopImage = this.imageService.getImage('MSTI-MOBILE-DESKTOP');
        this.cashbackRewardsMstiCashbackIcon = this.imageService.getImage('MSTI-CASHBACK-ICON');
        this.cashbackRewardsMstiLockIcon = this.imageService.getImage('MSTI-LOCK-ICON');
        this.cashbackRewardsMstiMultiplyIcon = this.imageService.getImage('MSTI-MULTIPLY-ICON');
        this.cashbackRewardsMstiSafedayzIcon = this.imageService.getImage('MSTI-SAFEDAYZ-ICON');
        this.cashbackRewardsPlusIcon = this.imageService.getImage('PLUS-ICON');
        this.cashbackRewardsEqualsIcon = this.imageService.getImage('EQUALS-ICON');
        this.quoteStepsMstiMobileDesktopImageBack = this.imageService.getImage('MSTI-MOBILE-DESKTOP_BACK');
        this.quoteStepsMstiMobileDesktopImageFront = this.imageService.getImage('MSTI-MOBILE-DESKTOP_FRONT');
        this.complimentaryMstiCashbackRewardCoverImage = this.imageService.getImage('MSTI-CASHBACK-REWARD-COVER');
        this.complimentaryMstiCashbackRewardCoverVerticalImage = this.imageService.getImage('MSTI-CASHBACK-REWARD-COVER-VERTICAL');
        this.quoteStepsBackground = this.imageService.getImage('MSTI-QUOTE-STEPS-BACKGROUND');

        this.footerImage = this.imageService.getImage('FOOTER');
    }

    ngAfterViewInit() {
        window.addEventListener('scroll', this.doParallaxEffectOnScroll.bind(this));
    }

    doParallaxEffectOnScroll() {
        if (this.breakpointObserver.isMatched('(min-width: 600px)')) {
            this.uiUtilsService.parallaxEffectOnScroll();
        }
    }

    scrollToQuoteSection() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_MARKETING_COVER_YOU_NEED, attributes);

        const scrollToNode = document.getElementById('scrollToCoverOptions');
        SmoothScroll.smoothScrollToElement(scrollToNode, -25 );
    }

    scrollToCashbackRewardsSection() {
        const attributes: Map<number, string> = new Map<number, string>();
        this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_MARKETING_CASH_BACK, attributes);

        const scrollToNode = document.getElementById('cashbackRewardsSection');
        SmoothScroll.smoothScrollToElement(scrollToNode, 25, 300);
    }

}
