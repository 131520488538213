export class RiskOptionModel {

  constructor(public quoteDescription: string,
              public quoteImage: string,
              public alt: string,
              public id: string,
              public name: string,
              public quoteDisplayDescription: string,
              public isSelected: boolean,
              public viewIndex: number,
              public sequence: number) {
  }

}
