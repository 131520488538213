import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {DisclosureItcDialogComponent} from './disclosure-itc-dialog/disclosure-itc-dialog.component';
import {PersonalService} from '../../personal/service/personal.service';
import {SaveClientResponseModel} from '../../personal/model/SaveClientModels/save-client-response.model';
import {Level} from '../../shared/utilities/logger-level';
import {DigitalAnalyticsService} from '../../shared/digital-analytics/digital-analytics.service';
import {DigitalAnalyticsPageModel} from '../../shared/digital-analytics/model/digital-analytics-page.model';
import {DigitalAnalyticsElementModel} from '../../shared/digital-analytics/model/digital-analytics-element.model';
import {LoaderService} from '../../shared/screen-loader/loader.service';
import {NeedHelpService} from '../../footer/need-help/services/need-help.service';
import {RoutingService} from '../../shared/services/routing.service';
import {BrokerDetailsService} from '../../shared/broker-common/broker-details.service';
import {MstiErrorService} from '../../shared/msti-error-handler/msti-error.service';
import {GoogleAnalyticsService} from '../../shared/google-analytics/google-analytics.service';
import {GoogleAnalyticsEventModel} from '../../shared/google-analytics/model/google-analytics-event.model';
import {GoogleAnalyticsActionModel} from '../../shared/google-analytics/model/google-analytics-action.model';
import {Logger} from '../../shared/utilities/logger';
import {SmoothScroll} from '../../shared/utilities/smooth-scroll';

@Component({
  selector: 'app-disclosure-itc',
  templateUrl: './disclosure-itc.component.html'
})
export class DisclosureItcComponent implements OnInit {

  consentMessage: string;
  isConsentChecked = false;

  constructor(
    public dialog: MatDialog,
    private personalService: PersonalService,
    private loaderService: LoaderService,
    private digitalAnalyticsService: DigitalAnalyticsService,
    private needHelpService: NeedHelpService,
    private routingService: RoutingService,
    private userService: BrokerDetailsService,
    private mstiErrorService: MstiErrorService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  ngOnInit() {
    SmoothScroll.smoothScrollToTop();
    this.consentMessage = this.personalService.getClient().consents[0].consentMessage;
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFirePageViewTag(DigitalAnalyticsPageModel.MSTI_ITC_CHECK_PAGE_ID, attributes);
  }

  openDisclosureDialog() {
    const attributes: Map<number, string> = new Map<number, string>();
    this.digitalAnalyticsService.constructAndFireElementTag(DigitalAnalyticsElementModel.ELEMENT_DISCLOSURE_ITC_KNOW_MORE, attributes);
    this.googleAnalyticsService.fireGoogleAnalytics(
      GoogleAnalyticsActionModel.ACTION_VIEWED,
      GoogleAnalyticsEventModel.EVENT_I_WANT_TO_KNOW_MORE_ABOUT_ITC
    );
    return this.dialog.open(DisclosureItcDialogComponent, {
      width: '52%',
      panelClass: 'msti-dialog-disclosure'
    });
  }

  onNext() {
    this.loaderService.show();
    if (this.isConsentChecked) {
      this.googleAnalyticsService.fireGoogleAnalytics(
        GoogleAnalyticsActionModel.ACTION_YES,
        GoogleAnalyticsEventModel.EVENT_GIVE_ITC_CONSENT
      );
      // this.routingService.route('/app-disclosure/app-disclosure-question-1');
      this.personalService.getClient().consents[0].consentChoice = true;
      this.personalService.getClient().consents[0].consentRequired = false;
      this.personalService.updateClient(this.personalService.getClient()).subscribe((response: any) => {
        this.personalService.clientDetails = Object.assign(new SaveClientResponseModel(), response);
        this.userService.getUser().clientResourceRef = this.personalService.clientDetails.clientResourceRef;
        Logger.log(Level.LOG, 'after Itc personal ', this.personalService.getClient());
        Logger.log(Level.LOG, 'after Itc user', this.userService.getUser());
        this.loaderService.hide();
        this.routingService.route('app-disclosure/app-disclosure-questions');
      }, (error) => {
        this.loaderService.hide();
        Logger.log(Level.ERROR, 'Error while updating client with itc');
        this.mstiErrorService.handleError(error);
      });

    }
  }

  onBack() {
    this.routingService.route('/app-personal');
  }

}
