import {Injectable} from '@angular/core';
import {GoogleAnalyticsActionModel} from './model/google-analytics-action.model';
import {GoogleAnalyticsEventModel} from './model/google-analytics-event.model';
import {Logger} from '../utilities/logger';
import {Level} from '../utilities/logger-level';
import {NeedHelpPageTags} from '../../footer/need-help/need-help-page-tags';
import {Observable, Subject} from 'rxjs';

declare var _gaq: any;
@Injectable()
export class GoogleAnalyticsService {

    private category: string;

    private userKeySubject = new Subject<any>();

    constructor() {
        this.category = GoogleAnalyticsEventModel.CATEGORY_MSTI_BROKER;
    }

    public getCategory(): string {
        return this.category;
    }

    public setCategory(newCategory: string) {
        this.category = newCategory;
    }

    public getUserKey(): Observable<any> {
        return this.userKeySubject.asObservable();
    }

    public setUserKey(userKey: string) {
        this.userKeySubject.next(userKey);
    }

    public clearUserKey() {
        this.userKeySubject.next();
    }

    public fireGoogleAnalytics(eventAction: string, eventLabel: string) {
        this.constructAndFireEventTag(
            eventAction,
            eventLabel
        );
    }

    private constructAndFireEventTag(eventAction: string, eventLabel: string) {
        const eventModel: GoogleAnalyticsEventModel = new GoogleAnalyticsEventModel();

        eventModel.trackMethod = GoogleAnalyticsEventModel.GOOGLE_EVENT_TRACK_METHOD;
        eventModel.category = this.getCategory();
        eventModel.eventAction = eventAction;
        eventModel.eventLabel = eventLabel;

        Logger.log(Level.LOG, 'GA Event Model', eventModel);

        _gaq.push([GoogleAnalyticsEventModel.GOOGLE_EVENT_TRACK_METHOD,
            this.getCategory(),
            eventAction,
            eventLabel]);
    }

    public fireGoogleAnalyticsForDoYouNeedHelpPage(page: string) {
        switch (page) {
            case NeedHelpPageTags.PAGE_LABEL_MARKETING: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_MARKETING_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_PERSONAL_INFORMATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_PERSONAL_INFORMATION_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_ITC_CHECK: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_ITC_CONSENT_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CURRENTLY_INSURED: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CURRENTLY_INSURED_WITH_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_COMPANY_INSURED_WITH: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_COMPANY_INSURED_WITH_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_PERIOD_WITHOUT_COVER: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_BREAK_IN_COVER_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_YEARS_OF_UNINTERRUPTED_COVER: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CONTINUOUS_COVER_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_HAVE_YOU_CLAIMED: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CLAIMS_DECLARATION_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CAPTURE_CLAIMS_DETAILS: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_CLAIMS_CAPTURE_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_INSURANCE_CANCELLATION_REFUSAL: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_INSURANCE_CANCELLED_OR_DECLINED_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CONFIRMATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_PROCEED_TO_COVER_DETAILS_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_COVER_DETAILS: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_COVER_DETAILS_PAGE);
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM:
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES:
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_SUBMITTED,
                    GoogleAnalyticsEventModel.EVENT_SUBMIT_DO_YOU_NEED_HELP_FROM_QUOTE_PAGE);
                break;
            }
            default: {
                Logger.log(Level.ERROR, 'Invalid page', page);
                break;
            }
        }
    }

    public fireGoogleAnalyticsForReturnToPortfolio(page: string) {
        switch (page) {
            case NeedHelpPageTags.PAGE_LABEL_MARKETING: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_MARKETING_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_PERSONAL_INFORMATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_PERSONAL_INFORMATION_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_ITC_CHECK: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_ITC_CONSENT_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CURRENTLY_INSURED: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_CURRENTLY_INSURED_WITH_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_COMPANY_INSURED_WITH: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_COMPANY_INSURED_WITH_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_PERIOD_WITHOUT_COVER: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_BREAK_IN_COVER_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_YEARS_OF_UNINTERRUPTED_COVER: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_CONTINUOUS_COVER_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_HAVE_YOU_CLAIMED: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_CLAIMS_DECLARATION_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CAPTURE_CLAIMS_DETAILS: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_CLAIMS_CAPTURE_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_INSURANCE_CANCELLATION_REFUSAL: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_INSURANCE_CANCELLED_OR_DECLINED_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_CONFIRMATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_PROCEED_TO_COVER_DETAILS_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_COVER_DETAILS: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_COVER_DETAILS_PAGE
                );
                break;
            }
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM:
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_PREMIUM_PAGE_CLIENT_APPROVES:
            case NeedHelpPageTags.PAGE_LABEL_QUOTE_THANK_YOU_FOR_YOUR_APPLICATION: {
                this.constructAndFireEventTag(
                    GoogleAnalyticsActionModel.ACTION_YES,
                    GoogleAnalyticsEventModel.EVENT_RETURN_TO_PORTFOLIO_FROM_QUOTE_PAGE
                );
                break;
            }
            default: {
                Logger.log(Level.ERROR, 'Invalid page', page);
                break;
            }
        }
    }

}
