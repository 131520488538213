import {Component, OnInit} from '@angular/core';
import {PersonalService} from '../personal/service/personal.service';
import {DisclosureService} from './services/disclosure.service';
import {LoaderService} from '../shared/screen-loader/loader.service';
import {ActivatedRoute} from '@angular/router';
import {RoutingService} from '../shared/services/routing.service';
import {UiUtilsService} from '../shared/utilities/ui-utils.service';
import {NeedHelpPageTags} from '../footer/need-help/need-help-page-tags';
import {NeedHelpService} from '../footer/need-help/services/need-help.service';

@Component({
  selector: 'app-disclosure-entry',
  templateUrl: './disclosure-entry.component.html',
  animations: []
})
export class DisclosureEntryComponent implements OnInit {

  askForItc = true;
  consentMessage: string;
  animateBackward;
  animateForward;

  private componentInitialised: boolean;

  constructor(private personalService: PersonalService, private disclosureService: DisclosureService,
              private loaderService: LoaderService, private route: ActivatedRoute, private routingService: RoutingService,
              private uiUtilsService: UiUtilsService, private needHelpService: NeedHelpService) {
    this.needHelpService.setCurrentPage(NeedHelpPageTags.PAGE_LABEL_DISCLOSURES_PARENT);
    this.componentInitialised = false;
    this.uiUtilsService.giveAppWrapperMargin();
    window.addEventListener('resize', () => {
      this.uiUtilsService.giveAppWrapperMargin();
    });
  }

  ngOnInit() {
    this.loadDisclosure();
  }

  private loadDisclosure() {
    this.loaderService.show();
    this.disclosureService.initializeDisclosureLookups();
    this.itcCheck();

  }


  private itcCheck() {
    if (this.personalService.getClient().consents.length > 0) { // TODO  Added this coz we r not getting consent back while saving the consent
      this.askForItc = this.personalService.getClient().consents[0].consentRequired;
      if (this.askForItc === true) {
        this.consentMessage = this.personalService.getClient().consents[0].consentMessage;
        this.routingService.route('app-disclosure/app-disclosure-itc');
      } else {
        this.routingService.route('app-disclosure/app-disclosure-questions');
      }
      this.loaderService.hide();

    } else {
      // TODO we dont know what to do here
      this.loaderService.hide();
    }
  }


}
