import {Component, Input, OnInit} from '@angular/core';
import {PreviousClaimsModel} from '../models/previous-claims.model';
import {DisclosureService} from '../services/disclosure.service';


@Component({
  selector: 'app-previous-ids-claims',
  templateUrl: './previous-ids-claims.component.html',
  styleUrls: ['./previous-ids-claims.component.scss']
})
export class PreviousIDSClaimsComponent implements OnInit {
  previousClaimsModel: PreviousClaimsModel[];
  @Input() parentFormGroup;
  riskTypesMap;
  claimTypesMap;

  constructor(private disclosureService: DisclosureService) {

  }

  async ngOnInit() {
    this.previousClaimsModel = this.disclosureService.getDisclosureQuestionsModel().previousClaims.filter(claim => claim.origin === 'IDS Claim');
    this.riskTypesMap = await this.disclosureService.getRiskTypeOptions();
    this.claimTypesMap = await this.disclosureService.getClaimTypeOptions();
  }

  getClaimType(claimTypeCode: string) {
    let val;
    if (this.claimTypesMap) {
      val = this.claimTypesMap.find(x => x.code === claimTypeCode);
      return val.description;
    }


    return val;
  }

  getCoverType(riskCode: string) {
    let val;
    if (this.riskTypesMap) {
      val = this.riskTypesMap.find(x => x.code === riskCode);
      return val.description;
    }


    return val;
  }

  isChecked(discardYN: string) {
    if (discardYN === '1') {
      return true;
    } else {
      return false;
    }

  }

  toggle(discardYN: string) {
    if (discardYN === '1') {
      return '0';
    } else {
      return '1';
    }
  }
}
