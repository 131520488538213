import {Component, Inject, OnInit} from '@angular/core';
import {ImageService} from '../../../../../../services/image.service';
import {MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatDialogRef} from '@angular/material';
import {PersonalBelongingsSpecifiedModel} from '../model/personal-belongings-specified.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LookupFilteredDropDownModel} from '../../../../../../lookup/lookup.filtered.drop.down.model';
import {Observable} from 'rxjs/index';
import {map, startWith} from 'rxjs/internal/operators';
import {DefaultLookupModel} from '../../../../../../lookup/default-lookup.model';
import {LoaderService} from '../../../../../../../shared/screen-loader/loader.service';
import {LookupService} from '../../../../../../../shared/lookup/lookup.service';
import {UiUtilsService} from '../../../../../../utilities/ui-utils.service';

@Component({
    selector: 'app-personal-belongings-specified-popup-dialog',
    templateUrl: './personal-belongings-specified-popup-dialog.component.html',
    styleUrls: ['./personal-belongings-specified-popup-dialog.component.scss']
})
export class PersonalBelongingsSpecifiedPopupDialogComponent implements OnInit {

    itemDescriptionMaxLength = 70;
    itemValueMinValue = 1;
    itemValueMaxLength = 10;

    IsTextCountEnabled = false;

    categoryOptions: LookupFilteredDropDownModel = new LookupFilteredDropDownModel([]);
    closeIcon: string;
    dialogForm: FormGroup;
    filteredOptions: Observable<DefaultLookupModel[]>;
    itemCategory = new FormControl();
    itemDescription: FormControl;
    itemValue: FormControl;
    personalBelongingsSpecifiedModel: PersonalBelongingsSpecifiedModel;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private imageService: ImageService,
        private dialogRef: MatDialogRef<PersonalBelongingsSpecifiedPopupDialogComponent>,
        private loaderService: LoaderService,
        private lookupService: LookupService,
        private uiUtilsService: UiUtilsService,
        private formBuilder: FormBuilder) {
            this.initializeIcons(imageService);
        this.personalBelongingsSpecifiedModel = data.optionModel;
    }

    ngOnInit() {
        this.initializeLookUpData();
        this.setControls();
        this.preFill();
    }

    private initializeLookUpData() {
        this.getCategories();
        this.setCategories();
    }

    private getCategories() {
        this.loaderService.show();
        this.getAllRiskSmiCodes().then((data) => {
            this.categoryOptions.setValues(data);
        }).then(() => this.loaderService.hide());
    }

    private setCategories() {
        this.filteredOptions = this.itemCategory.valueChanges
        .pipe(
            startWith<string | DefaultLookupModel>(''),
            map(value => typeof value === 'string' ? value : value.description),
            map(name => name ? this._filter(name) : this.categoryOptions.values.slice())
        );
    }

    private setControlsValidation() {
        this.itemCategory = new FormControl(
            '',
            [Validators.required]);

        this.itemDescription = new FormControl(
            '',
            [
                Validators.required,
                Validators.maxLength(this.itemDescriptionMaxLength)
            ]);

            this.itemValue = new FormControl(
            '',
            [
                Validators.required,
                Validators.min(this.itemValueMinValue)
            ]);
    }

    private setControls() {
        this.setControlsValidation();
        this.dialogForm = this.formBuilder.group({});
        this.dialogForm.addControl('itemCategory', this.itemCategory);
        this.dialogForm.addControl('itemDescription', this.itemDescription);
        this.dialogForm.addControl('itemValue', this.itemValue);
    }

    private _filter(value: string): DefaultLookupModel[] {
        const filterValue = value.toLowerCase();
        return this.categoryOptions.values.filter(option => option.description.toLowerCase().includes(filterValue));
    }

    private initializeIcons(imageService: ImageService) {
        this.closeIcon = imageService.getImage('CLOSE-ICON');
    }

    private preFill() {
        if (this.personalBelongingsSpecifiedModel) {
            this.itemCategory.setValue(
                this.personalBelongingsSpecifiedModel.categoryCode ?
                {
                    code: this.personalBelongingsSpecifiedModel.categoryCode,
                    description: this.personalBelongingsSpecifiedModel.category
                }
                : null
            );
            this.itemDescription.setValue(this.personalBelongingsSpecifiedModel.description);
            this.itemValue.setValue(this.personalBelongingsSpecifiedModel.value > 0 ? this.personalBelongingsSpecifiedModel.value : '');
            this.uiUtilsService.autoGrowTextElement('item-description-textArea');
        }
    }

    onSubmit() {
        if (!this.dialogForm.invalid) {
            this.personalBelongingsSpecifiedModel.description = this.itemDescription.value;
            this.personalBelongingsSpecifiedModel.value = this.itemValue.value;
            this.dialogRef.close();
        }
    }

    async getAllRiskSmiCodes() {
        return await this.lookupService.getLookup(LookupService.GROUP_CONTENTS_ALL_RISK_SMI_CODES);
    }

    autoGrowText(id: string) {
        this.uiUtilsService.autoGrowTextElement(id);
    }

    displayFn(option: DefaultLookupModel): DefaultLookupModel | string {
        return option ? option.description : option;
    }

    validateInput(input: string, value: any) {
        if (value && this.categoryOptions) {
            const dataList = this.categoryOptions.values;
            if (!dataList.find(x => x.description.toLowerCase() === value.toLowerCase())) {
                this.personalBelongingsSpecifiedModel.categoryCode = undefined;
            }
        }
    }

    clearField(input) {
        input.target.value = '';
        input.target.setAttribute('ng-reflect-model', '');
        let event;
        // A fix for IE
        if (typeof(Event) === 'function') {
            event = new Event('input');
        } else {
            event = document.createEvent('Event');
            event.initEvent('input', true, true);
        }
        input.target.dispatchEvent(event);
        this.initializeLookUpData();
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent) {
        const {code, description } = event.option.value;
        this.personalBelongingsSpecifiedModel.category = description;
        this.personalBelongingsSpecifiedModel.categoryCode = code;
    }

    onItemDescriptionBlur(event) {
        if (this.IsTextCountEnabled === true) {
            this.IsTextCountEnabled = false;
        }
    }

    onItemDescriptionKeyDown(event) {
        if (this.IsTextCountEnabled === false) {
            this.IsTextCountEnabled = true;
        }
    }

    onItemValueKeyDown(event) {
        const { keyCode, target } = event;

        if (target.value.length >= this.itemValueMaxLength && keyCode !== 8) {
            event.preventDefault();
        }
    }
}
