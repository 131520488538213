import {AutoUnsubscribe} from '../../auto-unsubscribe';
import {ChangeDetectorRef, EventEmitter, Injector, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DynamicFormControlErrorStateMatcher} from './dynamic-form-control-error-state-matcher';
import {BaseDynamicControlModel} from './base-dynamic-control.model';
import {MSTITooltipComponent} from '../../pop-up-dialogs/msti-tooltip/msti-tooltip.component';

export abstract class BaseDynamicFormControl<T> extends AutoUnsubscribe {

    @Input() form: FormGroup;
    @Input() dynamicControlModel: BaseDynamicControlModel<T>;
    @Output() onFormValueChanged = new EventEmitter();

    matcher = new DynamicFormControlErrorStateMatcher();

    private breakpointObserver: BreakpointObserver;

    protected isReadonly = false;
    protected isHidden = false;
    protected cdRef: ChangeDetectorRef;
    protected dialog: MatDialog;

    constructor(
        injector: Injector
    ) {
        super();

        this.cdRef = injector.get(ChangeDetectorRef);
        this.dialog = injector.get(MatDialog);
        this.breakpointObserver = injector.get(BreakpointObserver);
    }

    public init() {
        this.subscriptions.push(this.form.valueChanges.subscribe(() => {
            this._onFormValueChange();
        }));

        if (this.dynamicControlModel.updateValueObservable) {
            this.subscriptions.push(this.dynamicControlModel.updateValueObservable.subscribe(inputValue => {
                this.updateFormControlValue(inputValue);
                // TODO - Confirm this behaviour, is the 'value' property even used by the underlying form controls?
                this.dynamicControlModel.value = inputValue;
            }));
        }

        if (this.dynamicControlModel.hidden) {
            this.subscriptions.push(this.dynamicControlModel.hidden.subscribe((isHidden: boolean) => {
                const formControl = this.form.controls[this.dynamicControlModel.key];
                this.isHidden = isHidden;

                if (isHidden === true) {
                    formControl.clearValidators();
                    formControl.updateValueAndValidity({ emitEvent: false });
                } else {
                    formControl.setValidators(this.dynamicControlModel.validation);
                }
            }));
        }

        if (this.dynamicControlModel.clearValueObservable) {
            this.subscriptions.push(this.dynamicControlModel.clearValueObservable.subscribe((clearValue: boolean) => {
                if (clearValue === true) {
                    this.clearField();
                    this.clearValue();
                }
            }));
        }

        if (this.dynamicControlModel.disabled) {
            this.subscriptions.push(this.dynamicControlModel.disabled.subscribe((isDisabled: boolean) => {
                if (isDisabled === true) {
                    this.form.controls[this.dynamicControlModel.key].disable();
                } else {
                    this.form.controls[this.dynamicControlModel.key].enable();
                }
            }));
        }

        if (this.dynamicControlModel.readonly) {
            this.subscriptions.push(this.dynamicControlModel.readonly.subscribe((readonly: boolean) => {
                this.isReadonly = readonly;
            }));
        }

        this.doInit();
    }

    abstract doInit();
    abstract updateFormControlValue(inputValue: any);
    abstract clearValue();

    private _onFormValueChange() {
        this.onFormValueChanged.emit();
    }

    // Helper functions for child classes
    // TODO - Remove the above comment and confirm that the below functions are indeed generic helper functions that belong in the parent

    protected _clearFormControlErrors() {
        this.form.controls[this.dynamicControlModel.key].setErrors(null);
    }

    protected clearField() {
        if (!this.form.controls[this.dynamicControlModel.key].disabled && !this.isReadonly) {
            this.form.controls[this.dynamicControlModel.key].setValue('');
        }
    }

    protected openToolTipDialog() {
        if (this.dynamicControlModel.hintClickedEvent) {
            this.dynamicControlModel.hintClickedEvent.emit();
        }

        return this.dialog.open(MSTITooltipComponent, {
            width: '52%',
            data: { message: this.dynamicControlModel.hint, title: '' },
            panelClass: 'msti-dialog'
        });
    }

    // Check if device is touch device (phone or tablet)
    get isMobile() {
        return this.breakpointObserver.isMatched('(max-width: 800px)');
    }

}
