import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CardTabMenuItemParamsModel} from '../../../quote/card/model/card.tabmenuitem.params.model';
import {DynamicFormComponent} from '../../../shared/dynamic-form/component/dynamic-form.component';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-card-tab-container',
    templateUrl: './card-tab-container.component.html',
    styleUrls: ['./card-tab-container.component.scss']
})
export class CardTabContainerComponent {

    @Input() title: string;
    @Input() subTitle: string;
    @Input() menuItems: string[];

    @Output() onCloseEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() onSubmitEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() onTabMenuItemClickEventEmitter: EventEmitter<CardTabMenuItemParamsModel> = new EventEmitter<CardTabMenuItemParamsModel>();

    @ViewChild(DynamicFormComponent, { static: true }) dynamicFormComponent: DynamicFormComponent;

    private navigateToTabIndex = -1;

    constructor(
        public cdRef: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {
    }

    onClose() {
        this.onCloseEventEmitter.emit();
    }

    onSubmit(form: FormGroup) {
        if (this.navigateToTabIndex >= 0) {
            this.onTabMenuItemClickEventEmitter.emit(
                new CardTabMenuItemParamsModel(form, this.getCurrentTabIndex(), this.navigateToTabIndex, false)
            );

            this.navigateToTabIndex = -1;
        } else {
            this.onSubmitEventEmitter.emit(form);
        }
    }

    doSubmit() {
        this.dynamicFormComponent.onSubmit();
    }

    onTabMenuItemClick(tabIndex: number) {
        const currentTabIndex = this.getCurrentTabIndex();

        // If user has not interacted with the form, allow them to go to previous tabs
        if (!this.dynamicFormComponent.hasFormValueChanged && currentTabIndex > tabIndex) {
            this.onTabMenuItemClickEventEmitter.emit(
                new CardTabMenuItemParamsModel(this.dynamicFormComponent.form, currentTabIndex, tabIndex, true)
            );
        } else {
            // If user clicks on current tab menu item, then no action required
            if (currentTabIndex !== tabIndex) {
                if (this.dynamicFormComponent.form.valid) {
                    this.navigateToTabIndex = tabIndex;
                }
                this.dynamicFormComponent.onSubmit();
            }
        }
    }

    getCurrentTabIndex(): number {
        return this.menuItems.indexOf(this.title);
    }

}
