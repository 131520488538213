import {PremiumTermsAndConditionsPartyModel} from './premium-terms-and-conditions-party-model';

export class PremiumTermsAndConditionsModel  {
    public party: PremiumTermsAndConditionsPartyModel = new PremiumTermsAndConditionsPartyModel();
    public distributionChannel: string;
    public physicalChannel: string;
    public segment: string;
    public productLegalContentGuid: string;
}


// {
//     "party" : {
//         "cellNumber" : "0823344897",
//         "firstName" : "DEREK",
//         "surname" : "DINGWALL",
//         "identityValue" : "4609215108188",
//         "guid" : "677c01c0-ba8d-1035-92ca-ed84e1382aa2"
// },
//     "distributionChannel" : "DIRECT",
//     "physicalChannel" : "WEB",
//     "segment" : "MOMENTUM",
//     "productLegalContentGuid" : "c64cf6e7-0eb5-44ce-8f87-849e77574a3c"
// }

