import {Injectable} from '@angular/core';
import {MSTIService} from '../shared/services/msti.service';
import {RoutingService} from '../shared/services/routing.service';
import {RiskOptionService} from '../shared/risk/risk-option.service';
import {UrlService} from '../shared/services/url.service';
import {LoaderService} from '../shared/screen-loader/loader.service';
import {MstiErrorService} from '../shared/msti-error-handler/msti-error.service';
import {BrokerDetailsService} from '../shared/broker-common/broker-details.service';
import {BrokerDetailsModel} from '../shared/broker-common/broker-details.model';
import {BrokerUrlService} from '../shared/services/broker-url.service';
import {AvailableSectionsModel} from '../covers/models/available-sections.model';
import {AppConfigModel} from './models/app-config-model.model';
import {Logger} from '../shared/utilities/logger';
import {Level} from '../shared/utilities/logger-level';
import {Action, ErrorMessages, MstiErrorModel} from '../shared/msti-error-handler/models/msti-error.model';
import {QuoteService} from '../quote/service/quote.service';
import {BrokerContextModel} from '../shared/broker-common/broker-context.model';
import {forkJoin} from 'rxjs';

@Injectable()
export class AppInitializerService {

  constructor(private mstiService: MSTIService, private routingService: RoutingService,
              private riskOptionService: RiskOptionService, private urlService: UrlService,
              private loaderService: LoaderService, private brokerUrlService: BrokerUrlService,
              private mstiErrorService: MstiErrorService, private brokerDetailsService: BrokerDetailsService,
              private quoteService: QuoteService) {
  }

  public initialiseApp(appConfigModel: AppConfigModel): void {
    if (!appConfigModel.isAllowed) {
      this.routingService.route('/app_not_allowed');
    } else {
      this.getToken(appConfigModel);
    }

  }


  private getToken(appConfigModel: AppConfigModel) {
    this.loaderService.show();
    this.mstiService.getBrokerToken(this.brokerUrlService.getBrokerTokenUrl()).subscribe((response: any) => {
      const token = response.token;
      const user: BrokerDetailsModel = new BrokerDetailsModel();
      user.cifToken = token;
      user.idNumber = appConfigModel.rsaId;
      this.brokerDetailsService.setUser(user);
      const requestArray: Array<any> = [];
      requestArray.push(this.getBrokerContext1(appConfigModel));
      requestArray.push(this.getAvailableRisks1());
      forkJoin(requestArray).subscribe(results => {
        console.log(results);
        const brokerContext = Object.assign(results[0], new BrokerContextModel());
        this.brokerDetailsService.setBrokerContext(brokerContext);
        this.setRisks(results[1]);
        this.checkQuotable();
        this.loaderService.hide();
      }, (errors) => {
        this.loaderService.hide();
        this.mstiErrorService.handleError(errors[0]);
      });
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);

    });
    this.loaderService.hide();
  }


  private getBrokerContext1(appConfigModel: AppConfigModel) {
    return this.mstiService.getWithAbAuth(this.brokerUrlService.getBrokerRefUrl(appConfigModel.brokerReference));
  }


  private getAvailableRisks1() {
    this.loaderService.show();
    return this.mstiService.getWithAuth(this.urlService.getAvailableSectionsUrl());
  }

  /*  private getBrokerContext(appConfigModel: AppConfigModel) {
      this.mstiService.getWithAbAuth(this.brokerUrlService.getBrokerRefUrl(appConfigModel.brokerReference)).subscribe((response: any) => {
        const brokerContext = Object.assign(response, new BrokerContextModel());
        this.brokerDetailsService.setBrokerContext(brokerContext);
        this.checkQuotable();
      }, (error) => {
        this.loaderService.hide();
        this.mstiErrorService.handleError(error);
      });
    }*/

  /* private getAvailableRisks() {
     this.loaderService.show();
     this.mstiService.getWithAuth(this.urlService.getAvailableSectionsUrl()).subscribe(response => {
       this.setRisks(response);
       this.routingService.route('/app-select-cover');
       this.loaderService.hide();
     }, (error) => {
       this.loaderService.hide();
       this.mstiErrorService.handleError(error);
     });
     this.loaderService.hide();
   }*/

  private setRisks(result) {
    const risks: string[] = [];
    const availableSectionsModel: AvailableSectionsModel[] = Object.assign(new Array<AvailableSectionsModel>(), result);
    for (let i = 0; i < availableSectionsModel.length; i++) {
      risks.push(availableSectionsModel[i].sectionName);
    }
    this.riskOptionService.setRisks(risks);
    this.riskOptionService.setMaxNumberOfRisks(availableSectionsModel[0].maximumNumberOfRisksAllowed);
  }

  private checkQuotable() {
    this.loaderService.show();
    this.mstiService.getWithAbAuth(this.urlService.getLeadsUrl(this.brokerDetailsService.getUser().idNumber
    )).subscribe(leadsResponse => {
      this.leadsRoute(leadsResponse);
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private leadsRoute(response: any) {
    Logger.log(Level.LOG, 'Leads response', response);
    // 0 - not qualified, 1 - qualified, 2 - contact call centre
    if (response !== null && response[0].eligibleStatus) {
      Logger.log(Level.LOG, 'Leads response2=', response[0].eligibleStatus.statusCode);
      switch (response[0].eligibleStatus.statusCode) {

        case 0: // TODO - We need to discuss the flow in this case
          // this.routingService.route('/marketing-call-centre');
          break;
        case 1:
          if (response[0].agreementInformation && response[0].agreementInformation.agreementResourceRef) {
            this.quoteService.quoteId = response[0].agreementInformation.agreementResourceRef;
          }

          if (response[0].eligibleClient && response[0].eligibleClient.clientResourceRef) {
            this.brokerDetailsService.getUser().clientResourceRef = response[0].eligibleClient.clientResourceRef;
          }
          this.routingService.route('/app-select-cover');
          break;
        case 2:
          // this.routingService.route('/marketing-call-centre');
          // TODO get proper message and error code
          this.showLeadNotFoundError('Call Center Quotable');
          break;
        case 3:
          // Existing client
          break;
      }
    } else {
      this.showLeadNotFoundError('We found that you are linked to a financial adviser with a MSTI contract. Please contact your adviser');
    }


  }

  private showLeadNotFoundError(errorMessage) {
    const mstiErrorModel: MstiErrorModel = new MstiErrorModel(null);
    const errorMessages: ErrorMessages[] = [];
    const action: Action = new Action();
    action.actionTypeCode = '442';
    action.actionDescription = errorMessage;
    mstiErrorModel.action = action;
    const message = new ErrorMessages();
    message.message = '';
    message.errorCode = '';
    errorMessages.push(message);
    mstiErrorModel.errors = errorMessages;
    mstiErrorModel.type = 'Error';
    this.mstiErrorService.handleError(mstiErrorModel);
  }
}
