import {Vehicle} from '../../sections/vehicle/model/cif/helper/vehicle.model';
import {CifVehicleModel} from '../../sections/vehicle/model/cif/cif-vehicle.model';
import {CifClientInfoModel} from './cif-client-info.model';
import {ContentsModel} from '../../sections/contents/model/contents.model';
import {CifContentsModel} from '../../sections/contents/model/cif/cif-contents.model';
import {SaveClientResponseModel} from '../../../personal/model/SaveClientModels/save-client-response.model';
import {BuildingModel} from '../../sections/building/model/building.model';
import {CifBuildingModel} from '../../sections/building/model/cif/cif-building.model';
import {PifAgreementInfoModel} from './helper/pif-agreementinfo.model';
import {PifVehicleAddressModel} from '../../sections/vehicle/model/pif/pif-vehicle-address.model';

export class CifQuoteModel {
  agreementInformation = new PifAgreementInfoModel();

  constructor(public clientInformation: CifClientInfoModel, public vehicleRisks: Vehicle[], public contentRisks: CifContentsModel[],
              public buildingRisks: CifBuildingModel[]) {
  }

  static getMappedVehicleModel(clientInfo: CifClientInfoModel, cifVehicleModel: CifVehicleModel): CifQuoteModel {
    const vehicles = new Array<Vehicle>();
    const pifVehicleAddressModel = PifVehicleAddressModel.cifToPif(cifVehicleModel.addresses);
    const vehicle = Vehicle.create({
      riskResourceRef: cifVehicleModel.riskResourceRef, riskDescription: cifVehicleModel.riskDescription,
      sectionCode: cifVehicleModel.sectionCode, usageCode: cifVehicleModel.usageCode, possessionCode: cifVehicleModel.possessionCode,
      modificationCode: cifVehicleModel.modificationCode, vehicle: cifVehicleModel.vehicle, riskAddress: pifVehicleAddressModel,
      securityFeatures: cifVehicleModel.securityFeatures
    });
    // vehicle.modificationCode = cifVehicleModel.modificationCode;
    vehicles.push(vehicle);

    const quoteModel = new CifQuoteModel(clientInfo, vehicles, null, null);

    return quoteModel;
  }

  // TODO - Remove the use of these helper classes and map the internal model directly to the cif model
  static getMappedContentModel(clientInfo: CifClientInfoModel, contentsModel: ContentsModel): CifQuoteModel {
    const contents = new Array<CifContentsModel>();
    const clientModel = new SaveClientResponseModel();

    clientModel.clientResourceRef = clientInfo.clientResourceRef;
    clientModel.clientNumber = clientInfo.clientNumber;

    const cifContent = new CifContentsModel();
    cifContent.riskId = contentsModel.riskId;
    cifContent.riskResourceRef = contentsModel.riskId;
    cifContent.sectionCode = contentsModel.sectionCode;
    cifContent.industryTypeCode = contentsModel.industryTypeCode;
    cifContent.sumInsured = contentsModel.sumInsured;
    cifContent.riskAddress = contentsModel.riskAddress;
    cifContent.previousBurglaries = (contentsModel.previousBurglaries === '0' ? false : true);
    cifContent.neighbourTypeCode = contentsModel.neighbourTypeCode;
    cifContent.occupancyTypeCode = contentsModel.occupancyTypeCode;
    cifContent.occupantTypeCode = contentsModel.occupantTypeCode;
    cifContent.structure = contentsModel.getCifStructureModel();
    contents.push(cifContent);

    const quoteModel = new CifQuoteModel(clientInfo, null, contents, null);

    return quoteModel;
  }

  // TODO - Remove the use of these helper classes and map the internal model directly to the cif model
  static getMappedBuildingModel(clientInfo: CifClientInfoModel, buildingModel: BuildingModel): CifQuoteModel {
    const buildings = new Array<CifBuildingModel>();
    const clientModel = new SaveClientResponseModel();

    clientModel.clientResourceRef = clientInfo.clientResourceRef;
    clientModel.clientNumber = clientInfo.clientNumber;

    const cifBuilding = new CifBuildingModel(clientModel);
    cifBuilding.riskId = buildingModel.riskId;
    cifBuilding.riskResourceRef = buildingModel.riskId;
    cifBuilding.sectionCode = buildingModel.sectionCode;
    cifBuilding.industryTypeCode = buildingModel.industryTypeCode;
    cifBuilding.buildingTypeCode = buildingModel.buildingTypeCode;
    cifBuilding.sumInsured = buildingModel.sumInsured;
    cifBuilding.isHolidayResidence = (buildingModel.isHolidayResidence === '0' ? false : true);
    cifBuilding.previousBurglaries = (buildingModel.previousBurglaries === '0' ? false : true);
    cifBuilding.neighbourTypeCode = buildingModel.neighbourTypeCode;
    cifBuilding.ownershipTypeCode = buildingModel.ownershipTypeCode;
    cifBuilding.occupancyTypeCode = buildingModel.occupancyTypeCode;
    cifBuilding.occupantTypeCode = buildingModel.occupantTypeCode;
    cifBuilding.riskAddress = buildingModel.riskAddress;
    cifBuilding.structure = buildingModel.getCifStructureModel();
    buildings.push(cifBuilding);

    const quoteModel = new CifQuoteModel(clientInfo, null, null, buildings);

    return quoteModel;
  }

}
