import {Injectable, OnDestroy} from '@angular/core';
import {PersonalBelongingsModel} from '../model/personal-belongings.model';
import {Logger} from '../../../../shared/utilities/logger';
import {Level} from '../../../../shared/utilities/logger-level';
import {CifMovablesModel} from '../model/cif/cif-movables.model';
import {MstiSectionCode} from '../../../MstiSectionCode';
import {LoaderService} from '../../../../shared/screen-loader/loader.service';
import {MSTIService} from '../../../../shared/services/msti.service';
import {PersonalBelongingsUrlService} from './personal-belongings-url.service';
import {MstiErrorService} from '../../../../shared/msti-error-handler/msti-error.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {CifRiskReferenceModel} from '../../contents/model/cif/cif-risk-reference-model';
import {CifSecurityFeatureModel} from '../../vehicle/model/cif/cif-security-feature.model';
import {PifRiskAddressModel} from '../../../model/pif/pif-risk-address.model';

@Injectable()
// NOTE: This service does not extend the BaseRiskService class as the personal belongings risk is not stand alone
export class PersonalBelongingsService implements OnDestroy {

  private cardModels = {};
  public showPersonalBelongingsControls: BehaviorSubject<boolean>;
  public showAddressAlreadyExistControls: BehaviorSubject<boolean>;

  constructor(
    private loaderService: LoaderService,
    private mstiService: MSTIService,
    private mstiErrorService: MstiErrorService,
    private personalBelongingsUrlService: PersonalBelongingsUrlService
  ) {
    this.showPersonalBelongingsControls = new BehaviorSubject<boolean>(false);
    this.showAddressAlreadyExistControls = new BehaviorSubject<boolean>(true);
  }

  public ngOnDestroy() {
    this.cardModels = {};
  }

  private initCardModel(cardId: string) {
    if (!this.cardModels[cardId]) {
      this.cardModels[cardId] = {};
    }
  }

  public retrievePersonalBelongingsDataFromCif(cardId: string, quoteId: string, contentsRiskId: string): Subject<any> {
    this.loaderService.show();
    const personalBelongingsCompleteSubject = new Subject<any>();
    this.initCardModel(cardId);
    this.mstiService.getWithAbAuth(
      this.personalBelongingsUrlService.getPersonalBelongingsListURL(quoteId, contentsRiskId)
    ).subscribe(async (response: Array<CifRiskReferenceModel>) => {

      response.forEach(async riskReference => {
        await this.retrievePersonalBelongingItemFromCif(cardId, quoteId, contentsRiskId, riskReference.riskResourceRef);
      });

      this.loaderService.hide();
      personalBelongingsCompleteSubject.complete();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving personal belongings list', error);
      this.loaderService.hide();
      personalBelongingsCompleteSubject.error(error);
      this.mstiErrorService.handleError(error);
    });

    return personalBelongingsCompleteSubject;
  }

  private async retrievePersonalBelongingItemFromCif(cardId: string, quoteId: string, contentsRiskId: string, riskResourceRef: string) {
    this.loaderService.show();

    await this.mstiService.getWithAbAuth(
      this.personalBelongingsUrlService.getPersonalBelongingsRiskURL(quoteId, contentsRiskId, riskResourceRef)
    ).subscribe(async (response: CifMovablesModel) => {
      this.cardModels[cardId].cifMovablesModel = Object.assign(new CifMovablesModel(), response);
      await this.populatePersonalBelongingsFromCifModel(quoteId, contentsRiskId, cardId);
      this.loaderService.hide();
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while retrieving personal belongings item', error);
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    });
  }

  private async populatePersonalBelongingsFromCifModel(quoteId: string, contentsRiskId: string, cardId: string) {
    this.initPersonalBelongingsModel(cardId);
    await this.cardModels[cardId].personalBelongingsModel.populateContentFromCifModel(quoteId, contentsRiskId, this.cardModels[cardId].cifMovablesModel);
  }

  public async saveOrUpdatePersonalBelongings(contentRiskId: string, quoteId: string, address: PifRiskAddressModel,
                                              securityFeatures: Array<CifSecurityFeatureModel>, data: any) {
    this.loaderService.show();
    /* this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings.forEach((specifiedModel) => {
       specifiedModel.contentRiskId = contentRiskId;
       specifiedModel.quoteId = quoteId;
     });*/


    const selectedOption = this.cardModels[data.cardId].personalBelongingsModel.selectedPersonalBelongingsOption;
    Logger.log(Level.ERROR, 'Ab selection', selectedOption);
    if (selectedOption === 'Unspecified Personal Belongings') {
      //  Delete specified
      await this.deleteAllSpecified(data, quoteId, contentRiskId);
      await this.savePersonalBelongings(data, address, securityFeatures, quoteId, contentRiskId);
      this.loaderService.hide();

    } else if (selectedOption === 'Specified Personal Belongings') {
      // delete everything except Specified Personal Belongings
      if (this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId) {
        await this.deleteRisk(quoteId, contentRiskId, this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId);
        this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId = null;
        this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingsAmount = null;
      }
      await this.savePersonalBelongings(data, address, securityFeatures, quoteId, contentRiskId);
      this.loaderService.hide();

    } else if (selectedOption === 'Unspecified and Specified Personal Belongings') {
      await this.savePersonalBelongings(data, address, securityFeatures, quoteId, contentRiskId);
      this.loaderService.hide();

    } else if (selectedOption === 'None') {
      if (this.cardModels[data.cardId].personalBelongingsModel) {
        if (this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId) {
          await this.deleteRisk(quoteId, contentRiskId, this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId);
        }
        await this.deleteAllSpecified(data, quoteId, contentRiskId);
        this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingId = null;
        this.cardModels[data.cardId].personalBelongingsModel.unspecifiedBelongingsAmount = null;
      }
      this.loaderService.hide();

    }
    Logger.log(Level.ERROR, 'Ab final', this.cardModels[data.cardId]);
  }

  private async savePersonalBelongings(data: any, address: PifRiskAddressModel, securityFeatures: Array<CifSecurityFeatureModel>, quoteId: string, contentRiskId: string) {
    const cifMovablesModel = this.createMovableCifModel(this.cardModels[data.cardId].personalBelongingsModel, address, securityFeatures);
    await this.mstiService.putWithAbAuth(this.personalBelongingsUrlService.getSaveOrUpdateMovablesURL(quoteId, contentRiskId), cifMovablesModel
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'Save movable response:', response);
      this.setIDsForSave(data.cardId, response);
      this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings.forEach((specifiedModel) => {
         specifiedModel.contentRiskId = contentRiskId;
         specifiedModel.quoteId = quoteId;
       });
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while movable content', error);
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });
  }

  private async deleteAllSpecified(data: any, quoteId: string, contentRiskId: string) {
    if (this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings &&
      this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings.length > 0) {
      await this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings.forEach(async (specifiedModel) => {
        if (specifiedModel.id) {
          await this.deleteRisk(quoteId, contentRiskId, specifiedModel.id);
        }
      });
      this.cardModels[data.cardId].personalBelongingsModel.specifiedPersonalBelongings = [];
    }
  }

  private async deleteRisk(quoteId: string, contentId: string, riskResourceRef: string) {
    this.loaderService.show();
    await this.mstiService.deleteWithAbAuth(
      this.personalBelongingsUrlService.getDeleteMovablesURL(quoteId, contentId, riskResourceRef)
    ).toPromise().then((response: any) => {
      Logger.log(Level.LOG, 'Delete movable response:', response);
    }, (error) => {
      Logger.log(Level.ERROR, 'Error while deleting movable', error);
      this.loaderService.hide();
      this.mstiErrorService.handleError(error);
    }).then(() => {
      this.loaderService.hide();
    });
  }

  private setIDsForSave(cardId: string, cifResponse: any): void {
    cifResponse.forEach(cifMovableModel => {
      if (cifMovableModel.smiCode === CifMovablesModel.SMI_CODE_UNSPECIFIED) {
        this.cardModels[cardId].personalBelongingsModel.unspecifiedBelongingId = cifMovableModel.riskResourceRef;
      } else {
        if (cifMovableModel.riskDescription) {
          const parsedRiskDescription = this.parseCifResponse(cifMovableModel.riskDescription);
          if (parsedRiskDescription.smiCode === CifMovablesModel.SMI_CODE_UNSPECIFIED) { // Unspecified risk
            this.cardModels[cardId].personalBelongingsModel.unspecifiedBelongingId = cifMovableModel.riskResourceRef;
          } else {// specified risk
            this.cardModels[cardId].personalBelongingsModel.specifiedPersonalBelongings.forEach(personalBelonging => {
              if (personalBelonging.category === parsedRiskDescription.smiDescription &&
                personalBelonging.categoryCode === parsedRiskDescription.smiCode &&
                personalBelonging.description === parsedRiskDescription.makeModelDescription &&
                personalBelonging.value === Number(parsedRiskDescription.sumInsured) &&
                personalBelonging.id.length === 0
              ) {
                personalBelonging.id = cifMovableModel.riskResourceRef;
              }

            });
          }
        } else { // don't know why this code is here
          let specifiedPersonalBelonging;
          specifiedPersonalBelonging = this.cardModels[cardId].personalBelongingsModel.specifiedPersonalBelongings.find(personalBelongingsSpecifiedModel => {
            return personalBelongingsSpecifiedModel.category === cifMovableModel.smiDesc &&
              personalBelongingsSpecifiedModel.categoryCode === cifMovableModel.smiCode &&
              personalBelongingsSpecifiedModel.description === cifMovableModel.makeModel &&
              personalBelongingsSpecifiedModel.value === Number(cifMovableModel.sumInsured);
          });
          // NOTE: This if is for test purposes only due to the hardcoded wiremock responses
          if (specifiedPersonalBelonging) {
            specifiedPersonalBelonging.id = cifMovableModel.riskResourceRef;
          }
        }

      }
    });
  }

  private parseCifResponse(riskDescription: string) {
    const cifMovableModel = new CifMovablesModel();
    const splitArray = riskDescription.split('|');
    splitArray.forEach(element => {
      const splitVal = element.split(' : ');
      switch (splitVal[0].trim()) {
        case 'SMI Code':
          cifMovableModel.smiCode = splitVal[1].trim();
          break;
        case 'Make / Model':
          cifMovableModel.makeModelDescription = splitVal[1].trim();
          break;
        case 'SMI Description':
          cifMovableModel.smiDescription = splitVal[1].trim();
          break;
        case 'SUM_INSURED':
          cifMovableModel.sumInsured = splitVal[1].trim();
          break;
      }
    });
    return cifMovableModel;
  }

  private createMovableCifModel(personalBelongingsModel: PersonalBelongingsModel, address: PifRiskAddressModel,
                                securityFeatures: Array<CifSecurityFeatureModel>) {
    const cifMovablesModelArray = [];
    if (personalBelongingsModel.selectedPersonalBelongingsOption === 'Unspecified Personal Belongings') {
      this.createUnspecifiedCifModel(personalBelongingsModel, address, securityFeatures, cifMovablesModelArray);
    } else if (personalBelongingsModel.selectedPersonalBelongingsOption === 'Specified Personal Belongings') {
      this.createSpecifiedCifModel(personalBelongingsModel, address, securityFeatures, cifMovablesModelArray);
    } else { // Both
      this.createUnspecifiedCifModel(personalBelongingsModel, address, securityFeatures, cifMovablesModelArray);
      this.createSpecifiedCifModel(personalBelongingsModel, address, securityFeatures, cifMovablesModelArray);
    }
    return cifMovablesModelArray;
  }

  private createUnspecifiedCifModel(personalBelongingsModel: PersonalBelongingsModel, address: PifRiskAddressModel,
                                    securityFeatures: Array<CifSecurityFeatureModel>, cifMovablesModelArray
  ) {
    const cifMovablesModel = new CifMovablesModel();
    cifMovablesModel.sectionCode = MstiSectionCode.ALL_RISK;
    cifMovablesModel.riskResourceRef = personalBelongingsModel.unspecifiedBelongingId;
    cifMovablesModel.riskAddress = address;
    cifMovablesModel.securityFeatures = securityFeatures;
    cifMovablesModel.smiCode = CifMovablesModel.SMI_CODE_UNSPECIFIED;
    cifMovablesModel.sumInsured = personalBelongingsModel.unspecifiedBelongingsAmount;
    cifMovablesModel.makeModelDescription = 'Unspecified';
    cifMovablesModelArray.push(cifMovablesModel);
  }

  private createSpecifiedCifModel(personalBelongingsModel: PersonalBelongingsModel, address: PifRiskAddressModel,
                                  securityFeatures: Array<CifSecurityFeatureModel>, cifMovablesModelArray) {
    const specifiedPersonalBelongings = personalBelongingsModel.specifiedPersonalBelongings;
    const len = specifiedPersonalBelongings === null ? 0 : specifiedPersonalBelongings.length;
    for (let i = 0; i < len; i++) {
      const cifMovablesModel = new CifMovablesModel();
      cifMovablesModel.sectionCode = MstiSectionCode.ALL_RISK;
      cifMovablesModel.riskResourceRef = specifiedPersonalBelongings[i].id;
      cifMovablesModel.riskAddress = address;
      cifMovablesModel.securityFeatures = securityFeatures;
      cifMovablesModel.smiCode = specifiedPersonalBelongings[i].categoryCode;
      cifMovablesModel.smiDescription = specifiedPersonalBelongings[i].category;
      cifMovablesModel.sumInsured = '' + specifiedPersonalBelongings[i].value;
      cifMovablesModel.makeModelDescription = specifiedPersonalBelongings[i].description;
      cifMovablesModelArray.push(cifMovablesModel);
    }
  }

  public getPersonalBelongingsModel(cardId: string): PersonalBelongingsModel {
    if (!this.cardModels[cardId]) {
      this.initCardModel(cardId);
    }

    if (!this.cardModels[cardId].personalBelongingsModel) {
      this.initPersonalBelongingsModel(cardId);
    }

    return this.cardModels[cardId].personalBelongingsModel;
  }

  public initPersonalBelongingsModel(cardId: string) {
    if (!this.cardModels[cardId].personalBelongingsModel) {
      this.cardModels[cardId].personalBelongingsModel = new PersonalBelongingsModel();
    }
  }

  public showAddressExistsMessage(show: boolean) {
    // Here show means hidden
    this.showAddressAlreadyExistControls.next(!show);
    this.showPersonalBelongingsControls.next(show);
  }

  public doesPersonalBelongingsModelHaveData(cardId: string): boolean {
    // TODO see if card has data, remember to loop though all cards
    const personalBelongingsModel = this.cardModels[cardId].personalBelongingsModel;
    if (personalBelongingsModel.unspecifiedBelongingsAmount ||
      (personalBelongingsModel.specifiedPersonalBelongings && personalBelongingsModel.specifiedPersonalBelongings.length > 0)) {
      return true;
    }
    return false;
  }

}
