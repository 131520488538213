import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PremiumComponent} from './component/premium.component';
import {PremiumService} from './service/premium.service';
import {QuoteSummaryComponent} from './component/premium-summary/quote-summary.component';
import {SharedModule} from '../shared/shared.module';
import {PremiumUrlService} from './service/premium-url.service';
import {MatExpansionModule, MatGridListModule} from '@angular/material';
import {ToCurrencyPipe} from '../shared/pipes/to-currency.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatExpansionModule,
    MatGridListModule,
  ],
  declarations: [
    PremiumComponent,
    QuoteSummaryComponent
  ],
  providers: [
    PremiumService,
    PremiumUrlService
  ]
})
export class PremiumModule {
}
